<template>
  <div>
    <!--顶部标题-->
    <div>
      <div
        class="toolbar"
        style="
          background-color: #fff;
          padding: 0.5rem;
          border-radius: 15px;
          margin: 10px auto;
          max-width: 100%;
        "
      >
        <div
          id="kt_toolbar_container1"
          class="container-fluid d-flex flex-stack"
          style="padding: 0 30px"
        >
          <div
            class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container1'}"
          >
            <h1
              :style="`font-size: 1.17rem !important`"
              class="d-flex align-items-center text-dark fw-bolder fs-3 my-1"
            >
              商户号管理
            </h1>
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">Merchant Id Management</li>
            </ul>
          </div>
          <div
            style="position: absolute; right: 0; padding: 0px 30px"
            v-if="rows.length == 0"
          >
            <el-button
              round
              size="mini"
              style="margin-right: 20px"
              type="primary"
              @click="$refs.CreateMerchantId.open()"
            >
              添加商户号
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!--  下方卡片  -->
    <div>
      <!--  如果数据是空的就显示空的页面 -->
      <div class="card" v-if="rows.length == 0">
        <div class="card-body">
          <div class="card-px text-center pt-15 pb-15">
            <h2 class="fs-2x fw-bold mb-0">为你的账号来创建一个商户号</h2>
            <p class="text-gray-400 fs-4 fw-semibold py-7">
              点击下方按钮 <br />来创建你的第一个商户号
            </p>
            <a
              class="btn btn-primary er fs-6 px-8 py-4"
              data-bs-target="#kt_modal_create_project"
              data-bs-toggle="modal"
              href="javascript:void(0)"
              @click="$refs.CreateMerchantId.open()"
              >添加商户号</a
            >
          </div>
          <div class="text-center pb-15 px-5">
            <img
              alt=""
              class="mw-100 h-200px h-sm-325px"
              src="../../../../assets/MerchantIdNull.png"
            />
          </div>
        </div>
      </div>
      <!--如果数据不为空就显示列表-->
      <div class="card" v-else>
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
              id="kt_ecommerce_products_table"
            >
              <thead>
                <tr
                  class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                >
                  <th
                    class="sorting_disabled"
                    rowspan="1"
                    colspan="1"
                    aria-label="商户名称"
                  >
                    商户名称
                  </th>
                  <th
                    class="sorting_disabled"
                    rowspan="1"
                    colspan="1"
                    aria-label="商户号"
                  >
                    商户号
                  </th>
                  <th
                    class="sorting_disabled"
                    rowspan="1"
                    colspan="1"
                    aria-label="商户密钥"
                  >
                    商户密钥
                  </th>
                  <th
                    class="sorting_disabled"
                    rowspan="1"
                    colspan="1"
                    aria-label="操作"
                  >
                    操作
                  </th>
                </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
                <tr v-for="(item, index) in rows" :key="index">
                  <td>{{ item.wxMacName }}</td>
                  <td>{{ item.wxMacId }}</td>
                  <td>{{ item.wxMacPartnerKey }}</td>
                  <td>
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      @click="edit(item)"
                      size="small"
                      >编辑</el-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--    弹窗-->
    <ModalDialog ref="CreateMerchantId" title="添加商户号" width="45%">
      <MerchantIdCreate ref="form" @close="addMethod">
      </MerchantIdCreate>
    </ModalDialog>
    <!-- 编辑 -->
    <ModalDialog ref="editMerchantId" title="编辑商户号" width="45%">
      <el-form
        ref="editForm"
        :model="editObj"
        :rules="rules"
        label-width="190px"
      >
        <el-form-item label="商户名称" prop="wxMacId">
          <el-input v-model="editObj.wxMacId" placeholder="商户名称"></el-input>
        </el-form-item>
        <el-form-item label="商户名称" prop="wxMacName">
          <el-input
            v-model="editObj.wxMacName"
            placeholder="商户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="商户密钥" prop="wxMacPartnerKey">
          <el-input
            v-model="editObj.wxMacPartnerKey"
            placeholder="商户密钥"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveEdit">立即保存</el-button>
          <el-button @click="$refs.CreateMerchantId.close()">取消</el-button>
        </el-form-item>
      </el-form>
    </ModalDialog>
  </div>
</template>

<script>
import { getMyWxMacApi, updateMyWxMacApi } from "@/api/merchantApi";
import CardCreatePopUp from "@/views/Finance/components/card/CardCreatePopUp.vue";
import MerchantIdCreate from "@/views/Finance/components/MerchantId/MerchantIdCreate.vue";

export default {
  name: "MerchantIdList",
  components: { MerchantIdCreate, CardCreatePopUp },
  data() {
    return {
      rules: {
        wxMacId: [{ required: true, message: "请输入商户号", trigger: "blur" }],
        wxMacName: [
          { required: true, message: "请输入商户名", trigger: "blur" },
        ],
        wxMacPartnerKey: [
          { required: true, message: "请输入商户密钥", trigger: "blur" },
        ],
      },
      rows: [],
      editObj: {
        wxMacId: "",
        wxMacName: "",
        wxMacPartnerKey: "",
      },
    };
  },
  methods: {
    addMethod(){
      this.$refs.CreateMerchantId.close()
      this.getMyWxMac();
    },
    async getMyWxMac() {
      let { data } = await getMyWxMacApi();
      if (data.wxMacId) {
        this.rows = [data];
      } else {
        this.rows = [];
      }
    },
    async saveEdit() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          await updateMyWxMacApi(JSON.stringify(this.editObj));
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$refs.editMerchantId.close();
          this.getMyWxMac()
        }
      });
    },
    edit(item) {
      this.editObj = item;
      this.$refs.editMerchantId.open();
    },
  },
  created() {
    this.getMyWxMac();
  },
};
</script>

<style scoped>
</style>