import service from "@/utils/request";

// 列表分页查询
export function getComboGiftPackListApi(data) {
    return service({
        transformResponse: [function (data) {
            return data;
        }],
        url: '/giftPack/getComboGiftPackList',
        method: 'get',
        params: data
    })
}

// 新增组合礼包
export function addComboGiftPackApi(data) {
    return service({
        url: '/giftPack/addComboGiftPack',
        method: 'post',
        data
    })
}

// 删除组合礼包
export function deleteComboGiftPackApi(data) {
    return service({
        url: '/giftPack/deleteComboGiftPackById',
        method: 'delete',
        data
    })
}

// 数据回显
export function getComboGiftPackInfoApi(data) {
    return service({
        url: '/giftPack/getComboGiftPackDetailById',
        method: 'get',
        params: data
    })
}

// 编辑商品
export function editComboGiftPack(data) {
    return service({
        url: '/giftPack/editComboGiftPack',
        method: 'put',
        data
    })
}