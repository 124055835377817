import service from "@/utils/request"
// 获取上门安装
export function getInstallSelectApi(data) {
    return service({
        url: '/install/getInstallSelect',
        method: 'post',
        params:data
    })
}

export function getInstallPageApi(data) {
    return service({
        url: '/install/getInstallList',
        method: 'get',
        params: data
    })
}

export function updateInstallIsStatusApi(data) {
    return service({
        url: '/install/updateIsStatus',
        method: 'post',
        data
    })
}

export function deleteInstallByIdApi(data) {
    return service({
        url: '/install/delInstall',
        method: 'delete',
        data
    })
}

export function addInstallTemplate(data) {
    return service({
        url: '/install/addInstall',
        method: 'post',
        data
    })
}

