<template>
    <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
        <div class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div class="text-dark order-2 order-md-1">
                <span class="text-muted fw-bold me-1">2016-2024©一采一购</span>
                <a href="https://beian.miit.gov.cn" target="_blank" class="menu-link px-2">{{obj.webIcp}}</a>
            </div>
            <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                <li class="menu-item"><span class="text-muted fw-bold me-1">联系我们
                        : 400-066-2158</span> <span class="text-muted fw-bold me-1">Email
                        : yigworld@vip.163.com</span></li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default{
    computed: {
    ...mapState("web", ["obj"]),
  },
}
</script>
<style scoped>
.footer {
    background-color: #fff;
}
</style>