<template xmlns="http://www.w3.org/1999/html">
  <div>
    <!--顶部标题-->
    <div>
      <div class="toolbar" style="background-color: #fff; padding: 0.5rem;border-radius: 15px;margin: 10px auto; max-width:100%;">
        <div
            id="kt_toolbar_container1"
            class="container-fluid d-flex flex-stack"
            style="padding: 0 30px"
        >
          <div
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container1'}"
              class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
          >
            <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1" :style="`font-size: 1.17rem !important`">
              卡包管理
            </h1>
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">Card Management</li>
            </ul>
          </div>
          <div style="position: absolute;right: 0;padding: 0px 30px;">
            <el-button type="primary" size="mini" round style="margin-right: 20px" @click="$refs.createCard.open()" >添加卡包</el-button>
          </div>
        </div>

      </div>
    </div>
    <!--  如果数据是空的就显示空的页面 -->
    <div v-if="cardList.length === 0" class="card">

      <!--begin::Card body-->
      <div class="card-body">
        <!--begin::Heading-->
        <div class="card-px text-center pt-15 pb-15">
          <!--begin::Title-->
          <h2 class="fs-2x fw-bold mb-0">为你的账号来创建一个卡包</h2>
          <!--end::Title-->
          <!--begin::Description-->
          <p class="text-gray-400 fs-4 fw-semibold py-7">点击下方按钮
            <br/>来创建你的第一个卡包</p>
          <!--end::Description-->
          <!--begin::Action-->
          <a class="btn btn-primary er fs-6 px-8 py-4" data-bs-target="#kt_modal_create_project" data-bs-toggle="modal"
             href="javascript:void(0)" @click="$refs.createCard.open()">添加卡包</a>
          <!--end::Action-->
        </div>
        <!--end::Heading-->
        <!--begin::Illustration-->
        <div class="text-center pb-15 px-5">
          <img alt="" class="mw-100 h-200px h-sm-325px" src="../../../../assets/CartNull.png"/>
        </div>
        <!--end::Illustration-->
      </div>
      <!--end::Card body-->

    </div>
    <!--  如果有数据就显示数据  -->
    <div v-else>
      <!-- 列表开始 -->
      <div v-loading="loading" class="card mb-5 mb-xl-8"
           element-loading-background="rgba(0, 0, 0, 0.8)"
           element-loading-spinner="el-icon-loading"
           element-loading-text="拼命加载中"
           style="margin-left:3px;width:100%;">
        <div class="card-body py-3">
          <div class="table-responsive">
            <table id="kt_ecommerce_products_table"
                   class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
              <thead>
              <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">

                <th aria-controls="card_package_type" class="min-w-50px" colspan="1" rowspan="1"
                    style="width: 120px; "
                    tabindex="0">卡包类型
                </th>
                <th aria-label="collection_account" class=" min-w-50px" colspan="1" rowspan="1"
                    style="width: 120px; " tabindex="0">收款账号（或银行卡号）
                </th>
                <th aria-label="name_of_payee" class=" min-w-50px" colspan="1" rowspan="1"
                    style="width: 120px;" tabindex="0">收款人姓名
                </th>
                <th aria-label="creation_time" class=" min-w-50px" colspan="1" rowspan="1"
                    style="width: 120px;" tabindex="0">创建时间
                </th>
                <th aria-label="operation" class=" sorting_disabled" colspan="1"
                    rowspan="1" style="width: 120px;">操作
                </th>
              </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
              <tr v-for="(item,index) in cardList" :key="index">
                <!-- 卡包类型 -->
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center">{{ item.type }}  </span>
                  </div>
                </td>
                <!-- 收款账号（或银行卡号） -->
                <td class=" pe-0">
                  <span class="fw-light" v-if="item.type!='微信'">{{ item.content }} </span>
                  <span class="fw-light" v-else>
                    <img :src="item.content" width="50px" alt="">  
                  </span>
                </td>
                <!-- 收款人姓名 -->
                <td class=" pe-0">
                  <span class="fw-light" >{{ item.name }}</span>
                </td>
                <!-- 创建时间 -->
                <td class=" pe-0">
                  <span class="">{{ item.crTime }}</span>
                </td>
                <!-- 操作 -->
                <td class="">
                  <el-button type="primary" size="small" @click="openCradEdit(item.id)">编辑</el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <Pagination :total-items="totalCount" @page-change="pageChange"></Pagination>
          </div>
        </div>
      </div>
    </div>
    <ModalDialog ref="createCard" title="添加卡包" width="45%">
      <CardCreatePopUp ref="form" @getOrderTwoList="createCardSubmit">
        <el-form-item>
          <el-button type="primary" @click="$refs.form.onSubmit()">立即创建</el-button>
          <el-button @click="$refs.createCard.close()">取消</el-button>
        </el-form-item>
      </CardCreatePopUp>
    </ModalDialog>
    <ModalDialog ref="CardEditPopUp" title="卡包编辑" width="45%" v-show="show" >
      <CardEditPopUp ref="formCardEditPopUp" @success-submit="successSubmit" :getOrderTwoList="getOrderTwoList">
        <template slot="button">
          <el-form-item>
            <el-button type="primary" @click="clickRechargeEdit">提交</el-button>
            <el-button @click="clickCancelRechargeEdit">取消</el-button>
          </el-form-item>
        </template>
      </CardEditPopUp>
    </ModalDialog>
  </div>
</template>
<script>
import CardCreatePopUp from "./CardCreatePopUp.vue";
import CardEditPopUp from "./CardEditPopUp.vue";
import {getCardListApi} from "@/api/cardApi";

export default {
  name: "CardNull",
  components: {
    CardEditPopUp,
    CardCreatePopUp,
  },
// 组件的data属性
  data() {
    return {
      show:false,
      loading: false,
      cardList: [],
      totalCount: 10,
      params: {
        iDisplayStart: 1,
        iDisplayLength: 10,
      },
    };
  },
  methods: {
    createCardSubmit(){
      this.$refs.createCard.close()
      this.getOrderTwoList()
    },
    async getOrderTwoList() {
      this.loading = true
      let {data} = await getCardListApi(this.params);
      this.cardList = data.data;
      this.totalCount = data.total
      this.loading = false
    },
    pageChange(currentPage, pageSize) {
      this.params.iDisplayStart = currentPage;
      this.params.iDisplayLength = pageSize;
      this.getOrderTwoList();
    },
    clickRechargeEdit() {
      if (this.$refs.formCardEditPopUp.submit()) {
        setTimeout(() => {
          this.$refs.formCardEditPopUp.clearData()
          this.$refs.CardEditPopUp.close()
        }, 1000)
      }
    },
    successSubmit(){
      this.$refs.CardEditPopUp.close();
      this.getOrderTwoList();
    },
    clickCancelRechargeEdit() {
      // this.$refs.formCardEditPopUp.clearData()
      this.$refs.CardEditPopUp.close()
    },
    openCradEdit(id) {
      this.show=true;
      this.$refs.CardEditPopUp.open();
      this.$refs.formCardEditPopUp.id = id
      this.$refs.formCardEditPopUp.getCardById()
    }
  },
  created() {
    this.getOrderTwoList();
  },
}
</script>

<style scoped>

</style>