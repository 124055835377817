import service from "@/utils/request"
export function getECardListApi(data) {
    return service({
        url: '/ecard-combo-gift-voucher-item/getItemList',
        method: 'get',
        params: data
    })
}
//卡密生成
export function generateKeyApi(data) {
    return service({
        url: '/ecard-combo-gift-voucher-item/generate',
        method: 'post',
        data
    })
}

//修改卡密状态
// /ecard-combo-gift-voucher-item/updateBatch
export function updateBatchKeyApi(data) {
    return service({
        url: '/ecard-combo-gift-voucher-item/updateBatch',
        method: 'put',
        data
    })
}
//获取当前卡卷商品
export function getECardGoodsListApi(data) {
    return service({
        url: '/e-card-combo-gift-voucher/getGoodsList',
        method: 'get',
        params: data
    })
}
//删除已选商品
export function deleteDelGoodsApi(data) {
    return service({
        url: '/e-card-combo-gift-voucher/deleteGoods',
        method: 'delete',
        params: data
    })
}
//新增商品
export function addECardGoodsApi(data) {
    return service({
        url: '/e-card-combo-gift-voucher/addGoods',
        method: 'post',
        data
    })
}
//删除未使用卡密
export function deleteAllItemApi(data) {
    return service({
        url: '/ecard-combo-gift-voucher-item/deleteAllItem',
        method: 'delete',
        params: data
    })
}
//获取商城列表
// getECardShopListApi
export function getECardShopListApi(data) {
    return service({
        url: '/wxShop/getWxShopByStaffId',
        method: 'get',
        params: data
    })
}
//新增商城电子卡卷
//
export function addWxShopECardVoucherApi(data) {
    return service({
        url: '/wxShopECardVoucher/add',
        method: 'post',
        data
    })
}
// 修改商城电子卡券
// /wxShopECardVoucher/update
export function updateWxShopECardVoucherApi(data) {
    return service({
        url: '/wxShopECardVoucher/update',
        method: 'put',
        data
    })
}
// 获取商城卡密
export function getWxShopECardVoucherItemApi(data) {
    return service({
        url: '/wxShopECardVoucherItem/getItemList',
        method: 'post',
       data
    })
}
// generateWxShopKeyApi
export function generateWxShopKeyApi(data) {
    return service({
        url: '/wxShopECardVoucherItem/generate',
        method: 'post',
        data
    })
}
//更新商城卡密状态
export function updateBatchWxShopKeyApi(data) {
    return service({
        url: '/wxShopECardVoucherItem/batchUpdateStatus',
        method: 'put',
        data
    })
}
//删除卡包
export function deleteECardApi(data) {
    return service({
        url: '/e-card-combo-gift-voucher/delete',
        method: 'delete',
        params:data
    })
}

//删除商城卡包
// /wxShopECardVoucher/delete
export function deleteWxshopECardApi(data) {
    return service({
        url: '/wxShopECardVoucher/delete',
        method: 'delete',
        params:data
    })
}
// deleteAllWxShopItemApi
// 删除微信shop未使用卡密
export function deleteAllWxShopItemApi(data) {
    return service({
        url: '/wxShopECardVoucherItem/deleteAllItem',
        method: 'delete',
        params:data
    })
}