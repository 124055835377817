<template>
  <div>
    <el-collapse v-model="activeCollapse" @change="changeActiveCollapse">
      <el-collapse-item name="1">
        <template slot="title">
          <span style="font-size:16px;font-weight:600;padding-left: 10px">平台推荐商城&nbsp;&nbsp;</span>
          <span style="font-size:12px;color:#999;">精选现金卡消费商城模板,利润有保障</span>
          <a class="btn btn-sm btn-primary" style="font-size:12px;margin-left: 10px;padding: calc(.1rem + 1px) calc(1rem + 1px)">点击{{activeCollapseTxt}}</a>

        </template>
        <div class="post d-flex flex-column-fluid">
          <div class="container" style="max-width: 100%">
            <div class="row g-5 g-xl-8">
              <div class="col-1"></div>
              <div class="col-2 cardItem" v-for="(item, index) in recommendShopData" :key="index">
                <img style="width: 100%" :src="item.picUrl" alt="">
                <div class="wrap">
                  <div class="wrapper">
                    <div class="preview cursor-pointer" @click="openMobileModalBox(item.id)"><i class="el-icon-view"></i> 在线预览</div>
                    <div style="text-align:center;">
                      <img style="margin-top:30px;" :src="item.pageUrl" alt="">
                      <div style="color:#999;margin-top:10px;">微信扫一扫预览</div>
                    </div>
                    <div style="text-align:center;">
                      <div class="recommendStoreTitle">{{ item.name }}</div>
<!--                      <div style="color:#fff;margin-top:10px;">{{ item.desc }}</div>-->
                    </div>

                    <div class="recommendStoreDetail">
                      <div>
                        <div>商城综合利润率</div>
                        <div style="font-size:16px;">{{ item.right }}%</div>
                      </div>
                      <div style="text-align:right;">
                        <div>商城预设商品数</div>
                        <div style="font-size:16px;">{{ item.goodsNum }}款</div>
                      </div>
                    </div>
                    <el-button type="primary" @click="copyShopFromTemplate(item.id,item.publication)">复制此商城模板</el-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-1"></div>
          </div>

        </div>
        <el-pagination style="display:flex;justify-content:center;margin-top: 50px" @size-change="handleSizeChange"
                       @current-change="handleCurrentChange" :current-page="queryParams.pageIndex" :page-sizes="[5]"
                       :page-size="queryParams.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount"
                       background>
        </el-pagination>
      </el-collapse-item>

    </el-collapse>
    <el-dialog title="复制商城"  v-if="copyShopVisible"  :visible.sync="copyShopVisible" width="80%" @close="closeCopyShopDialog" destroy-on-close="true">
      <template #title>
        <div class="modal-content">
          <div class="modal-header">
            <h2>复制商城</h2>
          </div>
        </div>
      </template>
      <copyShop ref="copyShopModal" :shopId="chooseShopId" :shopPublication="chooseShopPublication" @closeCopyShopDialog="closeCopyShopDialog"></copyShop>
    </el-dialog>

    <MobileModalBox  ref="MobileModalBox"/>

  </div>
</template>
<script>
  import {getRecommendShop} from '@/api/shopApi'
  import QRCode from 'qrcode'
  import copyShop from "@/views/Shop/component/createShopModal/copyShop.vue";
  export default {
    components:{
      copyShop

    },
    props:{

    },
    data(){
      return {
        copyShopVisible:false,
        activeCollapse:['1'],
        queryParams:{
          pageIndex:1,
          pageSize:5
        },
        recommendShopData:[],
        totalCount:5,
        activeCollapseTxt:"关闭",
        chooseShopId:-1,
        chooseShopPublication:0
      }
    },
    methods:{
      async getRecommendShopList(){
        let {data} = await getRecommendShop(this.queryParams);
        this.recommendShopData = data.rows
        this.totalCount = data.count
        for (let i = 0; i < this.recommendShopData.length; i++) {
          const shopIdUrl ="https://h5.3jzc.cn/?shopId="+ this.recommendShopData[i].id;
          QRCode.toDataURL(shopIdUrl).then(res =>{
            this.recommendShopData[i].pageUrl = res
            console.log(this.recommendShopData)
          })
        }
      },
      changeActiveCollapse(val){
        // 这个是没打开的
        if(val.length<1){
          this.activeCollapseTxt="打开"
        }else{
          this.activeCollapseTxt="关闭"
        }
      },
      handleCurrentChange(){

      },
      handleSizeChange(){

      },
      copyShopFromTemplate(shopId,shopPublication){
        this.copyShopVisible = true
        this.chooseShopId = shopId
        this.chooseShopPublication = shopPublication
        console.log(shopId,shopType)
      },
      closeCopyShopDialog(){
        this.copyShopVisible = false
        this.$emit("closeCopyShopDialog");
      },
      openMobileModalBox(id){
        const shopIdUrl ="https://h5.3jzc.cn/?shopId="+ id;
        this.$refs.MobileModalBox.open(shopIdUrl);

      }

    },
    created() {
      this.getRecommendShopList();
    },
    updated() {
    }
  }

</script>

<style scoped>
.cardItem{
  position: relative;
}
.cardItem:hover .wrap {
  display: block;
}

.cardItem>img {
  width: 100%;
  aspect-ratio: 280 / 500;
}

.wrap {
  width: 100%;
  aspect-ratio: 280 / 500;
  padding: 1px;
  box-sizing: border-box;
  background: #040404af;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: space-around;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  aspect-ratio: 280 / 500;
}
.preview {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #fff;
}
.recommendStoreTitle {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
}

.recommendStoreDetail {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

</style>