<template>
  <div>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
      <!-- <GroupTools :typeArr="['group']"></GroupTools> -->
  </div>
</template>
<script>
export default {
  name: '',
// 组件的props定义,用于子组件接收父组件传值
  props: {},
  data() {
    return {};
  },
// 组件的方法
  methods: {},
// 在created钩子中执行的操作
  created() {

  }
};
</script>

<!-- 组件的样式 -->
<style scoped>
</style>