<template>
  <div>
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="190px"
    >
      <el-form-item label="商户号" prop="wxMacId">
        <el-input v-model="form.wxMacId" placeholder="商户号"></el-input>
      </el-form-item>
      <el-form-item label="商户名称" prop="wxMacName">
        <el-input
            v-model="form.wxMacName"
            placeholder="商户名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="商户密钥" prop="wxMacPartnerKey">
        <el-input
            v-model="form.wxMacPartnerKey"
            placeholder="商户密钥"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即添加</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {addMyWxMacApi, updateMyWxMacApi} from '@/api/merchantApi';

export default {
  data() {
    return {
      form: {
        // 收款账号
        wxMacId: "",
        wxMacName:"",
        wxMacPartnerKey:""
      },
      rules: {

      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          //  验证完后
          let {data} = await addMyWxMacApi(this.form)
          this.$emit('close')
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        }
      })

    },
  }
}

</script>
<style scoped>

</style>