<template>
  <div style="padding: 0 5% 0  5%;">
    <el-form :model="copyForm" :rules="rules" ref="copyForm" label-width="100px" label-position="top">
      <el-row>
        <el-col :span="6">
          <el-form-item prop="name">
                        <span slot="label" style="display:inline-block;">
                            商城名称
                            <el-tooltip effect="dark" content="将在首页展示" placement="bottom">
                                <i class='el-icon-question'/>
                            </el-tooltip>
                        </span>
            <el-input v-model="copyForm.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="6">
          <el-form-item prop="customerTel">
                        <span slot="label" style="display:inline-block;">
                            客服电话
                            <el-tooltip effect="dark" content="客户点击客服按钮将展示该手机号" placement="bottom">
                                <i class='el-icon-question'/>
                            </el-tooltip>
                        </span>
            <el-input v-model="copyForm.customerTel"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="6">
<!--          <el-form-item label="首页logo(建议尺寸：190x57)" prop="bannerUrl">-->
<!--            <uploadImg moduleName="wxShop" :imgUrl="copyForm.bannerUrl" @upload-success="uploadSuccess"-->
<!--                       @cancel-success="cancelSuccess"></uploadImg>-->
<!--          </el-form-item>-->
          <el-form-item prop="wxCustomer">
                        <span slot="label" style="display:inline-block;">
                            微信客服
                            <el-tooltip effect="dark" content="客户将直接和企业微信客服进行沟通" placement="bottom">
                                <i class='el-icon-question'/>
                            </el-tooltip>
                        </span>
            <el-input v-model="copyForm.wxCustomer"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="8">
          <el-form-item prop="publication" label-position="top">
                        <span slot="label" style="display:inline-block;">
                            商城发布到
                          <!-- <el-tooltip effect="dark" content="标题" placement="bottom">
                              <i class='el-icon-question' />
                          </el-tooltip> -->
                        </span>
            <el-radio-group disabled v-model="copyForm.publication" size="small">
              <el-radio border :label="2">H5页面</el-radio>
              <el-radio border :label="1">微信小程序</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item prop="shopType">
                        <span slot="label" style="display:inline-block;">
                            商城类型:
                          <!-- <el-tooltip effect="dark" content="标题" placement="bottom">
                              <i class='el-icon-question' />
                          </el-tooltip> -->
                        </span>
            <el-radio-group v-model="copyForm.shopType" size="small" :disabled="copyForm.id!=null">
              <el-radio border :label="1">积分</el-radio>
              <el-radio border :label="2">现金</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="2">&nbsp;
        </el-col>
        <el-col :span="6">
          <el-form-item label="到期日期" prop="endTime">
            <el-date-picker v-model="copyForm.endTime" type="date" :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>

      </el-row>


      <el-row>
        <el-col :span="6" v-if="copyForm.shopType == 1">
          <el-form-item prop="isCash">
                        <span slot="label" style="display:inline-block;">
                            是否开通现金支付
                            <el-tooltip effect="dark" content="当用户积分不足时可以支付现金购买" placement="bottom">
                                <i class='el-icon-question'/>
                            </el-tooltip>
                        </span>
            <el-radio-group v-model="copyForm.isCash" size="small">
              <el-radio border :label="1">开通</el-radio>
              <el-radio border :label="2">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="2" v-if="copyForm.shopType == 1">&nbsp;</el-col>
        <el-col :span="6" v-if="copyForm.isCash==1">
          <el-form-item prop="fId">
            <span slot="label" style="display:inline-block;">
                选择商户号:
                <span style="color: red;font-size: 10px;">在财务中添加商户号</span>
            </span>
            <el-select v-model="copyForm.fId" placeholder="请选择商户号">

              <el-option v-for="(item, index) in macList" :key="index" :label="item.wxMacName"
                         :value="item.wxMacId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="8" v-if="copyForm.shopType == 1">
          <el-form-item prop="scoreRatio">
                        <span slot="label" style="display:inline-block;">
                            货币单位与比例
                            <el-tooltip effect="dark" content="自定义积分的单位及现金的比例，如输入100积分，则表示100积分等于1元"
                                        placement="bottom">
                                <i class='el-icon-question'/>
                            </el-tooltip>
                        </span>
            <div class="d-flex" style="display: flex;flex-direction: row">
              <el-input class="mySelectRightNoRadius"  v-model="copyForm.scoreRatio"></el-input>
              <el-input class="mySelectLeftNoRadius" v-model="copyForm.unit"></el-input>
              <span style="min-width: 30px">&nbsp;=&nbsp;1元</span>
            </div>

          </el-form-item>
        </el-col>



      </el-row>
      <el-row>
        <el-col :span="16">
          <el-form-item prop="provinceId">
                            <span slot="label" style="display:inline-block;">
                            商城默认地址
                            <el-tooltip effect="dark" content="将筛选出在该地址有货的商品" placement="bottom">
                                <i class='el-icon-question'/>
                            </el-tooltip>
                            </span>
            <el-col :span="5">
              <el-select v-model="copyForm.provinceId" clearable placeholder="请选择省" filterable @change="provinceChange">
                <el-option v-for="(item, index) in provinceList" :key="index" :label="item.name"
                           :value="item.kid"></el-option>
              </el-select>
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="5">
              <el-select v-model="copyForm.cityId" placeholder="请选择市" filterable @change="cityChange">
                <el-option v-for="(item, index) in cityList" :key="index" :label="item.name"
                           :value="item.kid"></el-option>
              </el-select>
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="5">
              <el-select v-model="copyForm.countyId" :key="index" placeholder="请选择县" filterable>
                <el-option v-for="(item, index) in countyList" :key="index" :label="item.name"
                           :value="item.kid"></el-option>
              </el-select>
            </el-col>

          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="首页banner(建议比例16：9,大小低于10M)" prop="bannerUrl">
            <uploadImg moduleName="wxShop" :imgUrl="copyForm.bannerUrl" @upload-success="uploadBannerSuccess"
                       @cancel-success="cancelBannerSuccess"></uploadImg>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="商城logo(大小低于10M)" prop="logoUrl">
            <uploadImg moduleName="wxShop" :imgUrl="copyForm.logoUrl" @upload-success="uploadLogoSuccess"
                       @cancel-success="cancelLogoSuccess"></uploadImg>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="display: flex;flex-direction: row;justify-content: flex-end">
        <el-button type="primary" @click="create('copyForm')">创建</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import uploadImg from "../uploadImg.vue";
import {getMacSelect, getWxshopChooseTopic,copySystemWxShop} from '@/api/shopApi';
import {getCategoryList} from '@/api/categoryApi';
import {getJdRegionList} from '@/api/jdRegionApi';
import {getWxShopTopicList} from '@/api/shopTopicApi'

export default {
  components: {
    uploadImg
  },
  props: {
    shopId: {
      type: Number,
      default: null,
    },
    shopPublication: {
      type: Number,
      default: null,
    }
  },
  data() {
    const checkTel = (rule, value, callback) => {
      const pattern = /^(?:\+?86)?1[3-9]\d{9}$|^0[1-9]\d{1,2}-?\d{7,8}$|^400-?\d{3}-?\d{4}$/;
      setTimeout(() => {
        if (!pattern.test(value)) {
          return callback(new Error('输入的客服电话格式必须为手机号、座机号、400电话'))
        }
        return callback()
      }, 1000);
    };
    const checkRegion = (rule, value, callback) => {
      if (this.copyForm.provinceId && this.copyForm.cityId && this.copyForm.countyId) {
        return callback()
      } else {
        return callback(new Error('请选择商城默认地址'))
      }
    };

    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now();
        },
      },
      arrId: [],
      methodsLimit: 1,
      copyForm:{
        id:null,
        chooseShopId:this.shopId,
        name: '',
        customerTel: '',
        wxCustomer: '',
        publication:this.shopPublication,//商城发布到哪 1 微信小程序  2 H5
        shopType:1, //商城类型  1 积分 2 现金
        fId: '',
        scoreRatio: '1',
        unit: '积分',
        isCash:1,//是否开启现金支付
        wxShopTopicIds:[],
        bannerUrl:'',
        logoUrl:'',
        templateId:null,
        endTime:'',
        provinceId:'',
        cityId:'',
        countyId:''
      },
      provinceList: [],
      cityList: [],
      countyList: [],
      macList: [],
      topicList: [],
      checked: false,
      rules: {
        name: [
          {required: true, message: '请输入商城名称', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
        ],
        customerTel: [
          {required: true, message: '请输入客服手机号', trigger: 'change'},
          {validator: checkTel, trigger: 'blur'}
        ],
        fId: [
          {required: true, message: '请选择商户号', trigger: 'change'}
        ],
        endTime: [
          {required: true, message: '请选择商城的结束日期', trigger: 'blur'},
        ],

      }
    };
  },
  watch: {},
  methods: {
    async getShopInfo(data) {
      this.copyForm = data
      if (this.copyForm.id == null) {
        return
      }
      if (this.copyForm.provinceId && this.methodsLimit == 1) {
        const regionQuery = {};
        regionQuery.pId = this.copyForm.provinceId
        const {data} = await getJdRegionList(regionQuery);
        this.cityList = data
      }
      if (this.copyForm.cityId && this.methodsLimit == 1) {
        const regionQuery = {};
        regionQuery.pId = this.copyForm.cityId
        const {data} = await getJdRegionList(regionQuery);
        this.countyList = data
      }
      if (this.copyForm == 1) {
        let queryParam = {
          wxShopId: this.copyForm.id
        }
        let {data: res} = await getWxshopChooseTopic(queryParam)
        this.copyForm.wxShopTopicIds = res
        console.log(this.copyForm.wxShopTopicIds)

      }
      this.arrId = this.copyForm.wxShopTopicIds
      this.methodsLimit++;
    },
    async getMacSelect() {
      let macQuery = {}
      let {data} = await getMacSelect(macQuery)
      this.macList = data
      console.log(this.macList && this.macList.length > 0);
      if (this.macList && this.macList.length > 0) {
        this.copyForm.fId = this.macList[0].wxMacId
        console.log("getMacSelect")
        console.log(this.copyForm)
      }
    },
    async getCategoryList(parentId) {
      const {data} = await getCategoryList(parentId);
      console.log(data)
    },
    async getProvinceRegionList() {
      const regionQuery = {};
      regionQuery.pId = 0
      const {data} = await getJdRegionList(regionQuery);
      console.log(data)
      this.provinceList = data
    },
    async provinceChange(kid) {
      if(kid==null || kid==''){
        this.countyList = []
        this.cityList=[]
        this.copyForm.cityId = ""
        this.copyForm.countyId = ""
        return
      }

      const regionQuery = {};
      regionQuery.pId = kid
      const {data} = await getJdRegionList(regionQuery);
      this.cityList = data
      this.countyList = []
      this.copyForm.cityId = ""
      this.copyForm.countyId = ""
    },
    async cityChange(kid) {
      console.log("cityChange被触发")
      const regionQuery = {};
      regionQuery.pId = kid
      const {data} = await getJdRegionList(regionQuery);
      this.countyList = data
      this.copyForm.countyId = ""

    },

     create(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if(this.copyForm.shopType==2){
            this.copyForm.unit='元'
          }

          let {data} = await copySystemWxShop(this.copyForm)
          if(data){
            this.$message({
              type: 'success',
              message: '复制成功!'
            });
            this.$emit("closeCopyShopDialog");
          }else{
            this.$message({
              type: 'error',
              message: '复制失败，请联系系统管理员!'
            });
          }
        } else {
          await this.$confirm("请检查输入参数", "提示");
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    updateAddForm() {
      this.$emit("addForm-change", this.copyForm);
    },
    uploadBannerSuccess(res) {
      this.copyForm.bannerUrl = res.data.fileUrl

    },
    cancelBannerSuccess(res) {
      if (res) {
        this.copyForm.bannerUrl = ""
      }
    },
    uploadLogoSuccess(res) {
      this.copyForm.logoUrl = res.data.fileUrl

    },
    cancelLogoSuccess(res) {
      if (res) {
        this.copyForm.logoUrl = ""
      }
    },
    async getTopicList() {
      let topicListQuery = {
        pageIndex: 1, pageSize: 100
      }
      let {data} = await getWxShopTopicList(topicListQuery)
      if (data) {
        this.topicList = data.rows
      }
    },

  },
  // 在created钩子中执行的操作
  created() {
    console.log("stepper1的addForm", this.addForm)
    setTimeout(() => {
      // 这里写需要延迟执行的逻辑
      this.getMacSelect();
    }, 500);

    this.getTopicList()
    this.getProvinceRegionList();
  },
  updated() {
  }
}
</script>


<style scoped>
/* 修改i下间距 */
::v-deep .el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0;
}
.mySelectRightNoRadius{
  max-width: 100px;
}
::v-deep .mySelectRightNoRadius .el-input__inner{
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right-width: 0px;
  max-width: 100px;
}
.mySelectLeftNoRadius{
  max-width: 80px;
}
::v-deep .mySelectLeftNoRadius .el-input__inner{
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  max-width: 80px;
}
</style>