<template>
  <div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div id="kt_content_container" class="container"
           style="max-width:100%;flex-direction:row!important;display:flex;">
        <div class="card mb-5 mb-xl-8" style="margin-left:3px;width:100%;">

          <div class="card-body py-3">
            <div class="table-responsive">
              <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" id="userTable">
                <!--begin::Table head-->
                <thead>
                <tr class="fw-bolder text-muted bg-light">
                  <th class="ps-4 min-w-80px rounded-start">积分</th>
                  <th class="min-w-80px">类型</th>
                  <th class="min-w-80px">时间</th>
                </tr>
                </thead>
                <tbody id="userScoreTableBody">
                <tr v-for="(item,index) in userScoreList" :key="index">
                  <td><span class="text-muted fw-bold text-muted d-block fs-7">{{item.score}}</span></td>
                  <td><span class="text-muted fw-bold text-muted d-block fs-7">{{item.type}}</span></td>
                  <td><span class="text-muted fw-bold text-muted d-block fs-7">{{item.crTime}}</span></td>
                </tr>
                <tr v-if="userScoreList==null || userScoreList.length<1">
                  <td colspan="3" style="width: 100%;">
                    <span style="display:block;text-align: center;margin:0 auto">暂无数据</span>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
          <el-pagination style="display:flex;justify-content:flex-end" @size-change="handleSizeChange"
                         @current-change="handleCurrentChange" :current-page="queryParam.pageIndex"
                         :page-sizes="[10, 20, 30, 50, 100]" :page-size="queryParam.pageSize"
                         layout="total, sizes, prev, pager, next, jumper" :total="count" background>
          </el-pagination>
          <!-- 分页按钮 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getWxShopUserScoreList} from "@/api/shopUserApi"

export default {
  components: {},
  props: {
    currentWxShopId: {
      type: Number,
      default: null,
    },
    currentShopUserRelationId: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      queryParam: {
        id: this.currentShopUserRelationId,
        pageIndex: 1,
        pageSize: 10,
        wxShopId: this.currentWxShopId
      },
      userScoreList: [],
      count:0
    }
  },
  methods: {
    async getShopUserScoreList() {
      console.log(this.queryParam)
      const {data} =await getWxShopUserScoreList(this.queryParam);
      this.userScoreList = data.rows
      this.count = data.count
    },
    handleCurrentChange(param) {
      this.queryParam.pageIndex = param
      this.getShopUserScoreList();
    },
    handleSizeChange(param) {
      this.queryParam.pageIndex = 1
      this.queryParam.pageSize = param
      this.getShopUserScoreList();
    },
  },
  created() {
    this.getShopUserScoreList();

  },
  updated() {

  }

}
</script>