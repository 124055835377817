<template>
  <div>
    <canvas ref="canvas" width="600px" height="600px"></canvas>
  </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
  mounted() {
    this.generateQRCodeWithLogo();
  },
  methods: {
    async generateQRCodeWithLogo() {
      try {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext('2d');

        const qrCodeOptions = {
          errorCorrectionLevel: 'H',
          type: 'image/png',
          quality: 1,
          margin: 1,
          color: {
            dark: '#000000ff',
            light: '#ffffffff'
          }
        };

        const qrCodeDataUrl = await QRCode.toDataURL('https://example.com', qrCodeOptions);
        const qrCodeImage = new Image();
        qrCodeImage.src = qrCodeDataUrl;

        const logoImage = new Image();
        logoImage.src = './images/logo.png'; // 替换成你的Logo图片路径

        qrCodeImage.onload = () => {
          canvas.width = qrCodeImage.width;
          canvas.height = qrCodeImage.height;

          ctx.drawImage(qrCodeImage, 0, 0, qrCodeImage.width, qrCodeImage.height);
          const logoSize = qrCodeImage.width * 0.3; // 调整Logo大小
          const logoPositionX = (qrCodeImage.width - logoSize) / 2;
          const logoPositionY = (qrCodeImage.height - logoSize) / 2;

          logoImage.onload = () => {
            ctx.drawImage(logoImage, logoPositionX, logoPositionY, logoSize, logoSize);
          };
        };
      } catch (error) {
        console.error('Error generating QR code with logo:', error);
      }
    }
  }
};
</script>
