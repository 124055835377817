<template>
  <!-- 用户详情-->
  <div class="app-navbar flex-shrink-0" style="z-index: 100">
    <div class="app-navbar-item" id="kt_header_user_menu_toggle">
      <div
          class="d-flex align-items-center border border-dashed border-gray-300 rounded p-2"
          data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
      >
        <div class="cursor-pointer symbol me-3 symbol-35px symbol-lg-45px">
          <el-avatar shape="square" size="large"
                     src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"></el-avatar>
        </div>
        <div class="me-4">
          <router-link to="/" class="text-dark text-hover-primary fs-6 fw-bold"
          >{{ myUserInfo.nickName }}
          </router-link
          >
          <router-link
              to="/"
              class="text-gray-400 text-hover-primary fs-7 fw-bold d-block"
          >{{ myUserInfo.companyName }}
          </router-link
          >
        </div>
        <i class="ki-outline ki-down fs-2 text-gray-500 pt-1"></i>
      </div>
      <div
          class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
          data-kt-menu="true"
      >
        <div class="menu-item px-3">
          <div class="menu-content d-flex align-items-center px-3">
            <div class="symbol symbol-50px me-5">
              <el-avatar shape="square" size="large"
                         src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"></el-avatar>
              <!--<img alt="Logo" src="./../../../assets/user.png"/>-->
            </div>
            <div class="d-flex flex-column">
              <div class="fw-bold d-flex align-items-center fs-5">{{ myUserInfo.nickName }}</div>
              <a href="#" class="fw-semibold text-muted text-hover-primary fs-7"
              >{{ myUserInfo.companyName }}</a
              >
            </div>
          </div>
        </div>
        <div class="separator my-2"></div>

        <div class="menu-item px-5">
          <a href="javascript:void(0)" class="menu-link px-5" @click="test"
          >消息</a
          >
          <!-- <router-link to="/messages" class="menu-link px-5">消息<span class="badge badge-light-success">3</span></router-link> -->
        </div>
        <div class="menu-item px-5">
          <a href="javascript:void(0);" class="menu-link px-5" @click="dialogVisible=true">修改密码</a>
        </div>
        <div class="menu-item px-5">
          <a
              href="javascript:void(0);"
              @click="loginOutMethod"
              class="menu-link px-5"
          >退出登录</a
          >
        </div>
      </div>
    </div>
    <el-dialog
        title="修改密码"
        :visible.sync="dialogVisible"
        width="30%"
        :modal="false"
    >
      <el-form :model="form" :rules="rulesCreate" ref="createFrom"  label-width="100px">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input v-model="form.oldPassword"
                    autocomplete="off"
                    placeholder="请输入旧密码"
                    show-password
                    style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="form.newPassword"
                    autocomplete="off"
                    placeholder="请输入新密码"
                    show-password
                    style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="验证新密码" prop="secondPassword">
          <el-input v-model="form.secondPassword"
                    autocomplete="off"
                    placeholder="请再输入一次新密码"
                    show-password
                    style="width: 300px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeForm">取 消</el-button>
        <el-button type="primary" @click="updatePawd">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import {changePasswordApi, getMyInfoApi} from "@/api/userApi";

export default {
  name: "UserComponent",
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
        // password 是表单上绑定的字段
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        oldPassword:"",
        newPassword: "",
        secondPassword: "",
      },
      rulesCreate: {
        oldPassword: [
          {required: true, message: "请输入旧密码", trigger: "blur"},
          // {min: 6, max: 16, message: "长度在 6 到 16 个字符", trigger: "blur"},
        ],
        newPassword: [
          {required: true, message: "请再次输入新密码", trigger: "blur"},
          {min: 6, max: 16, message: "长度在 6 到 16 个字符", trigger: "blur"}
        ],
        secondPassword: [
          {required: true, validator: validatePass2, trigger: 'change'},
        ],
      },
      dialogVisible: false,
      myUserInfo: {
        companyName: "",
        img: null,
        nickName: "",
        tel: "",
        userName: "",
      },

    };
  },
  methods: {
    closeForm(){
      this.dialogVisible = false
      this.form={
        oldPassword:"",
        newPassword: "",
        secondPassword: "",
      }
    },
    updatePawd() {
      this.$refs.createFrom.validate(async (valid) => {
        if (valid) {
          await changePasswordApi(this.form)
          this.$message.success("修改成功")
          this.closeForm()
        }
        return false;
      });
    },
    ...mapActions("user", ["loginOut"]),
    loginOutMethod() {
      this.loginOut("1");
    },
    test() {
      this.$swal.fire({
        icon: "warning",
        title: "即将开放！",
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    async getMyInfo() {
      let {data} = await getMyInfoApi();
      this.myUserInfo = data
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    this.getMyInfo();
  }
};
</script>