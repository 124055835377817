<template>
  <div>
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
        label-width="100px"
    >
      <el-form-item label="充值金额" prop="money">
        <el-input v-model="ruleForm.money"></el-input>
      </el-form-item>
      <el-form-item class="receipt_form is-required" label="银行回执单">
        <el-upload
            ref="upload"
            :auto-upload="false"
            :http-request="handleChange"
            accept=".png,.jpg,.jpge"
            action="string"
            class="upload-demo"
            :on-change="changeFile"
            :file-list="fileList"
            :drag="imageUrl==''"
            :show-file-list="imageUrl==''"
        >
          <i class="el-icon-upload" v-show="imageUrl==''"></i>
          <div class="el-upload__text" v-show="imageUrl==''">
            将文件拖到此处，或<em>点击选取文件</em>
          </div>
          <div slot="tip" class="el-upload__tip" v-show="imageUrl==''">
            只能上传png、jpg、jpge文件，且不超过2MB
          </div>
          <div v-show="imageUrl">
            <el-avatar
                shape="square"
                :size="100"
                fit="contain"
                :src="imageUrl"
            ></el-avatar>
            点击修改银行回执单
          </div>
        </el-upload>
      </el-form-item>
      <slot name="button"></slot>
    </el-form>
    <el-descriptions :column="3" :size="size" border class="margin-top" title="对公信息">
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-house"></i>
          开户名
        </template>
        {{ obj.accountName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-map-location"></i>
          开户行
        </template>
        {{ obj.openingBank }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-bank-card"></i>
          账号
        </template>
        {{ obj.account }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import {uploadFileApi} from "@/api/uploadFileApi";
import {addStaffCz} from "@/api/staffCzApi";
import {mapState} from "vuex";

export default {
  name: "RechargeCreate",
  computed: {
    ...mapState("web", ["obj"])
  },
  data() {

    return {
      fileList: [],
      ruleForm: {
        money: "",
      },
      rules: {
        money: [
          {required: true, message: "请输入充值金额", trigger: "blur"},
          {validator: this.validateRechargeAmount, trigger: "change"},
        ],
      },
      imageUrl: "",
      status: false
    };
  },
  methods: {
    changeFile(a, b, c) {
      let url = URL.createObjectURL(b[b.length - 1].raw)
      this.imageUrl = url;
      this.fileList = b;
    },
    async handleChange(param) {
      if (this.fileList.length == 0) {
        this.$swal.fire({
          icon: "error",
          title: "请上传银行回执单",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }
      if (param.file.size >= 2 * 1024 * 1024) {
        this.$message({
          message: "文件过大,最大2MB",
          type: "warning",
        });
        return;
      }
      const data = new FormData();
      data.append("file", param.file);
      data.append("moduleName", "finance/rechargeRecords");
      if(!this.ruleForm.money) return;
      let {data: res} = await uploadFileApi(data);
      let filePath = res.filePath;
      let {data: resCz} = await addStaffCz({
        money: this.ruleForm.money,
        picUrl: filePath,
      });
      this.$swal.fire({
        icon: "success",
        title: "充值申请成功，请等待管理员审核",
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
      this.fileList = []
      this.imageUrl = "";
      this.$emit("submit")
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$refs.upload.submit()
        } else {
          return false;
        }
      });
    },
    validateRechargeAmount(rule, value, callback) {
      const amount = parseFloat(value);
      if (isNaN(amount) || amount <= 0) {
        callback(new Error('请正确填写充值金额'));
      } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
        callback(new Error('请正确填写充值金额'));
      } else {
        callback();
      }
    },
    clearData() {
      this.ruleForm.money = "";
      this.$refs.upload.clearFiles();
    },
  },
};
</script>

<style scoped>
</style>
