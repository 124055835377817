// user.js
const state = {
    webUrl: "1c-1g.com",
    obj: {
        webUrl: "",
        webIcp: "",
        email: "",
        phone: "",
        msg: "",
        webTitle: ""
    },
    webObj: [
        {
            webTitle: "一采一购",
            icon: "/favicon.ico",
            webUrl: "1c-1g.com",
            logoUrl: "./logo-2-dark.png",
            headerLogoUrl: "./logo.png",
            webIcp: "浙ICP备16046962号-4",
            email: "yigworld@vip.163.com",
            phone: "400-066-2158",
            msg: "2016-2024©一采一购",
            keFu: "./images/1c1g_kefu.png",
            tel:"400-066-2158",
        },
        {
            webTitle: "三江智采",
            icon: "/favicon.ico",
            webUrl: "https://api.1c1g.com",
            logoUrl: "./logo-2-dark.png",
            headerLogoUrl: "./logo.png",
            webIcp: "浙ICP备20022022号-4",
            email: "server@enower.com",
            phone: "400-840-8088",
            msg: "2016-2024©一采一购",
            keFu: "./images/1c1g_kefu.png",
            tel:"400-840-8088",
        },
        {
            webTitle: "浙礼道",
            icon: "/favicon.ico",
            webUrl: "https://saas.zhelidao.com",
            logoUrl: "./logo-2-dark-zhelidao.png",
            headerLogoUrl: "./logo-zhelidao.png",
            tel:"400-840-8088",
            webIcp: "浙ICP备20022022号-5",
            email: "server@enower.com",
            phone: "400-840-8088",
            msg: "2016-2024©浙礼道",
            keFu: "./images/1c1g_kefu.png",
        }
    ]
};

const mutations = {
    SET_WEBICP(state, payload) {
        state.obj = payload;
        document.title = payload.webTitle;
    },
    SET_ICON(state, payload) {
        const link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = payload.icon;
        const head = document.head || document.getElementsByTagName('head')[0];
        const oldFavicons = document.querySelectorAll('link[rel="icon"]');
        oldFavicons.forEach(oldFavicon => head.removeChild(oldFavicon));
        head.appendChild(link);
    }
};

const actions = {
    /**
     * 设置webIcp
     */
    setWebIcp({ commit }, amount) {
        commit('SET_WEBICP', amount)
        commit('SET_ICON', amount)
    },
    setIcon({ commit }, amount) {
        commit('SET_ICON', amount)
    }
};

const getters = {

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
