import service from "@/utils/request"
export function getGoodsGroupApi(data) {
    return service({
        url: '/goods_group',
        method: 'get',
        params:data
    })
}
//创建分组
export function addGoodsGroupApi(data) {
    return service({
        url: '/goods_group',
        method: 'post',
        data
    })
}
//修改分组
export function updateGoodsGroupApi(data) {
    return service({
        url: '/goods_group',
        method: 'PUT',
        data
    })
}
//根据staffid查询活动
export function getWxTopicByStaffIdApi() {
    return service({
        url: '/wxShopTopic/getWxTopicByStaffId',
        method: 'get',
    })
}

//根据staffid获取商城
export function getWxShopByStaffIdApi() {
    return service({
        url: '/wxShop/getWxShopByStaffId',
        method: 'get',
    })
}

//根据staffid获取分组
export function getGoodsGroupByStaffIdApi() {
    return service({
        url: '/goods_group/getGoodsGroupByStaffId',
        method: 'get',
    })
}

//根据分组id获取商品
export function getGoodsListByGroupIdApi(data) {
    return service({
        url: '/goods_group/getGoodsByGroupId',
        method: 'get',
        params: data
    })
}

// 商品推送
export function selectionGoodsApi(data) {
    return service({
        url: '/goods_group/goodsPush',
        method: 'post',
        data
    })
}

//将商品推送到分组
export function addGoodsApi(data) {
    return service({
        url: '/goods_group/addGoods',
        method: 'post',
        data
    })
}

export function deleteGroupApi(data) {
    return service({
        url: '/goods_group',
        method: 'delete',
        data,
    })
}

export function deleteGoodsInGroupApi(data) {
    return service({
        url: '/goods_group/deleteGoods',
        method: 'delete',
        data,
    })
}
