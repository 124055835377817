<!-- 组件模板内容 -->
<template>
  <div>
    <!-- {{ jdGoodsInfo }} -->
    <div style="display: flex">
      <div class="imgBox">
        <el-carousel>
          <el-carousel-item
            v-for="(item, index) in jdGoodsInfo.imgList"
            :key="index"
          >
            <img :src="item" width="100%" height="100%" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="titleBox">
        <div class="sku-name">
          <span>京东商品</span>
          {{ jdGoodsInfo.skuName }}
        </div>
        <div class="sku-price">
          <div>
            <span>市场价</span>
            <span>{{ jdGoodsInfo.price | format }}</span>
          </div>
          <div>
            <span>分销价</span>
            <span>{{ jdGoodsInfo.sjSupplyPrice | format }}</span>
          </div>
          <!--<div>
            <span>销售价</span>
            <span>{{ jdGoodsInfo.salePrice | format }}</span>
          </div>-->
        </div>
        <div class="sku-info">
        </div>
      </div>
    </div>
    <div class="imgBoxW">
      <img :src="item" width="100%" v-for="(item,index) in jdGoodsInfo.introduceWechat" alt="" :key="index">
    </div>
  </div>
</template>


<script>
import { getGoodsDetailById } from "@/api/goodsManageApi";
import { formatMoney } from "@/utils/formatMoney";
export default {
  name: "JdGoods",
  // 组件的props定义,用于子组件接收父组件传值
  props: {},
  filters: {
    format(num) {
      return formatMoney(num);
    },
  },
  // 组件的data属性
  data() {
    return {
      jdGoodsInfo: {
        skuId: 0,
        skuName:"",
        price: "",
        imgList: [

        ],
        headImg:"",
        lowestBuy: "",
        categoryAttrList: [

        ],
        supplyPrice: 0,
        sjSupplyPrice: "",
        salePrice: "",
        jdUrl: "",
        isJd: "1",
        introduceWechat: [

        ],
      },
    };
  },
  // 组件的方法
  methods: {
    async getJdGoodsInfo(id, isJd) {
      let { data } = await getGoodsDetailById({
        id,
        isJd,
      });
      this.jdGoodsInfo = data;
    },
  },
  // 在created钩子中执行的操作
  created() {},
};
</script>

<style>
#imgBoxW img{
  width: 100%;
}
</style>

<!-- 组件的样式 -->
<style scoped>
.imgBox {
  width: 400px;
  padding: 10px;
}
.titleBox {
  padding: 10px;
}
.sku-name {
  font: 700 16px Arial, "microsoft yahei";
  color: #666;
  padding-top: 10px;
  line-height: 28px;
  margin-bottom: 5px;
}
.sku-name span {
  display: inline-block;
  /* height: 14px; */
  padding: 5px;
  line-height: 14px;
  font-family: arial, simsun;
  color: #fff;
  background: #e4393c;
  margin-left: 6px;
  font-size: 14px;
  cursor: pointer;
}
.sku-price {
  font-size: 16px;
  font-weight: bold;
  background: #f3f3f3;
  padding: 10px;
}
.sku-price >div span:nth-child(1){
    font-size: 12px;
    font-weight: 100;
}
.sku-price >div span:nth-child(2){
    color: red;
    font-size: 18px;
    margin-left: 10px;
    font-weight:500;
    
}
.sku-info{
    margin-top: 10px;
}
.sku-info >div{
    margin-top: 5px;
}
.sku-info >div span:nth-child(1){

}
.sku-info >div span:nth-child(2){
    margin-left: 10px;
}
</style>