import service from "@/utils/request"

// 获取商品分类
export function getCategoryList(data) {
    return service({
        url: '/category/getCategoryList',
        method: 'get',
        params:data
    })
}

// 获取规格参数
export function getValueListApi(data) {
    return service({
        url: '/category/getValueList',
        method: 'get',
        params:data
    })
}