import service from "@/utils/request"
// 获取卡包列表
export function getApplyInvoiceForFxsListApi(data) {
    return service({
        url: '/applyInvoice/getApplyInvoiceForFxsList',
        method: 'get',
        params:data
    })
}

export function getApplyInvoiceInfo(data) {
    return service({
        url: '/applyInvoice/getApplyInvoiceInfo',
        method: 'get',
        params:data
    })
}
export function getApplyInvoiceInfoByCompany(data) {
    return service({
        url: '/applyInvoice/getApplyInvoiceInfoByCompany',
        method: 'get',
        params:data
    })
}
//查询申请开票订单列表
// /order/sj/getApplyForInvoicingOrderList
export function getApplyForInvoicingOrderListApi(data) {
    return service({
        url: '/order/sj/getApplyForInvoicingOrderList',
        method: 'get',
        params:data
    })
}
//单个申请开票
export function addApplyInvoiceBySqkpApi(data) {
    return service({
        url: '/applyInvoice/addApplyInvoiceBySqkp',
        method: 'post',
        data
    })
}
//批量申请开票
export function addApplyInvoiceByPlSqkpApi(data) {
    return service({
        url: '/applyInvoice/addApplyInvoiceByPlSqkp',
        method: 'post',
        data
    })
}