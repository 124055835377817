<template>
  <div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div id="kt_content_container" class="container"
           style="margin-top:10px;max-width:100%;flex-direction:row!important;display:flex; flex-wrap: wrap;">
        <!-- 搜索开始 -->
        <div style="width: 100%; margin-bottom: 10px;">
          <form action="#" id="category_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem; display: flex;">
                <label class="el-form-item__label fw-bolder text-dark" style="width: 90px; padding-right: 7px;">模板名称：</label>
                <div class="demo-input-suffix">
                  <el-input
                      @change="change"
                      placeholder="请输入内容"
                      prefix-icon="el-icon-search"
                      v-model.trim="params.title"
                      clearable @clear="getInstallList">
                  </el-input>
                </div>
                <el-button style="margin-left: 10px" type="primary" size="small" @click="getInstallList"> 搜索</el-button>

                <el-button style="margin-left: auto;  margin-right: 10px" type="primary" size="small" icon="el-icon-plus"
                           @click="$router.push({ name: 'AddOrUpdateInstall' })"> 新增上门安装模板
                </el-button>

                <el-button style="margin-right: 20px" type="primary" size="mini" icon="el-icon-edit" :disabled="ids.length < 1"
                           @click="updateDialog = true"> 批量修改模板状态
                </el-button>
                <el-dialog
                    title="提示"
                    :visible.sync="updateDialog"
                    width="30%">
                  <span>修改选中的模板状态</span>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="updateDialog = false">取 消</el-button>
                    <el-button type="danger" @click="updateInstallIsStatus(0,2)">禁 用</el-button>
                    <el-button type="primary" @click="updateInstallIsStatus(0,1)">启 用</el-button>
                  </span>
                </el-dialog>
                <el-button slot="reference" style=" margin-right: 10px" type="primary" size="mini" icon="el-icon-delete"
                           :disabled="ids.length < 1" @click="deleteDialog = true"> 批量删除模板
                </el-button>
                <el-dialog
                    title="提示"
                    :visible.sync="deleteDialog"
                    width="30%">
                  <span>是否删除选中的模板</span>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="deleteDialog = false">取 消</el-button>
                    <el-button type="primary" @click="deleteInstallById(0)">确 定</el-button>
                  </span>
                </el-dialog>
                <!--<el-button style="margin-left: auto; margin-right: 10px" type="primary" size="mini" icon="el-icon-edit" :disabled="ids.length < 1"
                           @click="updateInstallIsStatus(0)"> 批量修改模板状态
                </el-button>
                <el-button style=" margin-right: 10px" type="primary" size="mini" icon="el-icon-delete" :disabled="ids.length < 1"
                @click="deleteInstallById(0)"> 批量删除模板状态</el-button>-->
              </div>
            </div>
          </form>
        </div>
        <!-- 列表开始 -->
        <div class="card mb-5 mb-xl-8" style="margin-left:3px;width:100%;"
             element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
             v-loading="loading">
          <div class="card-body py-3" style="padding-left: 15px;">
            <div class="table-responsive">
              <!-- 表格数据-->
              <el-table
                  :data="rows"
                  style="width: 100%"
                  @selection-change="handleSelectionChange">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="title"
                    label="模板名称"
                    width="120"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="installFiles" label="收费规则" align="center" class="w-100">
                  <template #default="{row}">
                    <!-- 嵌套表格-->
                    <el-table :data="row.installFiles" style="width: 100%;" stripe highlight-current-row border
                              fitborder fit :header-cell-style="rowClass">
                      <!--<el-table-column prop="cityNameStr" label="配送范围" width="797" align="center" show-overflow-tooltip></el-table-column>-->
                      <el-table-column prop="cityNameStr" label="收费范围" width="1260" align="center" type="expand">
                        <template #default="{row}">
                          <div v-html="row.cityNameStr" style="width: 1260px"></div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="money" label="费用(元)" width="120" align="center"></el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column prop="crTime" label="状态" width="120" align="center">
                  <template #default="{row}">
                    <div v-if="row.isStatus == 1" class="text-success">启用</div>
                    <div v-else class="text-danger">停用</div>
                  </template>
                </el-table-column>
                <el-table-column prop="crTime" label="创建时间" width="120" align="center">
                  <template #default="{row}">
                    <div v-html="row.crTime"></div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="120" align="center">
                  <template #default="{row}">
                    <a
                        href="javascript:void(0)"
                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        @click="toggleDropdown(row.id)"
                    >
                      操作
                      <i class="ki-outline ki-down fs-5 ms-1"></i>
                    </a>
                    <div
                        :id="'dropdown-' + row.id"
                        class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                        data-kt-menu="true"
                    >
                      <div class="menu-item px-3" v-if="row.isStatus === 2">
                        <a
                            href="javascript:void(0)"
                            class="menu-link px-3 text-info" @click="updateInstallIsStatus(row.id,1)"
                        >启用</a>
                      </div>
                      <div class="menu-item px-3" v-else>
                        <a href="javascript:void(0)" class="menu-link px-3 text-danger" @click="updateInstallIsStatus(row.id,2)">
                          停用
                        </a>
                      </div>
                      <div class="menu-item px-3">
                        <a href="javascript:void(0)" class="menu-link px-3 text-primary-emphasis" @click="deleteInstallById(row.id)">
                          删除
                        </a>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <Pagination :total-items="totalCount" @page-change="pageChange"></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { getInstallPageApi, updateInstallIsStatusApi, deleteInstallByIdApi } from '@/api/install'
// 页码指定跳转
import Pagination from '@/components/Pagination/index.vue'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      rows: [],
      loading: false,
      params: {
        current: 1,
        size: 10,
        id: "",
        phone: "",
        staffId: "",
        isStatus: "",
        isType: "",
        crTime: "",
        title: "",
      },
      installInfo: {
        isStatus: "",
        title: "",
        ids: []
      },
      ids: [],
      id: "",
      deleteDialog: false, // 删除弹出框
      updateDialog: false, // 修改弹出框
      totalCount: 999,
      first: true,
      activeNames: ['1'],
    }
  },
// 方法
  methods: {
    async getInstallList() {
      this.loading = true;
      let {data} = await getInstallPageApi(this.params)
      this.loading = false;
      this.rows = data.rows;
      this.totalCount = data.total;
    },
    // 修改数据状态
    async updateInstallIsStatus(id, isStatus) {
      if (id) {
        this.installInfo.ids = [id];
      } else {
        this.installInfo.ids = this.ids;
      }
      this.installInfo.isStatus = isStatus;
      await updateInstallIsStatusApi(this.installInfo);
      this.updateDialog = false;
      this.getInstallList()
    },
    // 删除
    async deleteInstallById(id) {
      if (id) {
        this.ids = [id];
      }
      let arr = Array.from(this.ids)
      // get请求需要加上 对象格式 并给上key
      await deleteInstallByIdApi(arr);
      this.deleteDialog = false;
      this.getInstallList();
    },
    // 分页
    pageChange(currentPage, pageSize) {
      this.params.current = currentPage
      this.params.size = pageSize
      this.getInstallList();
    },
    toggleDropdown(index) {
      this.$nextTick(() => {
        const dropdown = document.getElementById(`dropdown-${index}`);
        new KTMenu(dropdown);
      });
    },
    // 复选框 将id 组成集合
    handleSelectionChange(val) {
      let arr = []
      val.forEach(item => {
        arr.push(item.id)
      })
      this.ids = arr;
    },
    // 改变颜色
    rowClass({rowIndex, columnIndex}) {
      rowIndex = rowIndex + 1;
      columnIndex = columnIndex + 1;
      return {background: '#DDD', color: 'black'}
    },
    // 当输入框值为空值 或者改变 会执行语句或刷新 列表
    change(value){
      if (!value){
        this.getInstallList();
      }
    }
  },
// 初始化这个方法
  created() {
    this.getInstallList();
  },
  updated() {
// 当数据更新后，可以在此处手动初始化下拉菜单插件
    this.rows.forEach((_, index) => {
      //this.toggleDropdown(index);
    });
  },

}
</script>
<!--组件样式-->
<style scoped>

</style>