
<template>
    <div style="padding: 0 5% 0  5%;">
        <el-form :model="stepper1Form" :rules="rules" ref="stepper1Form" label-width="100px" label-position="top">
            <el-row>
                <el-col :span="6">
                    <el-form-item prop="title">
                        <span slot="label" style="display:inline-block;">
                            卡密标题
                            <el-tooltip effect="dark" content="将在展示中使用" placement="bottom">
                                <i class='el-icon-question' />
                            </el-tooltip>
                        </span>
                        <el-input  :readonly="!inputCanEdit" v-model="stepper1Form.title"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="2">&nbsp;</el-col>
                <el-col :span="10">
                    <el-form-item prop="type">
                        <span slot="label" style="display:inline-block;">
                            卡密类型
                            <el-tooltip effect="dark" content="选择仅卡密时将不会生成卡号" placement="bottom">
                                <i class='el-icon-question' />
                            </el-tooltip>
                        </span>
                        <el-radio-group v-model="stepper1Form.type" size="small">
                            <el-radio border :label="1">仅卡密</el-radio>
                            <el-radio border :label="2">卡号+卡密</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="4" v-if="stepper1Form.type==2">
                    <el-form-item prop="type">
                        <span slot="label" style="display:inline-block;">
                            卡号前缀
                            <el-tooltip effect="dark" content="可以不填" placement="bottom">
                                <i class='el-icon-question' />
                            </el-tooltip>
                        </span>
                        <el-input v-model="stepper1Form.cardNoPrefix"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row >
              <el-col>
                <el-form-item>
                  <span slot="label" style="display:inline-block;">
                            卡密生成规则
                    <el-tooltip effect="dark" content="增加卡密长度和字符类型可以提高密码安全性。数字和大小写字母不包含0、1、2、9、i、l、o、z、g、q、I、L、O、Z" placement="bottom">
                        <i class='el-icon-question' />
                    </el-tooltip>
                  </span>

                  <el-select  class="mySelectRightNoRadius" v-model="stepper1Form.secretLength" placeholder="请选择卡密长度">
                    <el-option
                        v-for="item in cardSecretLength"
                        :key="'dada'+item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select class="mySelectLeftNoRadius" v-model="stepper1Form.secretContent" placeholder="请选择卡密内容">
                    <el-option
                        v-for="item in cardSecretContent"
                        :key="'dbdb'+item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row >
                <el-col :span="6">
                    <el-form-item prop="num">
                        <span slot="label" style="display:inline-block;">
                            生成数量
                            <!-- <el-tooltip effect="dark" content="自定义积分和现金的比例，如输入100，则表示100积分等于1元" placement="bottom">
                                <i class='el-icon-question' />
                            </el-tooltip> -->
                        </span>
                        <el-input v-model="stepper1Form.num"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="2">&nbsp;</el-col>

                <el-col :span="6">
                    <el-form-item prop="score">
                        <span slot="label" style="display:inline-block;">
                            面值
                            <!-- <el-tooltip effect="dark" content="自定义积分模式下的支付单位，如将积分替换为元宝、点数" placement="bottom">
                                <i class='el-icon-question' />
                            </el-tooltip> -->
                        </span>
                        <el-input v-model="stepper1Form.score"></el-input>
                    </el-form-item>
                </el-col>                
            </el-row>

            <el-row>
                <el-button type="primary" @click="nextStep('stepper1Form')">下一步</el-button>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    components: {
        
    },
    props: ['saveShopVoucherForm'],
    data() {
      const checkTel = (rule, value, callback) => {
        const pattern = /^1[3456789]\d{9}$/;
        setTimeout(() => {
          if (!pattern.test(value)) {
            return callback(new Error('输入手机号格式不正确'))
          }
          return callback()
        }, 1000);
      };
      const checkNum = (rule, value, callback) => {
        if (!/^[1-9]\d*$/.test(value)) {
          callback(new Error("请输入正整数"));
        } else {
          callback();
        }

      };
        return {
            cardSecretLength:[
              {
                value:6,
                label:"6位"
              },
              {
                value:7,
                label:"7位"
              },
              {
                value:8,
                label:"8位"
              },
              {
                value:9,
                label:"9位"
              },
            ],
            cardSecretContent:[{
              value:1,
              label:"全数字"
            },{
              value:2,
              label:"全小写字母"
            },{
              value:3,
              label:"全大写字母"
            },{
              value:4,
              label:"数字+小写字母"
            },{
              value:5,
              label:"数字+大写字母"
            },{
              value:6,
              label:"数字+小写字母+大写字母"
            }],
            stepper1Form: this.saveShopVoucherForm,
            inputCanEdit:true,
            rules: {
                title: [
                    { required: true, message: '请输入卡密名称', trigger: 'blur' },
                    { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
                ],
                num: [
                      { required: true, message: '请输入要生成的卡密数量', trigger: 'blur' },
                      { validator: checkNum, trigger: 'change' }
                  ],

            }
        };
    },
    methods: {
        nextStep(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.updateAddForm()
                    this.$emit("indexAdd")
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        updateAddForm() {
            this.$emit("saveForm-change", this.stepper1Form);
        },
        uploadSuccess(res) {
            console.log(res)
        },
        cancelSuccess(res){
            if(res){

            }
        }

    },
    // 在created钩子中执行的操作
    created() {
        if(this.stepper1Form.title){
            this.inputCanEdit = false
        }
    },
    updated() {
        // 当数据更新后，可以在此处手动初始化下拉菜单插件
        // this.rows.forEach((_, index) => {
        //     this.toggleDropdown(index);
        // });
        console.log(this.stepper1Form)
    }
}
</script>


<style scoped>
/* 修改i下间距 */
::v-deep .el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0;
}
::v-deep .mySelectRightNoRadius .el-input__inner{
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right-width: 0px;
  width:100px
}

::v-deep .mySelectLeftNoRadius .el-input__inner{
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>