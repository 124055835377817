import service from "@/utils/request"

export function getWxShopUserList(data) {
    return service({
        url: '/wxShopUser/getWxShopUserList',
        method: 'get',
        params:data
    })
}

export function deleteShopUser(data){
    return service({
        url:'/wxShopUser/deleteShopUser',
        method:'delete',
        params:data
    })
}

export function addShopUser(data){
    return service({
        url:'/wxShopUser/addShopUser',
        method:'post',
        params:data
    })
}
export function updateShopUser(data){
    return service({
        url:'/wxShopUser/updateShopUser',
        method:'put',
        params:data
    })
}

export function getShopUserDetail(data){
    return service({
        url:'/wxShopUser/getShopUserDetail',
        method:'get',
        params:data
    })
}

export function downloadTemp(){
    return service({
        url:'/wxShopUser/downloadTemp',
        method:'get',
        responseType: 'blob', 
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}

export function exportUserExcel(data){
    return service({
        url:'/wxShopUser/exportUserList',
        method:'get',
        params:data,
        responseType: 'blob',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}

export function batchAddShopUserByExcel(data){
    return service({
        url: '/wxShopUser/batchAddShopUserByExcel',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data
    })
}
export function getWxShopUserScoreList(data){
    return service({
        url:'/wxShopUser/getWxShopUserScoreList',
        method:'get',
        params:data
    })
}