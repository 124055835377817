<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  components: {},
  created() {
    // console.log(window.location.origin);
    // console.log(process.env.VUE_APP_WEB_URL);
    // console.log(this.setIcp());
    this.setIcp()
  },
  methods: {
    ...mapActions("web",["setWebIcp","setTitle"]),
    setIcp() {
      // 遍历webObj数组
      this.webObj.forEach((e) => {
        // 先判断是否是开发环境
        if (process.env.VUE_APP_WEB_URL) {
          // 判断process.env.VUE_APP_WEB_URL是否包含e.webUrl
          if (process.env.VUE_APP_WEB_URL.indexOf(e.webUrl) >= 0) {
            // 调用setWebIcp方法设置webIcp
            this.setWebIcp(e)
            // 返回
            return;
          }
        }
        // 判断window.location.origin是否包含e.webUrl
        if (window.location.origin.indexOf(e.webUrl) >= 0) {
          // 调用setWebIcp方法设置webIcp
          this.setWebIcp(e)
        }
      });
    },
  },
  computed: {
    ...mapState("web", ["webObj"]),
  },
};
</script>
<style>
.el-button--primary{
  background: #009ef7;
}
.el-dialog{
  border-radius: 0.92rem !important;
}
</style>