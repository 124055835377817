<template>
    <div>
        <div  class="w-100">
            <div class="fv-row">
                <!--begin::Label-->
                <label class="d-flex align-items-center fs-5 fw-bold mb-4">
                    <span class="required">请选择一个界面模板</span> <i class="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip" title="选择一个界面作为小程序首页展示使用"></i>
                </label>


                <div class=" pb-5">
                    <div class="row g-5 g-xl-8" style="margin-left: 0px;">
                            <div class="overlay col-xl-3" v-for="(item, index) in templateList" :key="index">
                                <div class="overlay-wrapper" style=" display: flex;justify-content: center;align-items: center;"> 
                                    <img alt="img" class="rounded w-200px h-200px"
                                        :src="item.frontPicUrl">
                                </div>
                                <div class="overlay-layer bg-dark bg-opacity-10 rounded" style="display: flex;flex-direction: column;">
                                    
                                    <a href="javascript:void(0)" class="btn btn-sm btn-primary btn-shadow"
                                        @click="chooseTemplate(item.id)">选择模板</a>
                                    <!-- <a href="#" class="btn btn-sm btn-primary btn-shadow" style="margin-top:5px ;"
                                        @click="view(item.picUrl)">查看大图</a> -->
                                </div>
                                
                                <div class="fs-4" style="text-align: center;margin-top: 20px;">{{ item.title }}</div>

                                <a href="javascript:void(0)" v-if="stepper2Form.voucherTemplateId === item.id" class="btn btn-sm btn-light-primary bbbb"
                                    style="margin:15px auto;display:block;width:100px;"> <span class="svg-icon svg-icon-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                <path
                                                    d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                                                    fill="#000000" fill-rule="nonzero" opacity="0.5"
                                                    transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002)">
                                                </path>

                                            </g>
                                        </svg> </span> 已选择
                                </a>
                            </div>
                            <!-- <div class="overlay me-10" id="editTemplate4">
                            <div class="overlay-wrapper"> <img alt="img" class="rounded w-200px h-350px"
                                    src="https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/shopTemplate/mubanH5_action.png">
                            </div>
                            <div class="overlay-layer bg-dark bg-opacity-10 rounded"> <a href="#"
                                    class="btn btn-sm btn-primary btn-shadow" onclick="chooseTemplate(4)">选择模板</a> </div>
                            <div class="fs-4" style="text-align: center;margin-top: 20px;">H5新模板</div>
                        </div> -->
                    </div>

                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="pageIndex" :page-sizes="[12]" :page-size="12"
                        layout="total, sizes, prev, pager, next, jumper" :total="count">
                    </el-pagination>

                </div>
            </div>
            <div class="d-flex flex-stack pt-10">
                <!--begin::Wrapper-->
                <div class="me-2">
                    <button @click="preStep" class="btn btn-lg btn-light-primary me-3">
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Left-2.svg-->

                        <!--end::Svg Icon-->
                        上一步
                    </button>
                </div>
                <!--end::Wrapper-->
                <!--begin::Wrapper-->
                <div>
                    <button type="button" class="btn btn-lg btn-primary" @click="submitAddVoucher">
                        创建
                        <!--begin::Svg Icon | path: icons/duotone/Navigation/Right-2.svg-->

                        <!--end::Svg Icon-->
                    </button>
                </div>
                <!--end::Wrapper-->
                
            </div>
        </div>
        
    </div>
</template>

<script>
import { getVoucherTemplateList,addVoucher } from '@/api/shopVoucherApi.js'


export default {
    props: ['saveShopVoucherForm'],
    data() {
        return {
            templateList: [],
            pageIndex: 1,
            pageSize: 12,
            count: 0,
            stepper2Form: this.saveShopVoucherForm,
            templatePicUrl: '',
            canChooseTemplate:true
        };
    },
    methods: {
        async getWxShopVoucherTemplateList() {
            let queryData = {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
            const {data} = await getVoucherTemplateList(queryData);
            console.log(data)
            this.templateList = data.rows
            this.count = data.count
        },

        preStep() {
            this.$emit("indexSub")
        },
        nextStep() {
            this.$emit("indexAdd")
        },
        handleCurrentChange(val) {
            console.log(val)
            this.pageIndex = val
            this.getWxShopVoucherTemplateList();
        },
        handleSizeChange() {

        },
        view(picUrl) {
            this.templatePicUrl = picUrl
            this.templatePicUrlVisible = true
        },
        chooseTemplate(id){
            if(!this.canChooseTemplate){
                this.$alert('当前不允许修改模板', '选择模板', {
                    confirmButtonText: '确定',
                    callback: action => {
                        // this.$message({
                        // type: 'info',
                        // message: `action: ${ action }`
                        // });
                    }
                    });
            }else{
                this.stepper2Form.voucherTemplateId = id
            }

        },
        async submitAddVoucher(){
            console.log(this.stepper2Form)
          const res = await addVoucher(this.stepper2Form);
          if(res.code==200){
              this.$message({
                type: 'success',
                message: '新增成功!'
              });
              this.$emit('closePopup')
            }
        }

    },
    created() {
        console.log(this.stepper2Form)
        this.getWxShopVoucherTemplateList()
        if(this.stepper2Form.voucherTemplateId){
            this.canChooseTemplate  =false
        }
    },
    updated() {
    }
}

</script>
<style scoped>
    .swal-z-index {
     z-index: 9999;
}
</style>