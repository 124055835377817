<template>
  <div style="background-color: #fff;">
    <div class="d-flex flex-column flex-root">
      <!--begin::Authentication - Sign-in -->
      <div
          class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed my-component">
        <!--begin::Content-->
        <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <!--begin::Logo-->
          <a class="mb-14">

            <img alt="Logo" src="../../assets/logo-2-dark.png" class="h-65px"/>


          </a>
          <!--end::Logo-->
          <!--begin::Wrapper-->
          <div class="w-lg-700px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <!--begin::Form-->
            <!-- novalidate="novalidate" -->
            <form class="form w-100" id="signUpForm" action="#">
              <input type="hidden" value="1" name="type" id="type"/>
              <!--begin::Heading-->
              <div class="mb-10 text-center">
                <!--begin::Title-->
                <h1 class="text-dark mb-3">欢迎进入系统</h1>
                <!--end::Title-->
                <!--begin::Link-->
                <div class="text-gray-400 fw-bold fs-4">
                  已有账号?
                  <router-link to="/login" class="link-primary fw-bolder">前往登录</router-link>
                </div>
                <!--end::Link-->
              </div>
              <!--end::Heading-->
              <!--begin::Action-->
              <!--end::Action-->
              <!--begin::Separator-->
              <div class="d-flex align-items-center mb-10" dispaly="none">
                <div class="border-bottom border-gray-300 mw-50 w-100"></div>
                <span class="fw-bold text-gray-400 fs-7 mx-2">OR</span>
                <div class="border-bottom border-gray-300 mw-50 w-100"></div>
              </div>

              <!--end::Separator-->
              <div class="row">
                <!-- First Column -->
                <div class="col-lg-4">
                  <!-- 账号 -->
                  <div class="mb-7">
                    <label class="form-label fw-bolder text-dark fs-6">*账号</label>
                    <input type="text" class="form-control form-control-lg form-control-solid" placeholder="请输入登录账号"
                           id="staffUsername" v-model="param.staffUsername" name="staffUsername" autocomplete="off"/>
                  </div>

                  <!-- 密码 -->
                  <div class="mb-7">
                    <label class="form-label fw-bolder text-dark fs-6">*密码</label>
                    <input type="password" class="form-control form-control-lg form-control-solid" placeholder="请输入登录密码"
                           id="staffPassword" v-model="param.staffPassword" name="staffPassword" autocomplete="off"/>
                  </div>

                  <!-- 确认密码 -->
                  <div class="mb-7">
                    <label class="form-label fw-bolder text-dark fs-6">*确认密码</label>
                    <input type="password" class="form-control form-control-lg form-control-solid" placeholder="请输入确认密码"
                           id="staffPasswordSec" v-model="param.configPassword" name="staffPasswordSec" autocomplete="off"/>
                  </div>

                  <!-- 上传营业执照 -->
                  <div class="mb-7">
                    <label class="form-label fw-bolder text-dark fs-6">*上传营业执照</label>
                    <div class="image-input image-input-outline blankPng" data-kt-image-input="true">
                      <div class="image-input-wrapper w-125px h-125px" :style="{ 'background-image': 'url(' + imageSrc + ')' }"></div>
                      <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="上传图像">
                        <i class="bi bi-pencil-fill fs-7"></i>
                        <input id="pic_file_list" ref="avatarFile" @change="uploadPic" type="file" name="avatar" accept=".png, .jpg, .jpeg">
                      </label>
                      <div class="form-text">允许的文件类型: png, jpg, jpeg.</div>
                    </div>
                  </div>
                </div>

                <!-- Second Column -->
                <div class="col-lg-4">

                  <!-- 手机号 -->
                  <div class="mb-7">
                    <label class="form-label fw-bolder text-dark fs-6">*手机号</label>
                    <input type="text" class="form-control form-control-lg form-control-solid" placeholder="请输入手机号"
                           id="tel" name="tel" v-model="param.tel" autocomplete="off"/>
                  </div>
                  <!-- 用户名 -->
                  <div class="mb-7">
                    <label class="form-label fw-bolder text-dark fs-6">*用户名</label>
                    <input type="text" class="form-control form-control-lg form-control-solid" placeholder="姓名"
                           id="staffName" name="staffName" v-model="param.staffName" autocomplete="off"/>
                  </div>

                  <div class="mb-7">
                    <label class="form-label fw-bolder text-dark fs-6">*供应商级别</label>
                    <el-select v-model="param.level" placeholder="请选择">
                      <el-option
                          v-for="item in levelList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </div>

                </div>

                <div class="col-lg-4">
                  <!-- 公司名称 -->
                  <div class="mb-7">
                    <label class="form-label fw-bolder text-dark fs-6">*邮箱</label>
                    <input type="text" class="form-control form-control-lg form-control-solid" placeholder="请输入公司名称，专属商城中显示名称"
                           id="backup" name="backup" v-model="param.email" autocomplete="off"/>
                  </div>
                  <!-- 公司名称 -->
                  <div class="mb-7">
                    <label class="form-label fw-bolder text-dark fs-6">*公司名称</label>
                    <input type="text" class="form-control form-control-lg form-control-solid" placeholder="请输入公司名称，专属商城中显示名称"
                           id="backup" name="backup" v-model="param.backup" autocomplete="off"/>
                  </div>
                </div>
              </div>
              <!--              &lt;!&ndash;begin::Input group&ndash;&gt;
                            <div class="row fv-row mb-7">
                              <label class="form-label fw-bolder text-dark fs-6">*账号</label> <input
                                class="form-control form-control-lg form-control-solid" type="text"
                                placeholder="请输入登录账号" id="staffUsername" v-model="param.staffUsername"
                                name="staffUsername" autocomplete="off"/>
                            </div>
                            &lt;!&ndash;end::Input group&ndash;&gt;

                            &lt;!&ndash;begin::Input group&ndash;&gt;
                            <div class="row fv-row mb-7">
                              <label class="form-label fw-bolder text-dark fs-6">*密码</label> <input
                                class="form-control form-control-lg form-control-solid" type="password"
                                placeholder="请输入登录密码" id="staffPassword" v-model="param.staffPassword"
                                name="staffPassword" autocomplete="off"/>
                            </div>
                            &lt;!&ndash;begin::Input group&ndash;&gt;
                            <div class="row fv-row mb-7">
                              <label class="form-label fw-bolder text-dark fs-6">*确认密码</label>
                              <input class="form-control form-control-lg form-control-solid" type="password"
                                     placeholder="请输入确认密码" id="staffPasswordSec" v-model="param.configPassword"
                                     name="staffPasswordSec" autocomplete="off"/>
                            </div>
                            &lt;!&ndash;end::Input group&ndash;&gt;
                            &lt;!&ndash;begin::Input group&ndash;&gt;
                            <div class="row fv-row mb-7 fv-plugins-icon-container">
                              <label class="form-label fw-bolder text-dark fs-6">*用户名</label> <input
                                class="form-control form-control-lg form-control-solid" type="text" placeholder="姓名"
                                id="staffName" name="staffName" v-model="param.staffName" autocomplete="off"/>
                            </div>
                            &lt;!&ndash;end::Input group&ndash;&gt;
                            &lt;!&ndash;begin::Input group&ndash;&gt;
                            <div class="row fv-row mb-7">
                              <label class="form-label fw-bolder text-dark fs-6">*手机号</label> <input
                                class="form-control form-control-lg form-control-solid" type="text" placeholder="请输入手机号"
                                id="tel" name="tel" v-model="param.tel" autocomplete="off"/>
                            </div>
                            &lt;!&ndash;end::Input group&ndash;&gt;
                            &lt;!&ndash;begin::Input group&ndash;&gt;
                            <div class="row fv-row mb-7">
                              <label class="form-label fw-bolder text-dark fs-6">*公司名称</label>
                              <input class="form-control form-control-lg form-control-solid" type="text"
                                     placeholder="请输入公司名称，专属商城中显示名称" id="backup" name="backup" v-model="param.backup"
                                     autocomplete="off"/>
                            </div>
                            &lt;!&ndash;end::Input group&ndash;&gt;

                            &lt;!&ndash;begin::Input group&ndash;&gt;
                            <div class="row fv-row mb-7">
                              <label class="form-label fw-bolder text-dark fs-6">*上传营业执照</label>
                              <div class="col-lg-8">
                                &lt;!&ndash;begin::Image input&ndash;&gt;
              &lt;!&ndash;                  <div class="image-input image-input-outline blankPng" data-kt-image-input="true"
                                     style="background-image: url(assets/media/avatars/blank.png)">&ndash;&gt;
                                <div class="image-input image-input-outline blankPng" data-kt-image-input="true">
                                  &lt;!&ndash;begin::Preview existing avatar&ndash;&gt;
                                  <div class="image-input-wrapper w-125px h-125px"
                                       :style="{ 'background-image': 'url(' + imageSrc + ')' }"></div>

                                  &lt;!&ndash;end::Preview existing avatar&ndash;&gt;
                                  &lt;!&ndash;begin::Label&ndash;&gt;
                                  <label
                                      class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                      data-kt-image-input-action="change" data-bs-toggle="tooltip" title=""
                                      data-bs-original-title="上传图像"> <i class="bi bi-pencil-fill fs-7"></i>
                                    <input id="pic_file_list" ref="avatarFile"
                                           @change="uploadPic" type="file" name="avatar" accept=".png, .jpg, .jpeg">
                                    <input type="hidden" name="avatar_remove">
                                    <input type="hidden" id="picUrl" name="picUrl" value="">
                                  </label>
                                  &lt;!&ndash;end::Label&ndash;&gt;
                                  &lt;!&ndash;begin::Cancel&ndash;&gt;
                                  <span
                                      class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                      data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title=""
                                      data-bs-original-title="取消"> <i class="bi bi-x fs-2"></i>
                                  </span>
                                  &lt;!&ndash;end::Cancel&ndash;&gt;
                                  &lt;!&ndash;begin::Remove&ndash;&gt;
                                  <span
                                      class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                      data-kt-image-input-action="remove" data-bs-toggle="tooltip" title=""
                                      data-bs-original-title="移除图像"> <i class="bi bi-x fs-2"></i>
                                  </span>
                                  &lt;!&ndash;end::Remove&ndash;&gt;
                                </div>
                                &lt;!&ndash;end::Image input&ndash;&gt;

                                &lt;!&ndash;begin::Hint&ndash;&gt;
                                <div class="form-text">允许的文件类型: png, jpg, jpeg.</div>
                                &lt;!&ndash;end::Hint&ndash;&gt;
                              </div>
                            </div>-->
              <!--end::Input group-->

              <!--begin::Input group
            <div class="fv-row mb-10" display="none">
              <label
                class="form-check form-check-custom form-check-solid form-check-inline" display="none">
                <input class="form-check-input" type="checkbox" name="toc"
                value="1" /> <span
                class="form-check-label fw-bold text-gray-700 fs-6" display="none">I
                  Agree <a href="#" class="ms-1 link-primary" display:"none">Terms and
                    conditions</a>.
              </span>
              </label>
            </div>
            -->
              <!--end::Input group-->
              <!--begin::Actions-->
              <div class="text-center">
                <button type="button" id="kt_sign_up_submit" @click="signUpSubmit"
                        class="btn btn-lg btn-primary">
                  <span class="indicator-label">提交申请</span> <span class="indicator-progress">请等待... <span
                    class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
              </div>
              <!--end::Actions-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Content-->
        <!--begin::Footer-->
        <div class="d-flex flex-center flex-column-auto p-10">
          <!--begin::Links-->
          <div class="d-flex align-items-center fw-bold fs-6">
            <a href="jd.jsp" class="text-muted text-hover-primary px-2">联系我们：</a>
            <a href="jd.jsp" class="text-muted text-hover-primary px-2">0571 8796 3208</a>
            <a href="jd.jsp" class="text-muted text-hover-primary px-2">yigworld@vip.163.com</a>
            <a href="https://beian.miit.gov.cn" class="text-muted text-hover-primary px-2"
               target="_blank">浙ICP备16046962号-4</a>
          </div>
          <!--end::Links-->
        </div>


        <!--end::Footer-->
      </div>
      <!--end::Authentication - Sign-in-->
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {registerApi} from "@/api/userApi";

export default {
  data() {
    return {
      levelList: [{
        value: 1,
        label: "一级供应商"
      }, {
        value: 2,
        label: "二级供应商"
      }],
      param: {
        level: 1,
        backup: "",
        staffUsername: "",
        staffPassword: "",
        configPassword: "",
        email: "",
        staffName: "",
        tel: "",
        picUrl: ""
      },
      imageSrc: ""
    }
  },
  computed: {
    ...mapState("web", ["webObj"]),
  },
  methods: {
    uploadPic() {
      const fileInput = this.$refs.avatarFile;
      if (fileInput.files.length > 0) {
        const selectedFile = fileInput.files[0];
        // 使用FileReader读取上传的文件
        const reader = new FileReader();

        reader.onload = () => {
          // 将文件转换为Base64格式
          this.imageSrc = reader.result;
        };

        reader.readAsDataURL(selectedFile);
      }
    },
    verifForm() {
      if (this.param.staffUsername === "") {
        this.$message({
          message: '请输入登录账号',
          type: 'warning'
        });
        return false;
      }
      if (this.param.staffPassword === "") {
        this.$message({
          message: '请输入登录密码',
          type: 'warning'
        });
        return false;
      }
      if (this.param.configPassword === "") {
        this.$message({
          message: '请输入确认密码',
          type: 'warning'
        });
        return false;
      }
      if (this.param.staffName === "") {
        this.$message({
          message: '请输入姓名',
          type: 'warning'
        });
        return false;
      }
      if (this.param.tel === "") {
        this.$message({
          message: '请输入手机号',
          type: 'warning'
        });
        return false;
      }
      //验证手机号正则
      if (!this.param.tel.match(/^1[34578]\d{9}$/)) {
        this.$message({
          message: '手机号格式不正确',
          type: 'warning'
        });
        return false;
      }

      if (this.param.backup === "") {
        this.$message({
          message: '请输入公司名',
          type: 'warning'
        });
        return false;
      }
      if (this.param.staffPassword !== this.param.configPassword) {
        this.$message({
          message: '密码不一致',
          type: 'warning'
        });
        return false;
      }
      if (!this.$refs.avatarFile.files[0]) {
        this.$message({
          message: '请上传营业执照',
          type: 'warning'
        });
        return false;
      }
      return true;
    },
    async signUpSubmit() {
      if (!this.verifForm()) {
        return;
      }
      let formData = new FormData();
      formData.append("staffUsername", this.param.staffUsername);
      formData.append("staffPassword", this.param.staffPassword);
      formData.append("staffName", this.param.staffName);
      formData.append("tel", this.param.tel);
      formData.append("backup", this.param.backup);
      formData.append("webSite", this.param.webSite);
      formData.append("file", this.$refs.avatarFile.files[0]);
      let res = await registerApi(formData);
      if (res.code === 200 && res.msg === "success") {
        this.$message({
          message: "注册成功！请等待管理员审核!",
          type: "success",
        });
        this.$router.push({path: "/login"})
      }
    },
    /*setWebSite(url) {
      if (url.indexOf("3jzc") !== -1) {
        this.param.webSite = 1;
      } else {
        this.param.webSite = 2;
      }
    }*/
  },
  created() {
    /*if (process.env.VUE_APP_WEB_URL) {
      this.setWebSite(process.env.VUE_APP_WEB_URL)
    } else {
      this.setWebSite(window.location.origin)
    }*/
  }
}
</script>
<style scoped>
.my-component {
  background-image: url('~@/assets/development-hd.png');
}
</style>