import service from "@/utils/request"
// 用户登录
export function userLoginApi(data) {
    return service({
        url: '/user/login',
        method: 'post',
        data
    })
}
// 用户注册
export function userRegisterApi() {
    return service({
        url: '/user/login',
        method: 'post',
    })
}
// 获取用户权限
export function userRoleApi() {
    return service({
        url: '/user/login',
        method: 'post',
    })
}
// 退出登录
export function userLogoutApi() {
    return service({
        url: '/user/logout',
        method: 'post',
        hidden:true
    })
}
//获取分类名
export function getAllCategoryApi() {
    return service({
        url: '/saas-permission-category/getAllCategory',
        method: 'get',
    })
}
// 获取当前用户权限列表
export function getMyPermissionApi() {
    return service({
        url: '/saas-permission/getMyPermission',
        method: 'get',
    })
}
// 获取当前用户角色
export function getLoginRoleApi() {
    return service({
        url: '/saas-role/getLoginRole',
        method: 'get',
    })
}
//获取所有权限
export function getAllPermissionApi() {
    return service({
        url: '/saas-permission/getAllPermission',
        method: 'get',
    })
}

// /saas-role/getAllRole
//获取所有角色
export function getAllRoleApi(data) {
    return service({
        url: '/saas-role/getRoleList',
        method: 'get',
        params:data
    })
}
// /staff/getStaffList
export function getStaffListApi(data) {
    return service({
        url: '/staff/getStaffList',
        method: 'get',
        params:data
    })
}
// /saas-role-permission/getRolePermissionByRoleId
export function getRolePermissionByRoleIdApi(data) {
    return service({
        url: '/saas-role-permission/getRolePermissionByRoleId',
        method: 'get',
        params:data
    })
}
// /saas-role/update
export function updateRoleApi(data) {
    return service({
        url: '/saas-role/update',
        method: 'put',
        data
    })
}
// /saas-role-permission/add
export function addRoleApi(data) {
    return service({
        url: '/saas-role-permission/add',
        method: 'post',
        data
    })
}
// /saas-permission/getStaffPermission
export function getStaffPermissionApi(data) {
    return service({
        url: '/saas-permission/getStaffPermission',
        method: 'get',
        params:data
    })
}
//创建角色
// /saas-role/add
export function addRolesApi(data) {
    return service({
        url: '/saas-role/add',
        method: 'post',
        data
    })
}
// 删除角色
// /saas-role/delete
export function deleteRoleApi(data) {
    return service({
        url: '/saas-role/delete',
        method: 'delete',
        params:data
    })
}
//修改用户权限
// /saas-permission/update
export function updateUserPApi(data) {
    return service({
        url: '/saas-permission/update',
        method: 'post',
        data
    })
}
//通过用户ID获取角色
// /saas-role/getRoleIdByUserId
export function getRoleIdByUserIdApi(params) {
    return service({
        url: '/saas-role/getRoleIdByUserId',
        method: 'get',
        params
    })
}

//根据用户ID设置角色
// /saas-role/add/user/role
export function setUserRoleApi(data) {
    return service({
        url: '/saas-role/add/user/role',
        method: 'post',
        data
    })
}
//获取验证码
// /user/getVerificationCode
export function getVerificationCode(params) {
    return service({
        url: '/user/getVerificationCode',
        method: 'get',
        params
    })
}
//验证码登录
export function loginByCodeApi(data) {
    return service({
        url: '/user/loginByCode',
        method: 'post',
        data
    })
}
//用户注册
export function registerApi(data) {
    return service({
        url: '/user/register',
        method: 'post',
        data
    })
}
//获取当前登录信息
export function getMyInfoApi() {
    return service({
        url: '/staff/getMyInfo',
        method: 'get',
    })
}

// 查询我的二级供应商
export function getSecondaryDistributorsListApi(params) {
    return service({
        url: '/staff/getSecondaryDistributorsList',
        method: 'get',
        params
    })
}

// 修改二级供应商状态
export function updateSecondaryDistributorsStatusApi(params) {
    return service({
        url: '/staff/updateSecondaryDistributorsStatus',
        method: 'put',
        params
    })
}

// 修改二级供应商密码
export function updateSecondaryDistributorsChangePasswordApi(data) {
    return service({
        url: '/staff/updateSecondaryDistributorsChangePassword',
        method: 'post',
        data
    })
}

// 修改账号状态
export function updateStaffStatusApi(data) {
    return service({
        url: '/staff/updateStaffStatus',
        method: 'post',
        data
    })
}

// 二级供应商创建
export function secondaryDistributorsRegisterApi(data) {
    return service({
        url: '/user/secondaryDistributorsRegister',
        method: 'post',
        data
    })
}

// 根据ID查询二级供应商信息
export function getDistributorByIdApi(params) {
    return service({
        url: '/staff/getDistributorById',
        method: 'get',
        params
    })
}

export function updateDistributorByIdApi(data) {
    return service({
        url: '/staff/updateDistributorById',
        method: 'put',
        data
    })
}
//修改密码
// /staff/changePassword
export function changePasswordApi(data) {
    return service({
        url: '/staff/changePassword',
        method: 'post',
        data
    })
}

export function updateMyInfoApi(data) {
    return service({
        url: '/staff/updateMyInfo',
        method: 'post',
        data
    })
}
