//时间工具类
/**
 * 获取当前时间近三十天的年月
 * @returns {*[]}
 */
export function getMonthDay() {
    // 获取当前时间
    let currentDate = new Date();

// 初始化数组以存储结果
    let lastMonthDates = [];

// 循环获取前一个月的日期
    while (currentDate.getTime() >= (new Date().getTime() - 30 * 24 * 60 * 60 * 1000)) {
        let previousDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
        lastMonthDates.push(`${previousDay.getFullYear()}-${previousDay.getMonth() + 1}-${previousDay.getDate()}`);
        // 更新当前时间为上一天
        currentDate = previousDay;
    }
    return lastMonthDates;
}
