
import service from "@/utils/request"
export function getShopGoodsList(data) {
    return service({
        url: '/wxShopGoods/getShopGoodsList',
        method: 'get',
        params:data
    })
}

export function getShopGoodsCount(data) {
    return service({
        url: '/wxShopGoods/getShopGoodsCount',
        method: 'get',
        params:data
    })
}

export function getShopGoodGroupList(data){
    return service({
        url: '/wxShopGoods/getShopGoodGroupList',
        method: 'get',
        params:data
    })
}

export function calculateShopSalePrice(data){
    return service({
        url: '/wxShopGoods/calculateShopSalePrice',
        method: 'post',
        data
    })
}

export function updateAddPrice(data){
    return service({
        url: '/wxShopGoods/updateAddPrice',
        method: 'post',
        data
    })
}

export function deleteById(data){
    return service({
        url: '/wxShopGoods/deleteById',
        method: 'delete',
        params:data
    })
}
export function batchDeleteByIds(data){
    return service({
        url: '/wxShopGoods/batchDeleteByIds',
        method: 'post',
        data:data
    })
}
