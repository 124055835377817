<!-- 组件模板内容 -->
<template>
  <div>
    <div id="myTabContent" class="tab-content">
      <div
          v-loading="loading"
          class="card mb-5 mb-xl-8"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          element-loading-spinner="el-icon-loading"
          element-loading-text="拼命加载中"
          style="margin-left: 3px; width: 100%"
      >
        <div class="card-body py-3">
          <div class="table-responsive">
            <table
                id="kt_ecommerce_products_table"
                class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
            >
              <thead>
              <tr
                  class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
              >
                <th
                    aria-label=""
                    class="w-10px pe-2 sorting_disabled"
                    colspan="1"
                    rowspan="1"
                    style="width: 29.8906px"
                >
                  <div
                      class="form-check form-check-sm form-check-custom form-check-solid me-3"
                  >
                    <input
                        v-model="checkboxInput"
                        class="form-check-input"
                        data-kt-check="true"
                        data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                        type="checkbox"
                        value="1"
                        @change="checkboxInputEvent"
                    />
                  </div>
                </th>
                <th
                    aria-label="order_batch"
                    class="text-center min-w-50px"
                    colspan="1"
                    rowspan="1"
                    style="width: 212.141px"
                    tabindex="0"
                >
                  订单批次
                  <hr style="margin: 2px 0 !important;"/>
                  订单编号
                </th>
                <th
                    aria-controls="procurement_account"
                    class=" text-center min-w-50px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  订单类型
                </th>
                <th
                    v-if="!role.includes('secondTierSuppliers')"
                    aria-label="order_source"
                    class="text-center min-w-50px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  订单来源
                  <hr style="margin: 2px 0 !important;"/>
                  商品来源
                </th>
                <th
                    aria-label="product_name"
                    class="text-center min-w-150px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  商品名称
                </th>
                <th
                    aria-label="market_price"
                    class="text-center min-w-40px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  市场价
                </th>
                <th
                    aria-label="distribution_price"
                    class="text-center min-w-40px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  分销价
                </th>
                <th
                    v-if="!role.includes('secondTierSuppliers')"
                    aria-label="sales_price"
                    class="text-center min-w-40px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  销售价
                </th>
                <th
                    aria-label="quantity"
                    class="text-center min-w-40px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  数量
                </th>
                <th
                    v-if="!role.includes('secondTierSuppliers')"
                    aria-label="profit"
                    class="text-center min-w-40px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  利润
                </th>
                <th
                    v-if="!role.includes('secondTierSuppliers')"
                    aria-label="customer_payment"
                    class="text-center min-w-60px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  客户支付<br/>(含运费)
                  <hr style="margin: 2px 0 !important;">
                  运费
                </th>
                <th
                    v-if="!role.includes('secondTierSuppliers')"
                    aria-label="payable_for_goods"
                    class="text-center min-w-60px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  应付货款
                </th>
                <th
                    aria-label="order_status"
                    class="text-center min-w-60px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  订单状态
                  <hr style="margin: 2px 0 !important;">
                  收件人信息
                </th>
                <th
                    aria-label="order_time"
                    class="text-center min-w-60px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  下单时间
                </th>
                <th
                    aria-label="express_delivery_company"
                    class="text-center min-w-60px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  快递公司
                  <hr style="margin: 2px 0 !important;">
                  快递单号
                </th>
                <th
                    aria-label="other_courier_tracking_numbers"
                    class="text-center min-w-60px"
                    colspan="1"
                    rowspan="1"
                    style="width: 106.344px"
                    tabindex="0"
                >
                  子母单号
                </th>
                <th
                    aria-label="operation"
                    class="text-center sorting_disabled"
                    colspan="1"
                    rowspan="1"
                    style="width: 120px"
                >
                  操作
                </th>
              </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
              <tr
                  v-for="(item, index) in rows"
                  :key="index"
                  :class="{ old: index % 2 == 0, even: index % 2 != 0 }"
              >
                <!-- 复选按钮 -->
                <td>
                  <div
                      v-if="
                        item.orderTypeName == '待付款' && item.dataFrom == 2
                      "
                      class="form-check form-check-sm form-check-custom form-check-solid"
                  >
                    <input
                        :checked="idArr.includes(item.orderNo)"
                        class="form-check-input"
                        type="checkbox"
                        value="1"
                        @click="addIdArr(item.orderNo)"
                    />
                  </div>
                </td>
                <td class="text-center pe-0">
                  <!-- 订单批次 -->
                  <span class="fw-light" style="font-size: 8px;cursor:pointer"
                        @click="copyText(item.cartNo)"
                  >{{ item.cartNo }}
                    </span>
                  <hr style="margin: 0 0 !important;"/>
                  <!-- 订单编号 -->
                  <span class="fw-light" style="font-size: 8px;cursor:pointer"
                        @click="copyText(item.orderNo)">
                      {{ item.orderNo }}</span>
                </td>
                <!-- 订单类型 -->
                <td>
                  <div class="text-center pe-0">
                    <span class="text-center">{{ item.orderFrom.replace("卷", "券") }} </span>
                  </div>
                </td>
                <!-- 订单来源 -->
                <td v-if="!role.includes('secondTierSuppliers')" class="text-center pe-0">
                  <span class="">{{ item.titleFrom }}</span>
                  <hr style="margin: 0 0 !important;"/>
                  <!-- 商品来源 -->
                  <span class="">{{ item.goodsTypeName }}</span>
                </td>
                <!-- 商品名称 -->
                <td class="text-center pe-0">
                  <span :title="item.title"
                        class=""
                        style="overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        line-height: 1.2;">{{ item.title }}</span>
                </td>
                <!-- 市场价 -->
                <td class="text-center pe-0">
                  <span class="fw-bold">{{ item.price | format }}</span>
                </td>
                <!--分销价-->
                <td class="text-center pe-0">
                  <span class="fw-bold">{{ item.supplyPrice | format }}</span>
                </td>
                <!--销售价-->
                <td v-if="!role.includes('secondTierSuppliers') && (item.orderFrom=='数字卡卷'||item.orderFrom=='组合礼包')"
                    class="text-center pe-0">
                  <span class="fw-bold">-</span>
                </td>
                <td v-else-if="!role.includes('secondTierSuppliers')" class="text-center pe-0">
                  <span class="fw-bold">{{ item.disPriceStr | format }}</span>
                </td>
                <!--数量-->
                <td class="text-center pe-0">
                  <span>{{ item.num }}</span>
                </td>
                <!--利润-->
                <td v-if="!role.includes('secondTierSuppliers')  && (item.orderFrom=='数字卡卷'||item.orderFrom=='组合礼包')"
                    class="text-center pe-0">
                  <span class="fw-bold">-</span>
                </td>
                <td v-else-if="!role.includes('secondTierSuppliers')" class="text-center pe-0">
                  <span class="fw-bold">{{ item.totalLirun | format }}</span>
                </td>
                <!--客户支付(含运费)-->
                <td v-if="!role.includes('secondTierSuppliers')  && (item.orderFrom=='数字卡卷'||item.orderFrom=='组合礼包')"
                    class="text-center pe-0">
                  <span class="fw-bold">-</span>
                  <hr style="margin: 0 0 !important;">
                  <!--运费-->
                  <span class="">{{ item.yfPrice | format }}</span>
                </td>
                <td v-else-if="!role.includes('secondTierSuppliers')" class="text-center pe-0">
                  <span class="fw-bold">{{ item.totalMoneyDisPriceStr | format }}</span>
                  <hr style="margin: 0 0 !important;">
                  <!--运费-->
                  <span class="">{{ item.yfPrice | format }}</span>
                </td>
                <!--应付货款-->
                <td v-if="!role.includes('secondTierSuppliers')" class="text-center pe-0">
                    <span class="fw-bold">{{
                        item.totalMoneySupplyStr | format
                      }}</span>
                </td>
                <!--订单状态-->
                <td class="text-center pe-0">
                  <div style="display: flex; justify-content: center;">
                    <span v-if="item.orderTypeName == '待付款'" class="fw-bold">
                      <span
                          class="badge badge-danger fs-7 fw-bold"
                          style="display: block; text-align: center; width: 80px"
                      >{{ item.orderTypeName }}</span
                      >
                    </span>

                    <span v-if="item.orderTypeName == '待发货'" class="fw-bold">
                      <span
                          class="badge badge-warning fs-7 fw-bold"
                          style="display: block; text-align: center; width: 80px"
                      >{{ item.orderTypeName }}</span
                      >
                    </span>
                    <span v-if="item.orderTypeName == '已发货'" class="fw-bold">
                      <span
                          class="badge badge-primary fs-7 fw-bold"
                          style="display: block; text-align: center; width: 80px"
                      >{{ item.orderTypeName }}</span
                      >
                    </span>
                    <span v-if="item.orderTypeName == '已完结'" class="fw-bold">
                      <span
                          class="badge badge-success fs-7 fw-bold"
                          style="display: block; text-align: center; width: 80px"
                      >{{ item.orderTypeName }}</span
                      >
                    </span>
                    <span v-if="item.orderTypeName == '已取消'" class="fw-bold">
                      <span
                          class="badge badge-secondary fs-7 fw-bold"
                          style="display: block; text-align: center; width: 80px"
                      >{{ item.orderTypeName }}</span
                      >
                    </span>
                    <span v-if="item.orderTypeName == '售后中'" class="fw-bold">
                      <span
                          class="badge badge-dark fs-7 fw-bold"
                          style="display: block; text-align: center; width: 80px"
                      >{{ item.orderTypeName }}</span
                      >
                    </span>
                  </div>
                  <hr style="margin: 2px 0 !important;">
                  <el-popover
                      placement="bottom"
                      trigger="click"
                      width="40%"
                  >
                    <el-descriptions :column="3" border class="margin-top" size="small" title="收件人信息">
                      <el-descriptions-item>
                        <template slot="label">
                          <i class="el-icon-user"></i>
                          收件人
                        </template>
                        {{ item.name }}
                      </el-descriptions-item>
                      <el-descriptions-item>
                        <template slot="label">
                          <i class="el-icon-mobile-phone"></i>
                          手机号
                        </template>
                        {{ item.tel }}
                      </el-descriptions-item>
                      <el-descriptions-item>
                        <template slot="label">
                          <i class="el-icon-location-outline"></i>
                          地址
                        </template>
                        {{ item.address }}
                      </el-descriptions-item>
                    </el-descriptions>

                    <el-button slot="reference" round size="mini">查看信息</el-button>
                  </el-popover>
                </td>
                <!--下单时间-->
                <td class="text-center pe-0" style="font-size: 10px">
                  <span>{{ item.crTime }}</span>
                </td>
                <!--快递公司-->
                <td v-if="item.expressName == null && item.expressNo == null" class="text-center pe-0"
                    style="font-size: 10px">
                  <span></span>
                </td>
                <td class="text-center pe-0" style="font-size: 10px" v-else>
                  <span>{{ item.expressName }}</span>
                  <hr style="margin: 2px 0 !important;">
                  <!--快递单号-->
                  <span>{{ item.expressNo }}</span>
                </td>

                <!--子母单号-->
                <td class="text-center pe-0" style="font-size: 10px">
                  <span>{{ item.otherExpressNo }}</span>
                </td>
                <!-- 操作 -->
                <td class="text-center">
                  <a
                      v-show="
                        item.orderTypeName == '待付款' ||
                        (item.orderTypeName == '待发货' && item.goodsTypeName != '京东') ||
                        (item.orderTypeName == '已完结'&& item.isFpState==2)
                      "
                      class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary"
                      data-kt-menu-placement="bottom-end"
                      data-kt-menu-trigger="click"
                      href="javascript:void(0)"
                      @click="toggleDropdown(index)"
                  >
                    操作
                    <i class="ki-outline ki-down fs-5 ms-1"></i>
                  </a>
                  <div
                      :id="'dropdown-' + index"
                      class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                      data-kt-menu="true"
                  >
                    <!-- v-if="item.orderTypeName == '待发货' && item.dataForm == '1'" -->
                    <div
                        v-if="item.orderTypeName == '待付款'"
                        class="menu-item px-3"
                    >
                      <a
                          class="menu-link px-3"
                          href="javascript:void(0)"
                          @click="orderPayment(item.orderNo)"
                      >付款</a
                      >
                    </div>
                    <div
                        v-if="item.orderTypeName == '待发货' && item.goodsTypeName == '自有'"
                        class="menu-item px-3"
                    >
                      <a
                          class="menu-link px-3"
                          href="javascript:void(0)"
                          @click="updateOrderDelivery(item.orderNo)"
                      >发货</a
                      >
                    </div>
                    <div
                        v-if="item.orderTypeName == '已完结' && item.isFpState==2"
                        class="menu-item px-3"
                    >
                      <a
                          class="menu-link px-3"
                          href="javascript:void(0)"
                          @click="updateOrderInvoiceState(item.orderNo)"
                      >开票</a
                      >
                    </div>
                    <div
                        v-if="
                          item.orderTypeName == '待付款' ||
                          (item.orderTypeName == '待发货' && item.goodsTypeName != '京东')
                        "
                        class="menu-item px-3"
                    >
                      <a
                          class="menu-link px-3"
                          href="javascript:void(0)"
                          @click="updateOrderCancel(item.orderNo)"
                      >取消</a
                      >
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <Pagination
                v-if="hidden"
                ref="Pagination"
                :total-items="totalCount"
                @page-change="pageChange"
            ></Pagination>
          </div>
        </div>
      </div>
    </div>
    <ModalDialog
        ref="updateOrderDeliveryModalDialog"
        title="确认发货"
        width="30%"
    >
      <form
          id="sendGoodsForm"
          action="#"
          class="form w-100"
          novalidate="novalidate"
          role="form"
      >
        <div class="row mb-2">
          <label class="col-lg-2 fw-bold text-muted"> 标题:</label>
          <div class="col-lg-8">
            <span id="orderTitle" class="fw-bolder text-gray-800">{{
                updateOrderDeliveryObj.title
              }}</span>
          </div>
          <div class="separator separator-dashed my-2"></div>
        </div>
        <div class="row mb-2">
          <label class="col-lg-2 fw-bold text-muted"> 市场价:</label>
          <div class="col-lg-4">
            <span id="orderPrice" class="fw-bolder text-gray-800">{{
                updateOrderDeliveryObj.price
              }}</span>
          </div>
          <label class="col-lg-2 fw-bold text-muted"> 分销价:</label>
          <div class="col-lg-4">
            <span id="orderSupplyPrice" class="fw-bolder text-gray-800">{{
                updateOrderDeliveryObj.supplyPrice
              }}</span>
          </div>
          <div class="separator separator-dashed my-2"></div>
        </div>

        <div class="row mb-2">
          <label class="col-lg-2 fw-bold text-muted"> 购买数量:</label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-4">
            <span id="saleOrderNum" class="fw-bolder text-gray-800">{{
                updateOrderDeliveryObj.num
              }}</span>
          </div>
          <!--end::Col-->
          <div class="separator separator-dashed my-2"></div>
        </div>

        <div class="row mb-2">
          <!--begin::Label-->
          <label class="col-lg-2 fw-bold text-muted">收件人:</label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-4">
            <span id="orderSendPeople" class="fw-bolder text-gray-800">{{
                updateOrderDeliveryObj.name
              }}</span>
          </div>
          <!--end::Col-->

          <!--begin::Label-->
          <label class="col-lg-2 fw-bold text-muted"> 收件电话:</label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-4">
            <span id="orderSendTel" class="fw-bolder text-gray-800">{{
                updateOrderDeliveryObj.tel
              }}</span>
          </div>
          <!--end::Col-->
          <div class="separator separator-dashed my-2"></div>
        </div>
        <div class="row mb-2">
          <!--begin::Label-->
          <label class="col-lg-2 fw-bold text-muted"> 地址:</label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8">
            <span id="orderAddress" class="fw-bolder text-gray-800">{{
                updateOrderDeliveryObj.address
              }}</span>
          </div>
          <!--end::Col-->
          <div class="separator separator-dashed my-2"></div>
        </div>
        <div class="row mb-2">
          <!--begin::Label-->
          <label class="col-lg-3 fw-bold text-muted"
          ><span style="color: red">*</span>快递公司:</label
          >
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-9">
            <select
                id="orderExpressName"
                v-model="submitOrder.expressName"
                class="form-control form-control-solid fv-row"
                data-placeholder="请输入快递公司名称"
                name="expressName"
            >
              <option value="">请选择</option>
              <option
                  v-for="(item, index) in expressList"
                  :key="index"
                  :value="item.expressName"
              >
                {{ item.expressName }}
              </option>
            </select>
            <div class="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <!--end::Col-->
          <div class="separator separator-dashed my-2"></div>
        </div>
        <div class="row mb-2">
          <!--begin::Label-->
          <label class="col-lg-3 fw-bold text-muted"
          ><span style="color: red">*</span>快递单号:</label
          >
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-9">
            <input
                id="orderExpressNo"
                v-model="submitOrder.expressNo"
                class="form-control form-control-solid fv-row"
                name="expressNo"
                placeholder="请输入快递单号"
            />
            <div class="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <!--end::Col-->
          <div class="separator separator-dashed my-2"></div>
        </div>
        <div class="row mb-2">
          <!--begin::Label-->
          <label class="col-lg-3 fw-bold text-muted">子母单号:</label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-9">
            <input
                id="otherExpressNo"
                v-model="submitOrder.otherExpressNo"
                class="form-control form-control-solid fv-row"
                name="otherExpressNo"
                placeholder="请输入子母单号(多个单号用  #分割)"
            />
          </div>
          <!--end::Col-->
          <div class="separator separator-dashed my-2"></div>
        </div>
        <el-button type="primary" @click="saveOrderDelivery">提交</el-button>
        <el-button @click="$refs.updateOrderDeliveryModalDialog.close()"
        >取消
        </el-button
        >
      </form>
    </ModalDialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/index.vue";
import {
  batchOrderPaymentApi,
  checkCartOrderApi,
  getExpressCodeListApi,
  getOrderInfoApi,
  getOrderSjListApi,
  updateOrderCancelApi,
  updateOrderDeliveryApi,
  updateOrderInvoiceStateApi,
} from "@/api/orderApi";
import {formatMoney} from "@/utils/formatMoney";
import {mapState} from "vuex";
import {Message} from "element-ui";

export default {
  name: "",
  components: {Pagination},
  // 组件的props定义,用于子组件接收父组件传值
  props: {},
  computed: {
    ...mapState("user", ["role"]),
    sumMoney() {
      let sum = 0;
      this.tempArr.forEach((e) => {
        if (this.idArr.includes(e.orderNo)) {
          sum += e.totalMoneySupplyStr * 100;
        }
      });

      return sum / 100;
    },
  },
  // 组件的data属性
  data() {
    return {
      tempArr: [],
      idArr: [],
      checkboxInput: false,
      submitOrder: {
        id: "",
        expressNo: "",
        expressName: "",
        otherExpressNo: "",
      },
      updateOrderDeliveryObj: {
        id: "",
        title: "",
        price: "",
        supplyPrice: "",
        num: "",
        name: "",
        tel: "",
        address: "",
      },
      hidden: true,
      loading: false,
      page: 1,
      pageSize: 10,
      rows: [],
      expressList: [],
      visible: false,
      params: {
        pageIndex: 1,
        pageSize: 10,
        // 订单编号
        orderNo: "",
        // 订单状态
        orderStatus: "",
        // 开票状态
        invoiceState: "",
        // 订单日期
        startTime: "",
        endTime: "",
        // 下单人手机号
        telNum: this.userTel,
        // 订单类型
        orderType: "",
        // 商品来源
        fromType: "",
        isFp: "",
        // 商城id 在商城用
        wxShopId: this.currentWxShopId == null ? "" : this.currentWxShopId,
      },
      totalCount: 99999,
    };
  },
  // 组件的方法
  methods: {
    updateOrderInvoiceState(orderNo) {
      this.$prompt('请输入税局提供的发票链接地址', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/,
        inputErrorMessage: '链接地址格式不正确'
      }).then(async ({value}) => {
        let {data} = await updateOrderInvoiceStateApi(JSON.stringify({
          orderNo,
          fpUrl: value
        }))
        this.$message({
          type: 'success',
          message: "开票成功！"
        });
        this.getOrderSjList();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
      console.log(orderNo);
    },
    copyText(text) {
      const textToCopy = text;

      // 创建一个临时的 textarea 元素
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;

      // 将 textarea 元素添加到 DOM 中
      document.body.appendChild(textarea);

      // 选中 textarea 中的文本
      textarea.select();

      // 执行复制操作
      document.execCommand('copy');

      // 移除 textarea 元素
      document.body.removeChild(textarea);
      Message.success('复制成功')
    },
    async orderPayment(orderNo, totalMoneySupplyStr) {
      let {data: res} = await checkCartOrderApi({
        orderNos: [orderNo],
      });
      this.$confirm(`确定支付 ${this.formatM(res.totalMoney)} 吗?(包含关联订单)`, "支付", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            let {data} = await batchOrderPaymentApi({
              orderNos: res.orderNoList,
            });
            this.$swal.fire({
              icon: "success",
              title: "支付成功",
              toast: true,
              position: "center",
              showConfirmButton: false,
              timer: 2000,
            });
            this.$emit("reload");
            this.getOrderSjList();
          })
          .catch(() => {
          });
    },
    checkboxInputEvent() {
      if (this.checkboxInput) {
        this.rows.forEach((e) => {
          if (e.orderTypeName == "待付款" && e.dataFrom == 2) {
            if (!this.idArr.includes(e.orderNo)) {
              this.idArr.push(e.orderNo);
            }
          }
        });
      } else {
        this.rows.forEach((e) => {
          if (e.orderTypeName == "待付款" && e.dataFrom == 2) {
            let index = this.idArr.indexOf(e.orderNo);
            if (index !== -1) {
              this.idArr.splice(index, 1);
            }
          }
        });
      }
      this.$emit("setIdArr", this.idArr);
    },
    addIdArr(id) {
      if (this.idArr.includes(id)) {
        let index = this.idArr.indexOf(id);
        if (index !== -1) {
          this.idArr.splice(index, 1);
        }
      } else {
        this.idArr.push(id);
      }
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.orderNo)) {
          a = false;
        }
      });
      this.$emit("setIdArr", this.idArr);
      this.$emit("setMoney", this.sumMoney);
      this.checkboxInput = a;
    },
    async saveOrderDelivery() {
      this.$confirm("是否发货?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            await updateOrderDeliveryApi(JSON.stringify(this.submitOrder));
            this.$message({
              type: "success",
              message: "发货成功!",
            });
            this.$refs.updateOrderDeliveryModalDialog.close();
            this.getOrderSjList();
          })
          .catch(() => {
          });
    },
    async updateOrderDelivery(id) {
      let {data} = await getOrderInfoApi({id});
      let {data: res} = await getExpressCodeListApi();
      this.expressList = res.rows;
      this.$refs.updateOrderDeliveryModalDialog.open();
      this.updateOrderDeliveryObj = data;
      this.submitOrder.id = id;
      this.updateOrderDeliveryObj.id = id;
    },
    async updateOrderCancel(id) {
      this.$confirm("是否取消当前订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            await updateOrderCancelApi({id});
            this.$message({
              type: "success",
              message: "取消成功!",
            });
            this.$emit("reload");
            this.getOrderSjList();
          })
          .catch(() => {
          });
    },
    toggleDropdown(index) {
      this.$nextTick(() => {
        const dropdown = document.getElementById(`dropdown-${index}`);
        new KTMenu(dropdown);
      });
    },
    async getOrderSjList() {
      this.loading = true;
      let {data} = await getOrderSjListApi(this.params);
      this.rows = data.data;
      this.totalCount = data.recordsTotal;
      this.loading = false;

      let a = true;
      let b = 0;
      this.rows.forEach((e) => {
        if (e.orderTypeName == "待付款" && e.dataFrom == 2) {
          b++;
          if (!this.idArr.includes(e.id)) {
            a = false;
          }
        }
      });
      if (b == 0) {
        a = false;
      }
      this.tempArr.push(...this.rows);

      this.tempArr = this.tempArr.filter((item, index, self) => {
        return (
            index ===
            self.findIndex(
                (t) => t.orderNo === item.orderNo // 根据对象的id属性来判断是否重复
            )
        );
      });
      this.checkboxInput = a;
      // this.params.orderDateRangee = data;
    },
    pageChange(currentPage, pageSize) {
      this.params.pageIndex = currentPage;
      this.params.pageSize = pageSize;
      this.getOrderSjList();
    },
    findOrder(params) {
      if (params.orderStatus == 7) {
        params.isFp = "1"
      } else {
        params.isFp = ""
      }
      this.params = params;
      this.idArr = [];
      this.$emit("setIdArr", this.idArr);
      if (params.pageIndex != 1) {
        this.$refs.Pagination.goToPage(1);
      } else {
        this.getOrderSjList();
      }
    },
    formatM(num) {
      if (!isNaN(num)) {
        return formatMoney(num);
      }
      if (num == null) {
        return "-";
      }
      return num;
    },
  },
  filters: {
    format(num) {
      if (!isNaN(num)) {
        return formatMoney(num);
      }
      if (num == null) {
        return "-";
      }
      return num;
    },
  },
  // 在created钩子中执行的操作
  created() {
    //this.getOrderSjList();
  },
  updated() {
    // 当数据更新后，可以在此处手动初始化下拉菜单插件
    this.rows.forEach((_, index) => {
      this.toggleDropdown(index);
    });
  },
};
</script>

<!-- 组件的样式 -->
<style scoped>
</style>
