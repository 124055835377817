<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">

      <el-form-item label="发票抬头" prop="fpName">
        <el-input v-model="form.fpName" placeholder="发票抬头"></el-input>
      </el-form-item>
      <el-form-item label="发票税号" prop="fpNo">
        <el-input v-model="form.fpNo" placeholder="发票税号"></el-input>
      </el-form-item>
      <el-form-item label="注册地址" prop="fpAdd">
        <el-input v-model="form.fpAdd" placeholder="注册地址"></el-input>
      </el-form-item>
      <el-form-item label="注册电话" prop="fpTel">
        <el-input v-model="form.fpTel" placeholder="注册电话"></el-input>
      </el-form-item>
      <el-form-item label="开户银行" prop="fpYh">
        <el-input v-model="form.fpYh" placeholder="开户银行"></el-input>
      </el-form-item>
      <el-form-item label="开户账号" prop="fpYhzh">
        <el-input v-model="form.fpYhzh" placeholder="开户账号"></el-input>
      </el-form-item>
      <el-form-item label="收票人邮箱" prop="sjEmail">
        <el-input v-model="form.sjEmail" placeholder="收票人邮箱"></el-input>
      </el-form-item>
      <el-form-item label="是否专票" prop="special">
        <el-radio v-model="form.special" :label="true">是</el-radio>
        <el-radio v-model="form.special" :label="false">否</el-radio>
      </el-form-item>
      <slot></slot>

    </el-form>
  </div>
</template>

<script>
import {getInvoiceApi, updateInvoiceApi} from "@/api/invoiceApi";

export default {
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    InvoiceId: {
      type: Number,
    },
  },
  data() {
    return {
      id: "",
      invoiceOjb: {

      },
      form: {
        // 发票抬头
        id: "",
        fpName: "",
        fpNo: "",
        fpAdd: "",
        fpTel: "",
        fpYh: "",
        fpYhzh: "",
        sjEmail: "",
        special: false
      },
      rules: {
        fpName: [
          {required: true, message: '请输入发票抬头', trigger: 'blur'}
        ],
        fpNo: [
          {required: true, message: '请输入发票税号', trigger: 'blur'}
        ],
        fpAdd: [
          {required: true, message: '请输入注册地址', trigger: 'blur'}
        ],
        fpTel: [
          {required: true, message: '请输入注册电话', trigger: 'blur'}
        ],
        fpYh: [
          {required: true, message: '请输入开户银行', trigger: 'blur'}
        ],
        fpYhzh: [
          {required: true, message: '请输入开户账号', trigger: 'blur'}
        ],
        sjEmail: [
          {required: true, message: '请输入收票人邮箱', trigger: 'blur'}
        ],
        special: [
          {required: true, message: '请至少选择一个发票性质', trigger: 'change' }
        ],
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //  验证完后
          this.updateInvoiceObj()
        }
      })
    },
    async getInvoiceObj(id) {
      this.id=id
      let {data: res} = await getInvoiceApi({
        id: this.id
      });
      this.invoiceOjb = res.info;
      this.form.fpName = this.invoiceOjb.fpName;
      this.form.fpNo = this.invoiceOjb.fpNo;
      this.form.fpAdd = this.invoiceOjb.fpAdd;
      this.form.fpTel = this.invoiceOjb.fpTel;
      this.form.fpYh = this.invoiceOjb.fpYh;
      this.form.fpYhzh = this.invoiceOjb.fpYhzh;
      this.form.sjEmail = this.invoiceOjb.sjEmail;
      this.form.id = this.invoiceOjb.id;
      this.form.special = this.invoiceOjb.special;
      this.id = 0;
    },

    async updateInvoiceObj() {
      await updateInvoiceApi(this.form);
      this.$swal.fire({
        icon: "success",
        title: "修改成功",
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
      this.$emit("refresh-list")
    },

    clean() {
      this.form = {
        // 发票抬头
        id: "",
        fpName: "",
        fpNo: "",
        fpAdd: "",
        fpTel: "",
        fpYh: "",
        fpYhzh: "",
        sjEmail: "",
      }
    }
  },
  watch: {
  },
  created() {
  }
}

</script>
<style scoped>

</style>