<template>
  <div>
    <!--顶部标题-->
    <div>
      <div
        class="toolbar"
        style="
          background-color: #fff;
          padding: 0.5rem;
          border-radius: 15px;
          margin: 10px auto;
          max-width: 100%;
        "
      >
        <div
          id="kt_toolbar_container1"
          class="container-fluid d-flex flex-stack"
          style="padding: 0 30px"
        >
          <div
            class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container1'}"
          >
            <h1
              :style="`font-size: 1.17rem !important`"
              class="d-flex align-items-center text-dark fw-bolder fs-3 my-1"
            >
              发票抬头
            </h1>
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">Invoice Management</li>
            </ul>
          </div>
          <div style="position: absolute; right: 0; padding: 0px 30px">
            <el-button
              round
              size="mini"
              style="margin-right: 20px"
              type="primary"
              @click="$refs.InvoiceCreate.open()"
            >
              添加发票抬头
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!--  下方卡片  -->
    <div>
      <!--  如果数据是空的就显示空的页面 -->
      <div v-if="invoiceList.length == 0" class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <!--begin::Heading-->
          <div class="card-px text-center pt-15 pb-15">
            <!--begin::Title-->
            <h2 class="fs-2x fw-bold mb-0">为你的账号来创建一个发票抬头</h2>
            <!--end::Title-->
            <!--begin::Description-->
            <p class="text-gray-400 fs-4 fw-semibold py-7">
              点击下方按钮 <br />来创建你的第一个发票抬头
            </p>
            <!--end::Description-->
            <!--begin::Action-->
            <a
              class="btn btn-primary er fs-6 px-8 py-4"
              data-bs-target="#kt_modal_create_project"
              data-bs-toggle="modal"
              href="javascript:void(0)"
              @click="$refs.InvoiceCreate.open()"
              >添加发票抬头</a
            >
            <!--end::Action-->
          </div>
          <!--end::Heading-->
          <!--begin::Illustration-->
          <div class="text-center pb-15 px-5">
            <img
              alt=""
              class="mw-100 h-200px h-sm-325px"
              src="../../../../assets/InvoiceNull.png"
            />
          </div>
          <!--end::Illustration-->
        </div>
        <!--end::Card body-->
      </div>
      <!--  如果有数据就显示数据  -->
      <div v-else>
        <!-- 列表开始 -->
        <div
          v-loading="loading"
          class="card mb-5 mb-xl-8"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          element-loading-spinner="el-icon-loading"
          element-loading-text="拼命加载中"
          style="margin-left: 3px; width: 100%"
        >
          <div class="card-body py-3">
            <div class="table-responsive">
              <table
                id="kt_ecommerce_products_table"
                class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
              >
                <thead>
                  <tr
                    class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                  >
                    <th
                      aria-controls="invoice_header"
                      class="min-w-50px"
                      colspan="1"
                      rowspan="1"
                      style="width: 120px"
                      tabindex="0"
                    >
                      发票抬头
                    </th>
                    <th
                      aria-label="invoice_tax_number"
                      class="min-w-50px"
                      colspan="1"
                      rowspan="1"
                      style="width: 120px"
                      tabindex="0"
                    >
                      发票税号
                    </th>
                    <th
                      aria-label="company_registered_address"
                      class="min-w-50px"
                      colspan="1"
                      rowspan="1"
                      style="width: 120px"
                      tabindex="0"
                    >
                      注册地址
                    </th>
                    <th
                      aria-label="registration_phone_number"
                      class="min-w-50px"
                      colspan="1"
                      rowspan="1"
                      style="width: 120px"
                      tabindex="0"
                    >
                      注册电话
                    </th>
                    <th
                      aria-label="opening_bank"
                      class="min-w-50px"
                      colspan="1"
                      rowspan="1"
                      style="width: 120px"
                      tabindex="0"
                    >
                      开户银行
                    </th>
                    <th
                      aria-label="BANK_ACCOUNT_NUMBER"
                      class="min-w-50px"
                      colspan="1"
                      rowspan="1"
                      style="width: 120px"
                      tabindex="0"
                    >
                      银行账号
                    </th>
                    <th
                      aria-label="recipient_email"
                      class="min-w-50px"
                      colspan="1"
                      rowspan="1"
                      style="width: 120px"
                      tabindex="0"
                    >
                      收票人邮箱
                    </th>
                    <th
                      aria-label="recipient_email"
                      class="min-w-50px"
                      colspan="1"
                      rowspan="1"
                      style="width: 120px"
                      tabindex="0"
                    >
                      是否专票
                    </th>
                    <th
                      aria-label="operation"
                      class="sorting_disabled"
                      colspan="1"
                      rowspan="1"
                      style="width: 120px"
                    >
                      操作
                    </th>
                  </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                  <tr v-for="(item, index) in invoiceList" :key="index">
                    <!-- 发票抬头 -->
                    <td>
                      <div class="d-flex pe-0">
                        <span class="text-center">{{ item.fpName }} </span>
                      </div>
                    </td>
                    <!--发票税号-->
                    <td class="pe-0">
                      <span class="fw-light">{{ item.fpNo }} </span>
                    </td>
                    <!-- 注册地址 -->
                    <td class="pe-0">
                      <span class="fw-light">{{ item.fpAdd }}</span>
                    </td>
                    <!-- 注册电话 -->
                    <td class="pe-0">
                      <span class="">{{ item.fpTel }}</span>
                    </td>
                    <!-- 开户银行 -->
                    <td class="pe-0">
                      <span class="">{{ item.fpYh }}</span>
                    </td>
                    <!-- 银行账号 -->
                    <td class="pe-0">
                      <span class="">{{ item.fpYhzh }}</span>
                    </td>
                    <!-- 收票人邮箱 -->
                    <td class="pe-0">
                      <span class="">{{ item.sjEmail }}</span>
                    </td>
                    <!-- 是否专票 -->
                    <td class="pe-0">
                      <span class="" v-if="item.special">是</span>
                      <span class="" v-if="!item.special">否</span>
                    </td>
                    <!-- 操作 -->
                    <td class="">
                      <el-button
                        size="small"
                        type="primary"
                        @click="clickEdit(item.id)"
                        >编辑</el-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination
                :total-items="totalCount"
                @page-change="pageChange"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    弹窗-->
    <ModalDialog ref="InvoiceCreate" title="添加发票抬头" width="45%">
      <InvoiceCreate ref="formInvoiceCreate">
        <el-form-item>
          <el-button type="primary" @click="createSumit">立即创建</el-button>
          <el-button @click="$refs.InvoiceCreate.close()">取消</el-button>
        </el-form-item>
      </InvoiceCreate>
    </ModalDialog>
    <ModalDialog ref="InvoiceEdit" title="编辑发票抬头" width="45%">
      <InvoiceEdit
        ref="formInvoiceEdit"
        :InvoiceId="cleckId"
        @refresh-list="refreshList"
      >
        <el-form-item>
          <el-button type="primary" @click="clickInvoiceEdit">提交</el-button>
          <el-button @click="clickCancelInvoice">取消</el-button>
        </el-form-item>
      </InvoiceEdit>
    </ModalDialog>
  </div>
</template>

<script>
import InvoiceCreate from "@/views/Finance/components/Invoice/InvoiceCreate.vue";
import InvoiceEdit from "@/views/Finance/components/Invoice/InvoiceEdit.vue";
import { getInvoiceListApi } from "@/api/invoiceApi";

export default {
  name: "InvoiceList",
  components: {
    InvoiceCreate,
    InvoiceEdit,
  },
  // 组件的data属性
  data() {
    return {
      invoiceEditShow: false,
      loading: false,
      invoiceList: [],
      totalCount: 10,
      params: {
        iDisplayStart: 1,
        iDisplayLength: 10,
        sEcho: "",
        fpName: "",
        fpNo: "",
      },
      cleckId: 0,
    };
  },
  methods: {
    createSumit() {
      let a = this.$refs.formInvoiceCreate.onSubmit();
      this.$refs.InvoiceCreate.close();
      setTimeout(() => {
        this.$swal.fire({
          icon: "success",
          title: "添加成功",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        this.getInvoiceList();
      }, 500);
    },
    async getInvoiceList() {
      this.loading = true;
      let { data } = await getInvoiceListApi(this.params);
      this.invoiceList = data.data;
      this.totalCount = data.total;
      this.loading = false;
    },
    pageChange(currentPage, pageSize) {
      this.params.iDisplayStart = currentPage;
      this.params.iDisplayLength = pageSize;
      this.getInvoiceList();
    },
    clickEdit(data) {
      this.cleckId = data;
      this.$refs.InvoiceEdit.open();
      this.$refs.formInvoiceEdit.getInvoiceObj(data);
    },
    clickInvoiceEdit() {
      this.invoiceEditShow = true;
      this.$refs.formInvoiceEdit.onSubmit();
    },
    refreshList() {
      this.getInvoiceList();
      this.$refs.InvoiceEdit.close();
    },
    clickCancelInvoice() {
      this.invoiceEditShow = false;
      this.$refs.formInvoiceEdit.clean();
      this.$refs.InvoiceEdit.close();
    },
  },
  created() {
    this.getInvoiceList();
  },
};
</script>

<style scoped>
</style>