<template>
  <div
    class="bg-body"
    id="kt_body"
    style="display: flex; flex-direction: column; height: 100vh"
  >
    <div class="d-flex flex-column flex-root">
      <div
        class="d-flex flex-column my-component flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      >
        <div
          class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
        >
          <a class="mb-14">
            <img alt="Logo" src="/logo-2-dark.png" class="h-65px" />
          </a>
          <div
            class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto"
          >
            <form
              class="form w-100"
              novalidate="novalidate"
              id="kt_sign_in_form"
              action=""
            >
              <div class="text-center mb-10">
                <h1 class="text-dark mb-3">欢迎进入系统</h1>
                <div class="text-gray-400 fw-bold fs-4">
                  没有账号?
                  <a href="javascript:void(0);" class="link-primary fw-bolder" @click="$router.push('/register')">申请账号</a>
                </div>
              </div>
              <div class="fv-row mb-10" v-if="type=='password'">
                <label class="form-label fs-6 fw-bolder text-dark">账号</label>
                <input
                  placeholder="请输入账号名"
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  id="username"
                  v-model="parame.userName"
                  name="username"
                  autocomplete="off"
                />
              </div>
              <div class="fv-row mb-10" v-if="type=='code'">
                <label class="form-label fs-6 fw-bolder text-dark">手机号</label>
                <input
                    placeholder="请输入手机号"
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    id="username"
                    v-model="parames.tel"
                    name="username"
                    autocomplete="off"
                />
              </div>
              <div class="fv-row mb-10" data-kt-password-meter="true" v-if="type=='password'">
                <div class="d-flex flex-stack mb-2">
                  <label class="form-label fw-bolder text-dark fs-6 mb-0"
                    >密码</label
                  >
                  <a
                    @click="type='code'"
                    class="link-primary fs-6 fw-bolder"
                    >手机验证码登录</a
                  >
                </div>
                <div class="position-relative mb-3">
                  <input
                    class="form-control form-control-lg form-control-solid"
                    v-model="parame.password"
                    :type="inputType"
                    placeholder="请输入登录密码"
                    name="password"
                    id="password"
                    autocomplete="off"
                    @keyup.enter="userLoginMethod"
                  />
                  <span
                    class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                    data-kt-password-meter-control="visibility"
                  >
                    <i
                      class="bi bi-eye-slash fs-2"
                      style="padding-right: 40px"
                      v-show="inputType == 'password'"
                      @click="inputType = 'text'"
                    ></i>
                    <i
                      class="bi bi-eye fs-2"
                      style="padding-right: 40px"
                      v-show="inputType != 'password'"
                      @click="inputType = 'password'"
                    ></i>
                  </span>
                </div>
              </div>
              <div class="fv-row mb-10" data-kt-password-meter="true" v-if="type=='code'">
                <div class="d-flex flex-stack mb-2">
                  <label class="form-label fw-bolder text-dark fs-6 mb-0"
                  >验证码</label
                  >
                  <a
                      @click="type='password'"
                      class="link-primary fs-6 fw-bolder"
                  >密码登录</a
                  >
                </div>
                <div class="position-relative" style="display: flex;justify-content: space-between">
                  <input
                      class="form-control form-control-lg form-control-solid" style="width: 280px;height: 20px"
                      v-model="parames.code"
                      type="text"
                      placeholder="请输入手机验证码"
                      @keyup.enter="userLoginMethod"
                  />
                  <el-link type="primary" :underline="false" @click="sendTelCode" v-if="!sending">发送验证码</el-link>
                  <el-link type="primary" :underline="false" v-if="sending">{{ sendSec }}秒后重新获取</el-link>
<!--                  <button
                      type="button"
                      @click="userLoginMethod"
                      id="loginsystem"
                      class="btn btn-sm btn-primary mb-5"
                  >
                    <span class="indicator-label form-actions">发送验证码</span>
                    <span class="indicator-progress"
                    >清等待...
                    <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span
                    ></span>
                  </button>-->
                </div>
              </div>
              <div class="text-center">
                <button
                  type="button"
                  @click="userLoginMethod"
                  id="loginsystem"
                  class="btn btn-lg btn-primary w-100 mb-5"
                >
                  <span class="indicator-label form-actions">登录系统</span>
                  <span class="indicator-progress"
                    >清等待...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span
                  ></span>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="d-flex flex-center flex-column-auto p-10">
          <div class="d-flex align-items-center fw-bold fs-6">
            <a href="#" class="text-muted text-hover-primary px-2">联系我们：</a>
            <a href="#" class="text-muted text-hover-primary px-2">0571 8796 3208</a>
            <a href="#" class="text-muted text-hover-primary px-2">yigworld@vip.163.com</a>
            <a href="https://beian.miit.gov.cn" class="text-muted text-hover-primary px-2"
               target="_blank">浙ICP备16046962号-4</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapState} from "vuex";
import {getVerificationCode} from "@/api/userApi";

export default {
  name: "Dashboard",
  data() {
    return {
      inputType: "password",
      parame: {
        userName: "",
        password: "",

      },
      parames:{
        tel:"",
        code:""
      },
      type:"password",
      sending:false,
      sendSec:60,
    };
  },
  methods: {
    sendCode(){
      this.sending = true;
      if (this.sendSec > 0) {
        this.sendSec--;
        setTimeout(() => {
          this.sendCode();
        }, 1000);
      } else {
        this.sending = false;
        this.sendSec = 60;
      }
    },
    async sendTelCode(){
      if(!/^1[3456789]\d{9}$/.test(this.parames.tel)){
        this.$message({
          message: '请填写正确手机号码',
          type: 'warning'
        });
        return;
      }
      let {data} = await getVerificationCode(this.parames)
      this.$message({
        message: '验证码发送成功,请注意查收！',
        type: 'success'
      });
      this.sendCode()
    },
    ...mapActions("user", ["userLogin","userTelCodeLogin"]),
    userLoginMethod() {
      let whiteList = ["ygadmin","后台展示","5088"];
      //if (whiteList.includes(this.parame.userName)) {
      //  if (!localStorage.getItem("white")){
      //    window.location.href="https://www.1c-1g.com/saas.jsp";
      //    return;
      //  }
      //}
      if (this.type==="code") {
        this.userTelCodeLogin(this.parames);
      }else{
        this.userLogin(this.parame);
      }
    },
  },
  computed: {
    ...mapState("web", ["obj"]),
  },
};
</script>
<style>
.my-component {
  background-image: url("~@/assets/development-hd.png");
}
</style>
