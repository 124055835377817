<template>
  <div>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-width="100px"
    >
      <el-form-item label="充值金额" prop="money">
        <el-input v-model.number="ruleForm.money"></el-input>
      </el-form-item>
      <el-form-item class="receipt_form is-required" label="银行回执单">
        <el-upload
          class="upload-demo"
          ref="upload"
          action="string"
          :auto-upload="true"
          :http-request="handleChange"
          :show-file-list="false"
        >
          <el-avatar
            shape="square"
            :size="100"
            fit="fill"
            :src="ruleForm.picUrl"
          ></el-avatar>
          点击图片进行修改
        </el-upload>
      </el-form-item>
      <slot name="button"></slot>
    </el-form>
  </div>
</template>

<script>
import { uploadFileApi } from "@/api/uploadFileApi";
import {
  addStaffCz,
  getRechargeRecordInfoApi,
  updateStaffCzApi,
} from "@/api/staffCzApi";

export default {
  name: "RechargeEdit",
  data() {
    var checkNum = (rule, value, callback) => {
        if (isNaN(value)) {
          return callback(new Error('请输入有效数字'));
        }
        callback();
      };
    return {
      fileList: [],
      id: "",
      ruleForm: {
        money: "",
        picUrl: "",
      },
      status: 0,
      rules: {
        money: [
          { required: true, message: "请输入充值金额", trigger: "blur" },
          { validator:checkNum, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    changeFile(file, fileList) {
      console.log(file.url);
      console.log(fileList);
      // this.fileList = b
    },
    async getRechargeRecordInfo(id) {
      this.id = id;
      let { data } = await getRechargeRecordInfoApi({
        id,
      });
      this.ruleForm = data;
      // TODO: 图片文件回填
      // this.fileList = ["asdasd.png"]
    },
    async handleChange(param) {
      const data = new FormData();
      if (param) {
        data.append("file", param.file);
        data.append("moduleName", "finance");
        if (param.file.size >= this.fileSize * 1024 * 1024) {
          this.$message({
            message: "文件过大,最大" + this.fileSize + "MB",
            type: "warning",
          });
          return;
        }
      }
      let { data: res } = await uploadFileApi(data);
      this.ruleForm.picUrl = res.fileUrl;
      this.$message({
        message: "上传成功!",
        type: "success",
          });
    },
    submit() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          await updateStaffCzApi({
            id: this.id,
            money: this.ruleForm.money,
            picUrl: this.ruleForm.picUrl,
          });
          this.$swal.fire({
            icon: "success",
            title: "修改成功",
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
          });
          this.status = 1;
        } else {
          return false;
        }
      });
    },
    clearData() {
      this.ruleForm.money = "";
    },
  },
};
</script>

<style scoped></style>