<template>
    <div>
        <div id="kt_post" class="post flex-column-fluid">
            <div id="kt_content_container" class="container"
                style="margin-top:10px;max-width:100%;flex-direction:row!important;display:flex;">
                <div id="kt_app_main" class="app-main flex-column flex-row-fluid">
                    <div class="d-flex flex-column flex-column-fluid">
                        <div v-if="!userRole.includes('vipMember')">
                            <recommendShop ref="recommendShop" @closeCopyShopDialog="closeCopyShopDialog"></recommendShop>
                        </div>
                      <div class="el-collapse-item__header" style="margin-top: 10px;justify-content: space-between">
                        <div>
                          <span style="font-size:16px;font-weight:600;padding-left: 10px">已搭建商城&nbsp;&nbsp;</span>
                          <span style="font-size:12px;color:#999;">创建独有的企业场景</span>
                        </div>
                        <div class="d-flex align-items-center ">
                          <a href="javascript:void(0)" class="btn btn-sm btn-primary" style="margin-right:20px"
                             @click="openCreateShopDialog"> 创 建 商 城 </a>
                          <!--end::Button-->
                          <!--begin::Button-->
                          <a href="javascript:void(0)" class="btn btn-sm btn-primary" data-bs-toggle="modal"
                             data-bs-target="#kt_Topic_body" @click="openTopicDialog" id="kt_toolbar_primary_button_topic"> 活 动 管 理
                          </a>
                          <!--end::Button-->
                        </div>

                      </div>
                        <div id="kt_app_content">
                            <shopList ref="shopListModal"></shopList>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="创建商城"  v-if="createShopVisible"  :visible.sync="createShopVisible" width="80%" @close="closeCreateShopDialog" destroy-on-close="true">
            <template #title>
                <div class="modal-content">
                    <div class="modal-header">
                        <h2>创建商城</h2>
                    </div>
                </div>
            </template>
            <createShop ref="createShopModal"  @closePopup="closeShopPopup" ></createShop>
        </el-dialog>
        <el-dialog v-if="topicVisible" :visible.sync="topicVisible" width="80%">
            <!-- <template #title>
                <div class="modal-content">
                    <div class="modal-header">
                        <h2>活动</h2>
                    </div>
                </div>
            </template> -->
            <topicList ></topicList>
        </el-dialog>
    </div>
</template>
<script>
import shopList from '@/views/Shop/component/shopList.vue'
import createShop from '@/views/Shop/component/createShopModal/createShop.vue'
import topicList from '@/views/Shop/component/topicList.vue'
import recommendShop from '@/views/Shop/component/recommendShop.vue'
export default {
    components: {
        shopList,
        createShop,
        topicList,
        recommendShop
    },
    data() {
        return {
            createShopVisible: false,
            topicVisible:false,
            userRole:localStorage.getItem('role')
        }
    },
    methods: {
      closeShopPopup() {
        this.createShopVisible = false
        this.$refs.shopListModal.resetGetShopList()
        // 关闭弹窗逻辑
      },
      openCreateShopDialog(){
        this.createShopVisible = true
        this.$refs.shopListModal.currentWxShopId=null
      },

       closeCreateShopDialog(){
        this.$refs.shopListModal.resetGetShopList()
      },
      openTopicDialog(){
        this.topicVisible = true
      },
      closeCopyShopDialog() {
        this.$refs.shopListModal.resetGetShopList()
      },

    },
    created() {
        //this.getChooseGoodsList();
    },
    updated() {
        // 当数据更新后，可以在此处手动初始化下拉菜单插件
        // this.rows.forEach((_, index) => {
        //     this.toggleDropdown(index);
        // });
    },


}
</script>
<style scoped></style>