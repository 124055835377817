<template>
  <div>
    <!--顶部标题-->
    <div>
      <div
        class="toolbar"
        style="
          background-color: #fff;
          padding: 0.5rem;
          border-radius: 15px;
          margin: 10px auto;
          max-width: 100%;
        "
      >
        <div
          id="kt_toolbar_container1"
          class="container-fluid d-flex flex-stack"
          style="padding: 0 30px"
        >
          <div
            class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container1'}"
          >
            <h1
              :style="`font-size: 1.17rem !important`"
              class="d-flex align-items-center text-dark fw-bolder fs-3 my-1"
            >
              充值记录
            </h1>
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">Recharge Record</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--  下方卡片  -->
    <div>
      <!-- 列表开始 -->
      <div
        v-loading="loading"
        class="card mb-5 mb-xl-8"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        element-loading-spinner="el-icon-loading"
        element-loading-text="拼命加载中"
        style="margin-left: 3px; width: 100%"
      >
        <div class="card-body py-3">
          <div class="table-responsive">
            <table
              id="kt_ecommerce_products_table"
              class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
            >
              <thead>
                <tr
                  class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                >
                  <th
                    aria-controls="recharge_amount"
                    class="min-w-50px"
                    colspan="1"
                    rowspan="1"
                    style="width: 120px"
                    tabindex="0"
                  >
                    充值金额
                  </th>
                  <th
                    aria-label="review_status"
                    class="min-w-50px"
                    colspan="1"
                    rowspan="1"
                    style="width: 120px"
                    tabindex="0"
                  >
                    审核状态
                  </th>
                  <th
                    aria-label="recharge_voucher"
                    class="min-w-50px"
                    colspan="1"
                    rowspan="1"
                    style="width: 120px"
                    tabindex="0"
                  >
                    充值凭证
                  </th>
                  <th
                    aria-label="recharge_time"
                    class="min-w-50px"
                    colspan="1"
                    rowspan="1"
                    style="width: 120px"
                    tabindex="0"
                  >
                    充值时间
                  </th>
                  <th
                    aria-label="recharge_time"
                    class="min-w-50px"
                    colspan="1"
                    rowspan="1"
                    style="width: 20px"
                    tabindex="0"
                  >
                    操作
                  </th>
                </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
                <tr v-for="(item, index) in rechargeRecordList" :key="index">
                  <!-- 充值金额 -->
                  <td>
                    <div class="d-flex pe-0">
                      <span class="text-center"
                        >{{ item.money | format }}
                      </span>
                    </div>
                  </td>
                  <!--审核状态-->
                  <td class="pe-0">
                    <span v-if="item.isType === '审核中'" class="fw-bold">
                      <span class="badge badge-danger fs-7 fw-bold">{{
                        item.isType
                      }}</span>
                    </span>
                    <span v-if="item.isType === '审核通过'" class="fw-bold">
                      <span class="badge badge-success fs-7 fw-bold">{{
                        item.isType
                      }}</span>
                    </span>
                    <span v-if="item.isType === '审核不通过'" class="fw-bold">
                      <span class="badge badge-secondary fs-7 fw-bold">{{
                          item.isType
                        }}</span>
                    </span>
<!--                    badge-secondary-->
                  </td>
                  <!-- 充值凭证 -->
                  <td class="pe-0">
                    <img :src="item.picUrl" width="100px" />
                  </td>
                  <!-- 充值时间 -->
                  <td class="pe-0">
                    <span class="">{{ item.crTime }}</span>
                  </td>
                  <!-- 操作 -->
                  <td v-if="item.isType == '审核中'" class="pe-0">
                    <el-button
                      size="mini"
                      type="primary"
                      @click="clickRechargeEditOpen(item.id)"
                      >编辑</el-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination
              :total-items="totalCount"
              @page-change="pageChange"
            ></Pagination>
          </div>
        </div>
      </div>
    </div>
    <ModalDialog ref="RechargeEdit" title="充值编辑" width="50%">
      <RechargeEdit ref="formRechargeEdit">
        <template slot="button">
          <el-form-item>
            <el-button type="primary" @click="clickRechargeEdit"
              >提交</el-button
            >
            <el-button @click="clickCancelRechargeEdit">取消</el-button>
          </el-form-item>
        </template>
      </RechargeEdit>
    </ModalDialog>
    <div style="height: 10px"></div>
  </div>
</template>

<script>
import { getRechargeRecordListApi } from "@/api/staffCzApi";
import { formatMoney } from "@/utils/formatMoney";
import RechargeEdit from "@/views/Finance/components/RechargeRecord/RechargeEdit.vue";

export default {
  name: "RechargeRecordList",
  components: { RechargeEdit },
  // 组件的data属性
  data() {
    return {
      loading: false,
      rechargeRecordList: [],
      totalCount: 10,
      params: {
        iDisplayStart: 1,
        iDisplayLength: 10,
        sEcho: "",
      },
    };
  },
  methods: {
    async getRechargeRecordList() {
      this.loading = true;
      let { data } = await getRechargeRecordListApi(this.params);
      this.rechargeRecordList = data.data;
      this.totalCount = data.total;
      this.loading = false;
    },
    pageChange(currentPage, pageSize) {
      this.params.iDisplayStart = currentPage;
      this.params.iDisplayLength = pageSize;
      this.getRechargeRecordList();
    },
    clickRechargeEdit() {
      this.$refs.formRechargeEdit.submit();
      setTimeout(() => {
        if (this.$refs.formRechargeEdit.status) {
          this.$refs.formRechargeEdit.clearData();
          this.$refs.RechargeEdit.close();
          this.getRechargeRecordList();
        }
      }, 500);
    },
    clickCancelRechargeEdit() {
      this.$refs.formRechargeEdit.clearData();
      this.$refs.RechargeEdit.close();
    },
    clickRechargeEditOpen(id) {
      this.$refs.RechargeEdit.open();
      this.$refs.formRechargeEdit.getRechargeRecordInfo(id);
    },
  },
  filters: {
    format(num) {
      return formatMoney(num);
    },
  },
  created() {
    this.getRechargeRecordList();
  },
};
</script>

<style scoped>
</style>
