import service from "@/utils/request"
//获取商户号
export function getMyWxMacApi() {
    return service({
        url: '/wxMac/getMyWxMac',
        method: 'get',
    })
}
//保存商户号
export function updateMyWxMacApi(data) {
    return service({
        url: '/wxMac/updateMyWxMac',
        method: 'put',
        data
    })
}

//新增商户号
export function addMyWxMacApi(data) {
    return service({
        url: '/wxMac/addMyWxMac',
        method: 'post',
        data
    })
}