import service from "@/utils/request"
export function getVoucherItemList(data) {
    return service({
        url: '/wxShopVoucher/getVoucherItemList',
        method: 'get',
        params:data
    })
}

export function addVoucher(data) {
    return service({
        url: '/wxShopVoucher/addVoucher',
        method: 'post',
        params:data
    })
}

export function getVoucherTemplateList(data){
    return service({
        url:'/wxShopVoucher/getVoucherTemplateList',
        method:'get',
        params:data
    })
}

export function hasVoucherData(data){
    return service({
        url:'/wxShopVoucher/hasVoucherData',
        method:'get',
        params:data
    })
}

export function exportVoucher(data){
    return service({
        url:'/wxShopVoucher/exportVoucher',
        method:'get',
        params:data,
        responseType: 'blob',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}

export function exportUpdateErrorList(data){
    return service({
        url:'/wxShopVoucher/exportUpdateErrorList',
        method:'get',
        params:data,
        responseType: 'blob',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}


export function deleteAllCode(data) {
    return service({
        url: '/wxShopVoucher/deleteAllCode',
        method: 'delete',
        params:data
    })
}

export function uploadVoucherFromExcel(data){
    return service({
        url: '/wxShopVoucher/uploadVoucherFromExcel',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data
    })
}

export function updateVoucherScore(data){
    return service({
        url:'/wxShopVoucher/updateVoucherScore',
        method:'put',
        params:data
    })
}

export function activeVoucherItem(data){
    return service({
        url:'/wxShopVoucher/activeVoucherItem',
        method:'put',
        params:data
    })
}

export function batchActiveVoucherItem(data){
    return service({
        url:'/wxShopVoucher/batchActiveVoucherItem',
        method:'post',
        data:data
    })
}