import service from "@/utils/request"
// 获取卡包列表
export function getCashOutListApi(data) {
    return service({
        url: '/sjCashOut/getCashOutList',
        method: 'get',
        params:data
    })
}

export function getMyCashOut() {
    return service({
        url: '/sjCashOut/getMyCashOut',
        method: 'get',
    })
}

//提现申请
// /sjCashOut/cashOutApplication
export function cashOutApplicationApi(data) {
    return service({
        url: '/sjCashOut/cashOutApplication',
        method: 'post',
        data
    })
}
// /sjCashOut/getCashOutOrderInfoById
export function getCashOutOrderInfoByIdApi(data) {
    return service({
        url: '/sjCashOut/getCashOutOrderInfoById',
        method: 'get',
        params:data
    })
}