<template>
  <div>
    <form action="#" style="width: 100%">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <!--begin::Compact form-->
          <div class="d-flex align-items-center">
            <!--begin::Input group-->
            <div class="position-relative w-md-400px me-md-2">
								<span
                    class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
									<svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px"
                       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
										<g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
											<rect height="24" width="24" x="0" y="0"></rect>
											<path
                          d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                          fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
											<path
                          d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                          fill="#000000" fill-rule="nonzero"></path>
										</g>
									</svg>
								</span>
              <!--end::Svg Icon-->
              <input id="sTitle"
                     v-model="params.cardNo"
                     class="form-control form-control-solid ps-10" name="cardNo" placeholder="卡号（含卡号前缀）"
                     type="text" value=""/>
            </div>
            <!--end::Input group-->
            <!--begin:Action-->
            <div class="d-flex align-items-center">
              <div class="btn btn-primary me-5 btn-sm" @click="queryCardList">
                查询
              </div>
              <div class="btn btn-secondary me-5 btn-sm" @click="reloadCardList">
                重置
              </div>
              <a id="kt_horizontal_search_advanced_link" class="btn btn-link" data-bs-toggle="collapse"
                 href="#kt_advanced_search_form1">高级查询</a>
            </div>
            <!--end:Action-->
            <div class="d-flex flex-wrap my-1 ms-auto">

              <!--begin::Actions-->
              <div style="margin-left: 5px">
                <el-button size="small" type="danger" @click="deleteAllItem">
                  一键删除未使用卡密
                </el-button>
                <el-button size="small" type="primary" @click="openNotify">
                  一键制作实体卡
                </el-button>
                <el-button size="small" type="primary" @click="updateKey(1)" :disabled="idArr.length==0">
                  激活卡密
                </el-button>
                <el-button size="small" type="primary" @click="updateKey(3)" :disabled="idArr.length==0">
                  失效卡密
                </el-button>
                <el-button size="small" type="primary" @click="dialogVisible2=true">
                  批量工具
                </el-button>
                <el-button size="small" type="primary" @click="createKey">
                  制作卡密
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <!--end::Compact form-->

        <!--begin::高级搜索-->
        <div id="kt_advanced_search_form1" class="collapse">
          <!--begin::Separator-->
          <div class="separator separator-dashed mt-9 mb-6"></div>
          <!--end::Separator-->
          <!--begin::Row-->
          <div class="row g-8 mb-8">

            <!--begin::Col-->
            <div class="col-lg-3">
              <label class="fs-6 form-label fw-bolder text-dark">卡号前缀</label>
              <br/>
              <el-input v-model="params.cardNoPrefix" placeholder="卡号前缀"></el-input>
            </div>
            <div class="col-lg-3">
              <label class="fs-6 form-label fw-bolder text-dark">卡密</label>
              <br/>
              <el-input v-model="params.cardSecret" placeholder="卡密"></el-input>
            </div>
            <div class="col-lg-3">
              <label class="fs-6 form-label fw-bolder text-dark">使用人手机号</label>
              <br/>
              <el-input v-model="params.tel" placeholder="使用人手机号"></el-input>
            </div>
            <div class="col-lg-3">
              <label class="fs-6 form-label fw-bolder text-dark">是否申请制作实体卡</label>
              <br/>
              <el-select v-model="params.isMake" placeholder="请选择" style="width: 100%;">
                <el-option
                    v-for="item in isMake"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="col-lg-3">
              <label class="fs-6 form-label fw-bolder text-dark">卡密状态</label>
              <br/>
              <el-select v-model="params.status" placeholder="请选择" style="width: 100%;">
                <el-option
                    v-for="item in keyStatus"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="col-lg-3">
              <label class="fs-6 form-label fw-bolder text-dark">创建时间范围</label>
              <br/>
              <el-date-picker
                  style="width: 100%;"
                  v-model="crTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="pickerOptions"
                  :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
            </div>
            <div class="col-lg-3">
              <label class="fs-6 form-label fw-bolder text-dark">激活时间范围</label>
              <br/>
              <el-date-picker
                  style="width: 100%;"
                  v-model="activeUpdateTime"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
            </div>

            <!--end::Col-->
          </div>
          <!--end::Row-->
        </div>
        <!--end::Advance form-->
      </div>
      <!--end::Card body-->

      <!--end::Card-->
    </form>
    <el-table
        :data="rows"
        style="width: 100%;margin-bottom: 20px"
        v-loading="loading"
        element-loading-text="数据加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-table-column label="多选" align="center" fixed width="30" :key="checkboxInput">
        <template slot-scope="scope">
          <div
              class="form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
                class="form-check-input"
                type="checkbox"
                value="1"
                @click="addIdArr(scope.row.id)"
                :checked="idArr.includes(scope.row.id)"
            />
          </div>
        </template>
        <template slot="header">
          <div
              class="form-check form-check-sm form-check-custom form-check-solid me-3"
          >
            <input
                class="form-check-input"
                type="checkbox"
                data-kt-check="true"
                data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                :value="true"
                id="checkboxInput"
                ref="checkboxInput"
                v-model="checkboxInput"
                @change="checkboxInputEvent"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="cardNo" label="卡号" align="center" fixed width="100"></el-table-column>
      <el-table-column prop="cardSecret" label="卡密" align="center" fixed width="100"></el-table-column>
      <el-table-column prop="tel" label="手机号" align="center"></el-table-column>
      <el-table-column label="礼卡状态" align="center">
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.status|keyStatusTypeFilters"
              effect="dark"
          size="mini"
          >
          {{ scope.row.status|keyStatusFilters }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="orderIdJson" label="关联订单" align="center"></el-table-column>
      <el-table-column prop="cardNoPrefix" label="制卡前缀" align="center"></el-table-column>
      <el-table-column label="可领取方式" align="center">
        <template slot-scope="scope">
          {{ scope.row.type|keyTypeFilters }}
        </template>
      </el-table-column>
      <el-table-column prop="isMake" label="申请制作实体卡" width="150" align="center">
        <template slot-scope="scope">
          {{ scope.row.isMake ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column prop="activeUpdateTime" label="激活时间" width="150" align="center"></el-table-column>
      <el-table-column prop="endTime" label="过期时间" width="150" align="center"></el-table-column>
      <el-table-column prop="exchangeTime" label="兑换时间" width="150" align="center"></el-table-column>
      <el-table-column prop="crTime" label="制卡时间" width="150" align="center"></el-table-column>
      <!--<el-table-column label="操作" fixed="right"></el-table-column>-->
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.current"
        :page-sizes="[10, 20, 30, 50,100,200,500]"
        :page-size="params.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="params.total">
    </el-pagination>
    <!--  制作卡密 -->
    <el-dialog
        title="制作卡密"
        append-to-body
        :visible.sync="dialogVisible"
        width="30%"
        v-loading="loading"
        element-loading-text="卡密生成中,请勿刷新网页..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-form :model="generateParams" :rules="generateParamsRules" ref="generateParams">
        <el-form-item label="卡号前缀" prop="cardNoPrefix">
          <el-input v-model="generateParams.cardNoPrefix" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="生成数量" prop="num">
          <el-input v-model.number="generateParams.num" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="卡密到期时间">
          <br>
          <el-date-picker
              style="width: 100%;"
              v-model="generateParams.endTime"
              type="datetime"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="postCreateKey">制 作</el-button>
    </el-dialog>
    <!--  批量工具  -->
    <el-dialog
        title="批量工具"
        append-to-body
        :visible.sync="dialogVisible2"
        width="30%">
      <el-form ref="form" :model="updateObj" label-width="80px" :rules="updateObjRules">
        <el-form-item label="操作" prop="status">
          <el-select v-model="updateObj.status" placeholder="请选择操作" size="small">
            <el-option :label="item.label" v-for="(item,index) in statusOption" :value="item.key" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-radio-group v-model="updateObj.type" size="small" @change="groupType">
            <el-radio-button label="0">按卡号区间</el-radio-button>
            <el-radio-button label="1">按卡号前缀</el-radio-button>
            <el-radio-button label="2">按激活时间</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="卡号区间" prop="startCardNo" v-show="updateObj.type==='0'">
          <el-col :span="11">
            <el-input v-model="updateObj.startCardNo" size="small">
            </el-input>
          </el-col>
          <el-col class="line" :span="2" style="text-align: center">-</el-col>
          <el-col :span="11">
            <el-input v-model="updateObj.endCardNo" size="small"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="卡号前缀" prop="cardNoPrefix" v-show="updateObj.type==='1'">
          <el-input v-model="updateObj.cardNoPrefix" size="small"></el-input>
        </el-form-item>
        <el-form-item label="激活时间" prop="time" v-show="updateObj.type==='2'">
          <el-date-picker
              style="width: 100%;"
              size="small"
              v-model="updateObj.time"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-button @click="dialogVisible2 = false">取 消</el-button>
      <el-button @click="updateKey(null)" type="primary">提 交</el-button>
    </el-dialog>
  </div>
</template>
<script>
import {eCardList} from "@/views/eCardNew/component/eCardList";
import index from "vuex";

export default {
  computed: {
    index() {
      return index
    }
  },
  mixins: [eCardList],
}
</script>
<style scoped>
.input-with-divider {
  width: 120px; /* 调整宽度 */
}
</style>