import { render, staticRenderFns } from "./MonthPrice.vue?vue&type=template&id=b7722ae0&scoped=true"
import script from "./MonthPrice.vue?vue&type=script&lang=js"
export * from "./MonthPrice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7722ae0",
  null
  
)

export default component.exports