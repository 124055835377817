import service from "@/utils/request"
// 获取运费模板
export function getFreightSelectApi(data) {
    return service({
        url: '/freight/getFreightSelect',
        method: 'post',
        data
    })
}

/**
 * 获取运费模板分页
 */
export function getFreightListApi(data) {
    return service({
        url: '/freight/getFreightList',
        method: 'get',
        params: data
    })
}

export function updateFreightIsStatusApi(data) {
    return service({
        url: '/freight/updateisStatus',
        method: 'post',
        data
    })
}

export function deleteFreightByIdApi(data) {
    return service({
        url: '/freight/delFreight',
        method: 'delete',
        data
    })
}

export function addFreightTemplate(data) {
    return service({
        url: '/freight/addFreight',
        method: 'post',
        data
    })
}
