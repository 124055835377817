export const goodsStatus = [
    {
        label: "上架",
        code: 1
    },
    {
        label: "下架",
        code: 2
    }
]

export const isJd = [
    {
        label: "京东",
        code: 1
    },
    {
        label: "自有",
        code: 2
    }
]
//发货时效
export const deliveryTime = [
    {
        label: "24小时内发货",
        code: "24小时内发货"
    }, {
        label: "48小时内发货",
        code: "48小时内发货"
    }, {
        label: "48小时内发货(周末、节假日除外)",
        code: "48小时内发货(周末、节假日除外)"
    }, {
        label: "72小时发货",
        code: "72小时发货"
    }, {
        label: "其他",
        code: 0
    },
]