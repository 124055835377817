<template>
  <!--发票申请记录-->
  <div>
    <!--顶部标题-->
    <div>
      <div class="toolbar"
           style="background-color: #fff; padding: 0.5rem;border-radius: 15px;margin: 10px auto; max-width:100%;">
        <div
            id="kt_toolbar_container1"
            class="container-fluid d-flex flex-stack"
            style="padding: 0 30px"
        >
          <div
              class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container1'}"
          >
            <h1 :style="`font-size: 1.17rem !important`"
                class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              发票申请记录
            </h1>
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">Invoice Application Record</li>
            </ul>
          </div>

        </div>

      </div>
    </div>
    <!--  下方卡片  -->
    <div>
      <!-- 列表开始 -->
      <div v-loading="loading" class="card mb-5 mb-xl-8"
           element-loading-background="rgba(0, 0, 0, 0.8)"
           element-loading-spinner="el-icon-loading"
           element-loading-text="拼命加载中"
           style="margin-left:3px;width:100%;">
        <div class="card-body py-3">
          <div class="table-responsive">
            <table id="kt_ecommerce_products_table"
                   class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
              <thead>
              <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0 text-center">

                <th aria-controls="invoice_amount" class="min-w-50px" colspan="1" rowspan="1"
                    style="width: 120px; "
                    tabindex="0">开票金额
                </th>
                <th aria-label="invoice_header" class=" min-w-50px" colspan="1" rowspan="1"
                    style="width: 120px; " tabindex="0">发票抬头
                </th>
                <th aria-label="invoice_tax_number" class=" min-w-50px" colspan="1" rowspan="1"
                    style="width: 120px;" tabindex="0">发票税号
                </th>
                <th aria-label="invoice_status" class=" min-w-50px" colspan="1" rowspan="1"
                    style="width: 120px;" tabindex="0">开票状态
                </th>
                <th aria-label="application_time" class=" min-w-50px" colspan="1" rowspan="1"
                    style="width: 120px;" tabindex="0">申请时间
                </th>
                <th aria-label="operation" class=" min-w-50px" colspan="1" rowspan="1"
                    style="width: 120px;" tabindex="0">操作
                </th>
              </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
              <tr v-for="(item,index) in invoiceApplicationList" :key="index" class="text-center">
                <!-- 开票金额 -->
                <td>
                  <div class=" pe-0">
                    <span class="text-center">{{ item.money | format }}  </span>
                  </div>
                </td>
                <!--发票抬头-->
                <td class=" pe-0">
                  <span class="fw-light">{{ item.fpName }} </span>
                </td>
                <!-- 发票税号 -->
                <td class=" pe-0">
                  <span class="fw-light">{{ item.fpNo }}</span>
                </td>
                <!-- 开票状态 -->
                <td class="pe-0 text-center">
                  <span v-if="item.isStauts == '未开票'" class="fw-bold">
                        <span class="badge badge-danger fs-7 fw-bold">{{ item.isStauts }}</span>
                      </span>
                  <span v-if="item.isStauts == '已开票'" class="fw-bold">
                        <span class="badge badge-success fs-7 fw-bold">{{ item.isStauts }}</span>
                      </span>
                </td>
                <!-- 申请时间 -->
                <td class=" pe-0">
                  <span class="">{{ item.crTime }}</span>
                </td>
                <!-- 操作 -->
                <td class=" pe-0">
                  <el-button size="mini" type="primary" @click="clickViewDetails(item.id)">查看详情</el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <Pagination :total-items="totalCount" @page-change="pageChange"></Pagination>
          </div>
        </div>
      </div>
    </div>
    <ModalDialog ref="InvoiceApplicationDetails" title="发票详情" width="70%">
      <InvoiceApplicationDetails ref="formInvoiceApplicationDetails">
        <el-button @click="closeInvoiceApplicationDetails">关闭</el-button>
      </InvoiceApplicationDetails>
    </ModalDialog>
  </div>
</template>
<script>
import {formatMoney} from "@/utils/formatMoney";
import {getApplyInvoiceForFxsListApi} from "@/api/ApplyInvoiceApi.jsApi";
import InvoiceEdit from "@/views/Finance/components/Invoice/InvoiceEdit.vue";
import InvoiceApplicationDetails from "@/views/Finance/components/InvoiceApplication/InvoiceApplicationDetails.vue";

export default {
  name: "InvoiceApplicationList",
  components: {InvoiceApplicationDetails, InvoiceEdit},
  // 组件的data属性
  data() {
    return {
      loading: false,
      invoiceApplicationList: [],
      totalCount: 10,
      params: {
        iDisplayStart: 1,
        iDisplayLength: 10,
        sEcho: "",
        sign: 1
      },
    };
  },
  methods: {
    async getInvoiceApplicationList() {
      this.loading = true
      let {data} = await getApplyInvoiceForFxsListApi(this.params);
      this.invoiceApplicationList = data.data;
      this.totalCount = data.total
      this.loading = false
    },
    pageChange(currentPage, pageSize) {
      this.params.iDisplayStart = currentPage;
      this.params.iDisplayLength = pageSize;
      this.getInvoiceApplicationList();
    },
    clickViewDetails(id) {
      this.$refs.InvoiceApplicationDetails.open()
      this.$refs.formInvoiceApplicationDetails.getApplyInvoiceInfo(id)
    },
    closeInvoiceApplicationDetails() {
      this.$refs.InvoiceApplicationDetails.close()
      this.$refs.formInvoiceApplicationDetails.cleanData()
    }
  },
  filters: {
    format(num) {
      return formatMoney(num)
    }
  },
  created() {
    this.getInvoiceApplicationList();
  }
}
</script>
<style scoped>

</style>