<template>
  <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start"
    data-kt-menu-offset="-50,0" class="menu-item show menu-here-bg menu-lg-down-accordion me-0 me-lg-4"
    :class="{ here: item.path.includes($route.path) }" style="z-index: 400">
    <span class="menu-link demo-bg-color" style="height: 60px; width: 60px"
      v-if="item.children && item.children.length > 0" @click="$router.push(item.path[0])">
      <span class="menu-icon">
        <i class="ki-outline" :class="[item.icon]" style="font-size: 1.6rem"></i>
      </span>
      <span class="menu-title font-CN" :style="'white-space: nowrap;font-size:0.9rem;width:' +
        0.9 * item.title.length +
        'rem'
        " v-if="item.title.length <= 3">{{ item.title }}</span>
      <span class="menu-title font-CN" style="white-space: nowrap; font-size: 0.8rem; text-align: center" v-else>{{
        item.title }}</span>
      <span class="menu-arrow d-lg-none"></span>
    </span>

    <span class="menu-link demo-bg-color" style="height: 60px; width: 60px" v-else @click="$router.push(item.path[0])">
      <span class="menu-icon">
        <i class="ki-outline" :class="[item.icon]" style="font-size: 1.6rem"></i>
      </span>
      <span class="menu-title font-CN" :style="'white-space: nowrap;font-size:0.9rem;width:' +
        0.9 * item.title.length +
        'rem'
        " v-if="item.title.length <= 3">{{ item.title }}</span>
      <span class="menu-title font-CN" style="white-space: nowrap; font-size: 0.7rem; text-align: center" v-else>{{
        item.title }}</span>
      <span class="menu-arrow d-lg-none"></span>
    </span>
    <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-200px"
      v-if="item.children && item.children.length > 0 && item.children.length != 1">
      <div class="menu-item" v-for="(nav, index) in item.children" :key="index"
        :class="nav.children ? `menu-lg-down-accordion` : ``"
        :data-kt-menu-trigger="nav.children ? `{default:'click', lg: 'hover'}` : ``"
        :data-kt-menu-placement="nav.children ? `right-start` : `right-start`">
        <template v-if="nav.path != item.path[0]">
          <router-link :to="nav.path" v-if="!nav.children || nav.children.length == 0" class="menu-link"
            data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click"
            data-bs-placement="right">
            <span class="menu-icon">
              <i class="ki-duotone fs-2" :class="[nav.icon]">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </span>
            <span class="menu-title">{{ nav.title }}</span>
          </router-link>
        </template>
        <template v-if="nav.children">
          <span class="menu-link">
            <span class="menu-icon">
              <i class="ki-outline fs-2" :class="[nav.icon]"></i>
            </span>
            <span class="menu-title">
              {{ nav.title }}
            </span>
            <span class="menu-arrow"></span>
          </span>
          <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg w-lg-225px"
            v-if="nav.children">
            <div class="menu-item" v-for="(el, index) in nav.children">
              <router-link class="menu-link" :to="el.path">
                <span class="menu-bullet">
                  <span class="bullet bullet-dot"></span>
                </span>
                <span class="menu-title">
                  {{ el.title }}
                </span>
              </router-link>
            </div>

          </div>
        </template>
      </div>
    </div>

  </div>
</template>
<script>
import { RouterLink } from 'vue-router';

export default {
  name: "Navicon",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() { },
  mounted() { },
  components: { RouterLink }
};
</script>