<template>

  <!--begin::Col-->
  <div class="col-xl-8 mb-xl-8">
    <div class="card h-md-100">
      <div class="card-header align-items-center border-0">
        <h3 class="fw-bold text-gray-900 m-0">全国商品池，甄选爆品</h3>
      </div>
      <div class="card-body pt-2">
        <ul class="nav nav-pills nav-pills-custom mb-3">
          <li class="nav-item mb-3 me-3 me-lg-6" v-for="(item, index) in navList" :key="index">
            <a class="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-80px h-85px py-4"
               :class="index===0?'active':''"
               data-bs-toggle="pill" :href="`#kt_stats_widget_2_tab_${index+1}`">
              <div class="nav-icon">
                <img alt="" :src="item.imagesSrc" class=""/>
              </div>
              <span class="nav-text text-gray-700 fw-bold fs-7 lh-1">
                        {{ item.title.substring(0, 4) }}
                    </span>
              <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade" :id="`kt_stats_widget_2_tab_${index+1}`" v-for="(item, index) in navList"
               :key="index" :class="index===0?'show active':''">
            <div class="table-responsive">
              <table class="table table-row-dashed align-middle gs-0 gy-4 my-0">
                <thead>
                <tr class="fs-7 fw-bold text-gray-500 border-bottom-0">
                  <th class="">商品信息</th>
                  <th class="pe-0 text-center min-w-100px">市场价</th>
                  <th class="pe-0 text-center min-w-100px">分销价</th>
                  <th class="pe-0 text-center min-w-100px">利润率</th>
                  <th class="pe-0 text-center min-w-100px">商品来源</th>
                  <th class="pe-0 text-center min-w-100px"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(i, index) in item.list" :key="index">
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="javascript:void(0);" class="symbol symbol-50px">
                        <img class="symbol-label lozad" :src="i.imagesUrl"/>
<!--                        <img class="symbol-label lozad" v-if="i.imagesUrl.indexOf('http') == 0"-->
<!--                             :src="`${i.imagesUrl}`"/>-->
<!--                        <img class="symbol-label lozad" v-else :src="`https://www.1c-1g.com${i.imagesUrl}`"/>-->
                      </a>
                      <div class="ms-3">
                        <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-6 fw-bold" style="white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;width: 220px;display: block" :title="i.title"
                           data-kt-ecommerce-product-filter="product_name" @click="openGoodInfo(
                                i.id,
                                i.isJd
                              )">{{ i.title }}</a>
                      </div>
                    </div>
                  </td>
                  <td class="text-center pe-0">
                    <span class="text-gray-800 fw-bold d-block fs-6">{{ i.price | format }}</span>
                  </td>
                  <td class="text-center pe-0">
                    <span class="text-gray-800 fw-bold d-block fs-6">{{ i.disPrice | format }}</span>
                  </td>
                  <td class="text-center pe-0">
                    <span class="text-gray-800 fw-bold d-block fs-6">{{ (i.discount*100).toFixed(2) }}%</span>
                  </td>
                  <td class="text-center pe-0">
                    <span class="text-gray-800 fw-bold d-block fs-6">京东商品</span>
                  </td>
                  <td class="text-center pe-0">
                      <span class="text-gray-800 fw-bold d-block fs-6">
<!--                        上架-->
                      </span>
                  </td>
                </tr>
                </tbody>
                <!--end::Table body-->
              </table>
              <!--end::Table-->
            </div>
            <!--end::Table container-->
          </div>

        </div>
        <!--end::Tab Content-->
      </div>
      <!--end: Card Body-->
    </div>
    <!-- 商品详情 -->
    <ModalDialog title="商品详情" id="goodInfo" ref="goodInfo" width="1000px">
      <GoodInfoComponent ref="goodInfoComponent"></GoodInfoComponent>
    </ModalDialog>
  </div>

</template>
<script>
import GoodInfoComponent from "@/components/GoodInfoComponent/index.vue";
import {getSaasGoodApi, getSaasGoodsInfoApi} from "@/api/home";

export default {
  name: "PlatformRecommendation",
  components: {GoodInfoComponent},
  data() {
    return {
      navList: [
        {
          title: '食品饮料',
          imagesSrc: 'https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/category/lysp.png',
          list: [
            {
              title: '三只松鼠_ _手撕面包 1000g/盒',
              imagesSrc: 'https://img13.360buyimg.com/n0/jfs/t1/96993/25/42360/132817/64af6700F5f2d08b2/37de7f8f969f8742.jpg',
              isJD: 1,
              id: 692597
            },
            {
              title: '三只松鼠_麻辣味蜀香牛肉100g/袋',
              imagesSrc: 'https://img13.360buyimg.com/n0/jfs/t1/124129/25/31908/217132/644740cdF7b7c28fd/1b93ba29e374f7e7.jpg',
            },
            {
              title: '良品铺子 爆浆手造麻薯 传统糕点点心 零食特产小吃 芒果味150g',
              imagesSrc: 'https://img13.360buyimg.com/n0/jfs/t1/96739/18/13417/589921/5e573376Eabfa85c3/4d170bbd97de4e8b.png',
            }
          ]
        }, {
          title: '家庭清洁/纸品',
          imagesSrc: 'https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/category/jtqj.png',
          list: [
            {
              title: '舒洁（Kleenex）抽纸纸巾 小熊印花软抽纸抽 120抽*16包（婴儿可用）',
              imagesSrc: 'https://img13.360buyimg.com/n0/jfs/t1/101098/17/41011/238077/65311b13F243ee005/ce1d9aa356dfb650.png',
            },
            {
              title: '艾逸瑶选原生木浆蓝色梦想300张4包软纸抽AYYX018-4',
              imagesSrc: 'https://img13.360buyimg.com/n0/jfs/t1/147350/30/23610/138620/6232e564E31dc55fe/b835c2367d2b507a.jpg',
            },
            {
              title: '京东京造 75%酒精湿巾独立包装*50片【DK】',
              imagesSrc: 'https://img13.360buyimg.com/n0/jfs/t1/158812/8/4287/94291/6007dcc3E17f8f8ce/558f193d900d1a56.jpg',
            }
          ]
        }
      ]
    };
  },
  methods: {
    openGoodInfo(id, isJD) {
      console.log(isJD)
      this.$refs.goodInfo.open();
      this.$refs.goodInfoComponent.openGoodsInfo(id, isJD);
    },
    async getSaasList() {
      let {data} = await getSaasGoodApi();
      this.navList = []
      data.splice(8)
      data.forEach(async (e) => {
        let {data} = await getSaasGoodsInfoApi({
          categoryId: e.id
        })
        this.navList.push({
          title: e.title,
          imagesSrc: "https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/category/" + e.imgSrc,
          list: data
        })

      })
    },
  },
  mounted() {
    this.getSaasList()
  }
}
</script>