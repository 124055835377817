<template>
    <div>
        <div class="modal-body" data-select2-id="select2-data-34-ygil">
            <!-- 内容开始 -->
            <div class="post d-flex flex-column-fluid" id="kt_post" data-select2-id="select2-data-kt_post">
                <!--begin::Container-->
                <div id="kt_content_container" class="container"
                    style="max-width:100%;flex-direction:row!important;display:flex;"
                    data-select2-id="select2-data-kt_content_container">
                    <!-- 搜索开始 -->
                    <div class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-300px "
                        data-select2-id="select2-data-33-vnph">
                        <!--begin::Form-->
                        <form action="#">
                            <!--begin::Card-->
                            <div class="card">
                                <!--begin::Body-->
                                <div class="card-body">
                                    <!--begin:Search-->
                                    <div class="position-relative hidden">
                                        <!--begin::Svg Icon | path: icons/duotone/General/Search.svg-->
                                        <span
                                            class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path
                                                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                        fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                    <path
                                                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                        fill="#000000" fill-rule="nonzero"></path>
                                                </g>
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->
                                        <input v-model="queryVoucherParam.cardNo" id="queryVoucherCardNo" type="text"
                                            class="form-control form-control-solid ps-10 " name="queryVoucherCardNo"
                                            value="" placeholder="请输入查询的卡号">
                                    </div>
                                    <!--end:Search-->
                                    <!--begin::Border-->
                                    <div class="separator separator-dashed my-8"></div>
                                    <!--end::Border-->

                                    <!--begin::Input group-->
                                    <div class="mb-5">
                                        <label class="fs-6 form-label fw-bolder text-dark">用户手机号</label>
                                        <input v-model="queryVoucherParam.tel" id="queryVoucherTel" type="text" value=""
                                            class="form-control form-control form-control-solid" name="queryVoucherTel">
                                    </div>
                                    <!--end::Input group-->

                                    <!--begin::Input group-->
                                    <div class="mb-5" data-select2-id="select2-data-32-t577">
                                        <label class="fs-6 form-label fw-bolder text-dark">兑换状态</label>
                                        <!--begin::Select-->
                                        <select v-model="queryVoucherParam.status" id="queryVoucherStatus"
                                            class="form-select form-select-solid " data-control="select2"
                                            data-placeholder="全部" data-hide-search="false"
                                            data-select2-id="select2-data-10-cn83" tabindex="-1" aria-hidden="true">
                                            <option value="null" data-select2-id="select2-data-27-xdfx">全部</option>
                                            <option value="2" data-select2-id="select2-data-37-2xg6">已兑换</option>
                                            <option value="1" data-select2-id="select2-data-38-hz91">未兑换</option>
                                            <option value="0" data-select2-id="select2-data-38-hz91">待激活</option>
                                        </select>
                                    </div>
                                    <!--end::Input group-->
                                    <div class="d-flex align-items-center justify-content-end">
                                        <a @click="findVoucherRecord();" href="javasript:void(0)"
                                            class="btn btn-primary">筛选</a>&nbsp;&nbsp;&nbsp; <a
                                            @click="resetVoucherQuery();" href="javascript:void(0)"
                                            class="btn btn-active-light-primary btn-color-gray-400 me-3">重置</a>
                                    </div>
                                    <!--end::Action-->
                                </div>
                                <!--end::Body-->
                            </div>
                            <!--end::Card-->
                        </form>
                        <!--end::Form-->
                    </div>
                    <!-- 列表开始 -->
                    <div class="card mb-5 mb-xl-8" style="margin-left:3px;width:100%;">
                        <!--begin::Header-->
                        <div class="card-header border-0 pt-5">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bolder fs-3 mb-1">卡密列表</span> <span
                                    class="text-muted mt-1 fw-bold fs-7">该商城下所有卡密的管理</span>
                            </h3>
                            <div class="card-toolbar">
                                <a @click="saveShopVoucherVisible = true" href="javascript:void(0);"
                                    class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_addVoucher" style="margin-right:15px; ">
                                    <!--begin::Svg Icon | path: icons/duotone/Communication/Add-user.svg-->
                                    生成新卡密
                                </a> <a href="javascript:void(0);" class="btn btn-sm btn-primary"
                                    @click="exportShopVoucher()" style="margin-right:15px; ">
                                    <!--begin::Svg Icon | path: icons/duotone/Communication/Add-user.svg-->
                                    导出卡密
                                </a> <a href="javascript:void(0);" class="btn btn-sm btn-primary"
                                         style="margin-right:15px;" @click="excelSaveShopVoucherVisible=true">
                                    <!--begin::Svg Icon | path: icons/duotone/Communication/Add-user.svg-->
                                    导入更新
                                </a>

                              <a v-bind:class="{disabled:batchActiveVoucherItemDisabled}" href="javascript:void(0)" class="btn btn-sm btn-primary" style="margin-right:15px;"
                                 @click="batchActiveVoucherItemStatus">
                                批量激活
                              </a>

                                <a href="javascript:void(0)" class="btn btn-sm btn-primary" style="margin-right:15px;"
                                    @click="deleteVoucherAllCode();">
                                    <!--begin::Svg Icon | path: icons/duotone/Communication/Add-user.svg-->
                                    删除卡密
                                </a>

                            </div>
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body py-3">
                            <!--begin::Table container-->
                            <div class="table-responsive">
                                <!--begin::Table-->
                                <div id="voucherTable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div class="table-responsive">
                                      <el-table :data="wxShopVoucherList" ref="voucherTable" @selection-change="handleSelectionChange" empty-text="未查询到数据">
                                        <el-table-column
                                            type="selection" width="50" >
                                        </el-table-column>
                                        <el-table-column
                                            label = "卡号"
                                        >
                                          <template slot-scope="scope">
                                            {{ scope.row.cardNo }}
                                          </template>
                                        </el-table-column>
                                        <el-table-column
                                            label = "卡密"
                                        >
                                          <template slot-scope="scope">
                                            {{ scope.row.cardSecret }}
                                          </template>
                                        </el-table-column>
                                        <el-table-column
                                            label = "面值"
                                        >
                                          <template slot-scope="scope">
                                            {{ scope.row.score }}
                                          </template>
                                        </el-table-column>
                                        <el-table-column
                                            label = "状态"
                                        >
                                          <template slot-scope="scope">
                                            {{ scope.row.statusStr }}
                                          </template>
                                        </el-table-column>
                                        <el-table-column
                                            label = "模板名称"
                                        >
                                          <template slot-scope="scope">
                                            样式{{ scope.row.voucherTemplateId }}
                                          </template>
                                        </el-table-column>
                                        <el-table-column
                                            label = "创建时间"
                                        >
                                          <template slot-scope="scope">
                                            {{ scope.row.crTime }}
                                          </template>
                                        </el-table-column>
                                        <el-table-column
                                            label = "激活时间"
                                        >
                                          <template slot-scope="scope">
                                            {{ scope.row.activeUpdateTime }}

                                          </template>
                                        </el-table-column>
                                        <el-table-column
                                            label = "兑换手机"
                                        >
                                          <template slot-scope="scope">
                                            {{ scope.row.tel }}

                                          </template>
                                        </el-table-column>
                                        <el-table-column
                                            label = "更新时间"
                                        >
                                          <template slot-scope="scope">
                                            {{ scope.row.updateTime }}
                                          </template>
                                        </el-table-column>
                                        <el-table-column
                                            label = "操作"
                                            width="150px"
                                        >
                                          <template slot-scope="scope">
                                            <a v-if="scope.row.status==0" class="btn btn-info btn-sm px-4 mx-sm-1 my-sm-1"
                                               @click="activeVoucherItemStatus(scope.row.id)">&nbsp;激活</a>
                                            <a v-if="scope.row.status!=2 && !scope.row.isECard" class="btn btn-info btn-sm px-4 mx-sm-1 my-sm-1"
                                               @click="updateVoucherScore(scope.row.id)">&nbsp;编辑</a>
                                          </template>
                                        </el-table-column>

                                      </el-table>
                                        <!-- 分页按钮 -->
                                        <el-pagination style="display:flex;justify-content:flex-end"
                                            @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                            :current-page="queryVoucherParam.pageIndex" :page-sizes="[10, 20, 30, 50, 100]"
                                            :page-size="queryVoucherParam.pageSize"
                                            layout="total, sizes, prev, pager, next, jumper" :total="totalCount" background>
                                        </el-pagination>
                                        <!--end::Table body-->
                                        <div id="voucherTable_processing" class="dataTables_processing"
                                            style="display: none;"></div>
                                    </div>

                                </div>
                                <!--end::Table-->
                            </div>
                            <!--end::Table container-->
                        </div>
                        <!--begin::Body-->


                    </div>

                    <el-dialog title="创建卡密" v-if="saveShopVoucherVisible" :visible.sync="saveShopVoucherVisible" width="80%"
                        append-to-body=true>
                        <template #title>
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h2>创建卡密</h2>
                                </div>
                            </div>
                        </template>
                        <createVoucher :currentWxShopId="currentWxShopId" @closePopup="closeVoucherPopup"></createVoucher>
                    </el-dialog>

                  <el-dialog title="批量更新卡密" v-if="excelSaveShopVoucherVisible" :visible.sync="excelSaveShopVoucherVisible"
                             width="40%" append-to-body=true>
                    <template #title>
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">批量导入卡密</h5>
                        </div>
                      </div>
                    </template>
                    <div class="modal-body">
                      <div class="form-group" style="display:flex;align-items:center;">
                        <el-upload
                            class="upload-Excel"
                            ref = "uploadExcel"
                            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            drag
                            action="#"
                            :http-request="uploadVoucherExcel"
                            :auto-upload="false"
                            multiple
                            limit = 1
                        >
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                          <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件</div>
                        </el-upload>
                      </div>
                    </div>

                    <div class="modal-footer">
                      <button type="button" class="btn btn-light" @click="excelSaveShopVoucherVisible=false">关闭</button>
                      <button @click="batchAddShopVoucher" type="button"
                              class="btn btn-primary">提交
                      </button>
                    </div>
                  </el-dialog>

                    <!-- 弹出框 -->
                    <el-dialog title="更新卡密面值" v-if="updateVoucherScoreVisible" :visible.sync="updateVoucherScoreVisible" width="40%"
                        append-to-body=true @close="closeUpdateVoucherScoreDialog" :destroy-on-close="true">
                        <template #title>
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h2>更新卡密面值</h2>
                                </div>
                            </div>
                        </template>

                      <el-form :model="updateVoucherScoreObj" :rules="updateVoucherScoreRule" ref="updateVoucher" label-width="100px" label-position="top">
                        <el-row>
                          <el-col >
                            <el-form-item prop="score">
                                    <span slot="label" style="display:inline-block;">
                                        <label class="form-label fw-bolder text-dark fs-6">卡密积分</label>
                                    </span>
                              <el-input v-model="updateVoucherScoreObj.score"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </el-form>
                         <div class="modal-footer">
                                    <button type="button" class="btn btn-light"
                                        @click="updateVoucherScoreVisible=false">关闭</button>
                                    <button @click="submitUpdateVoucherScore" type="button"
                                        class="btn btn-primary">提交</button>
                          </div>
                        
                    </el-dialog>
                </div>
                <!--end::Container-->

            </div>
            <!-- 内容结束 -->
        </div>
    </div>
</template>


<script>
import { getVoucherItemList, exportVoucher, deleteAllCode,uploadVoucherFromExcel,updateVoucherScore,activeVoucherItem,batchActiveVoucherItem,exportUpdateErrorList } from '@/api/shopVoucherApi.js'
import createVoucher from '@/views/Shop/component/createVoucherModal/createVoucher.vue'
import {batchDeleteByIds} from "@/api/shopGoodsApi";

export default {
    components: {
        createVoucher
    },
    props: {
        currentWxShopId: {
            type: Number,
            default: null,
        }
    },
    data() {
      const checkTel = (rule, value, callback) => {
        const pattern = /^1[3456789]\d{9}$/;
        setTimeout(() => {
          if (!pattern.test(value)) {
            return callback(new Error('输入手机号格式不正确'))
          }
          return callback()
        }, 1000);
      };
      return {
            excelSaveShopVoucherVisible:false,
            saveShopVoucherVisible: false,
            updateVoucherScoreVisible:false,
            batchActiveVoucherItemDisabled:true,
            saveShopUserMode: 0,
            wxShopVoucherList: [],
            queryVoucherParam: {
                cardNo: "",
                tel: "",
                status: null,
                wxShopId: this.currentWxShopId,
                pageIndex: 1,
                pageSize: 10
            },
            totalCount: 0,
            saveShopVoucherForm: {
                voucherTemplated: null,
                wxShopId: this.currentWxShopId,
                title: "",
                num: "",
                score: null,
                type: null,
                staffId: null,
                cardNoPrefix: ""
            },
            multipleSelection:[],
            saveShopUserFormRule: {
                name: [
                    { required: true, message: '请输入用户姓名', trigger: 'blur' },
                ],
                tel: [
                    { required: true, message: '请输入客服手机号', trigger: 'blur' },
                    { validator: checkTel, trigger: 'change' }
                ],

                score: [
                    { required: true, message: '请输入积分', trigger: 'blur' },
                    { type: 'number', message: '积分必须是数字类型', trigger: 'change' }
                ]
            },
            updateVoucherScoreObj:{
              score:null,
              voucherItemId:-1
            },
          updateVoucherScoreRule:{
            score: [
              { required: true, message: '请输入积分', trigger: 'blur' },
              { type: 'number', message: '积分必须是数字类型', trigger: 'change' }
            ]
          }
        }
    },
    methods: {
        async getWxShopVoucherList() {
            console.log(this.queryVoucherParam)
          if(this.queryVoucherParam.status=="null"){
            this.queryVoucherParam.status=null
          }
            let { data } = await getVoucherItemList(this.queryVoucherParam)
            console.log(data)
            this.wxShopVoucherList = data.rows
            this.totalCount = data.count
        },
        findVoucherRecord() {
            this.queryVoucherParam.pageIndex = 1
            this.getWxShopVoucherList()
        },
        resetVoucherQuery(){
          this.queryVoucherParam= {
                cardNo: "",
                tel: "",
                status: null,
                wxShopId: this.currentWxShopId,
                pageIndex: 1,
                pageSize: 10
          }
          this.getWxShopVoucherList()

        },
        closeVoucherPopup(){
          this.saveShopVoucherVisible = false
          this.resetVoucherQuery()
        },

        handleCurrentChange(param) {
            this.queryVoucherParam.pageIndex = param
            this.getWxShopVoucherList();
        },
        handleSizeChange(param) {
            this.queryVoucherParam.pageIndex = 1
            this.queryVoucherParam.pageSize = param
            this.getWxShopVoucherList();
        },
        async exportShopVoucher() {
          const response = await exportVoucher(this.queryVoucherParam);
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
            a.href = url;
            a.download = '卡密导出.xlsx'; // 设置下载的文件名，根据需要修改
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        },
        deleteVoucherAllCode() {
          const that = this;
          this.$confirm('将删除全部还没有兑换的卡密，已经兑换的会保留, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {

            const deleteParam = {wxShopId: this.currentWxShopId};
            deleteAllCode(deleteParam).then((res) => {
                    that.getWxShopVoucherList();
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })

            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });
            });

        },
      updateVoucherScore(id){
        this.updateVoucherScoreVisible = true
        this.updateVoucherScoreObj.voucherItemId = id
      },
      async submitUpdateVoucherScore() {
        let res = await updateVoucherScore(this.updateVoucherScoreObj)
        const that = this;
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '编辑成功!'
          });
          this.updateVoucherScoreVisible = false
          await this.getWxShopVoucherList();

        } else {
          this.$message({
            type: 'error',
            message: '编辑失败!'
          });
        }
      },
      batchAddShopVoucher(){
        this.$refs.uploadExcel.submit();
      },
      async uploadVoucherExcel(event){
        const formData = new FormData();
        if (event) {
          const file = event.file
          formData.append("file", file);
          formData.append("wxShopId",this.currentWxShopId);
        }
        let res = await uploadVoucherFromExcel(formData);
        if(res.code==200){
          let message = "总计："+res.data.total+"其中成功："+res.data.success+",失败:"+res.data.error
          if(res.data.errorId){
            message += `<a style="cursor:pointer;margin-left: 10px;color: #0f55b6;" onclick="downloadErrorList('`+res.data.errorId+`')">点击下载</a>`
          }
          await this.$confirm(message, '提示', {
            confirmButtonText: '确定',
            type: 'info',
            dangerouslyUseHTMLString:true
          })

          await this.getWxShopVoucherList()
          this.excelSaveShopVoucherVisible = false

        }

      },

      closeUpdateVoucherScoreDialog(){
        this.updateVoucherScoreObj.score = null
        this.updateVoucherScoreObj.voucherItemId = -1
      },
      handleSelectionChange(val){
        this.multipleSelection = val;
        console.log(this.multipleSelection)
        if(this.multipleSelection.length>0){
          this.batchActiveVoucherItemDisabled = false
        }else{
          this.batchActiveVoucherItemDisabled = true
        }
      },
      async activeVoucherItemStatus(id){
        let res = await activeVoucherItem({voucherItemId:id})
        if(res.code==200){
          this.$message({
            type: 'success',
            message: '激活成功!'
          });
          await this.getWxShopVoucherList()
        }
      },
      async batchActiveVoucherItemStatus(){
        const that = this;
        this.$confirm('是否批量激活所选卡密?','提示',{
          confirmButtonText:"确定",
          cancelButtonText:"取消",
          type:'warning'
        }).then(async () => {
          let voucherItemIds = []
          for (let i = 0; i < this.multipleSelection.length; i++) {
            voucherItemIds.push(this.multipleSelection[i].id)
          }
          let res = await batchActiveVoucherItem(voucherItemIds);
          if(res.code==200){
            this.$message({
              type: 'success',
              message: '批量激活成功!'
            });
            await this.getWxShopVoucherList()
          }

        }).catch(()=>{
          this.$message({
            type: 'info',
            message: '已取消激活'
          });
        })
      },
      async downloadErrorList(errorId){
        const response = await exportUpdateErrorList({errorId});
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = '批量更新卡密错误列表.xlsx'; // 设置下载的文件名，根据需要修改
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }

    },
    created() {
        console.log(this.currentWxShopId)
        window.downloadErrorList = this.downloadErrorList
        this.getWxShopVoucherList();
    },
    updated() {
        // 当数据更新后，可以在此处手动初始化下拉菜单插件
        // this.rows.forEach((_, index) => {
        //     this.toggleDropdown(index);
        // });
    }

}

</script>


<style scoped>
/* 修改滚动条样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 20px; /* 设置滚动条宽度 */
  height: 10px;
  background-color: #F5F5F5; /* 设置滚动条背景颜色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #999; /* 设置滚动条滑块颜色 */
  border-radius: 5px; /* 设置滚动条滑块圆角 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #777; /* 设置滚动条滑块在鼠标悬停时的颜色 */
}
</style>