import sd from "silly-datetime";
import {getSjGoodsListApi, getSjGoodsListCountApi} from "@/api/goodsManageApi";
import {getChooseGoodsListApi, getChooseGoodsListCountApi} from "@/api/chooseGoodsApi";
import {getCategoryList} from "@/api/categoryApi";
import {checkPptCanDownloadApi, exportGoodsPptApi, getPPTModuleApi} from "@/api/pptApi";
import {mapActions, mapState} from "vuex";

export const mixins = {
    name: "goodsManage",
    //import引入的组件需要注入到对象中才能使用
    data() {
        //这里存放数据
        return {
            pptLoading: false,
            excelLoading: false,
            // ppt模板图片数据集合
            pptModules: [],
            pptModule: {
                id: "",
                pptImg: "",
                pptName: "",
                pptTitle: ""
            },
            // 抽屉
            drawer: false,
            direction: 'ltr',
            // 方法图片
            templatePicUrlVisible: false,
            // ppt商品数据实体
            pptExportProgramme: {
                pptModel: "",
                goodsDisplayModel: 1,
                pptGoodsDto: [{
                    groupBuyPrice: 0,
                    groupGoodsList: [{
                        groupName: "",
                        goodsList: []
                    }]
                }],
                isEnableSupplPrice: true,
                isEnableSalePrice: true,
            },
            imageLOGOSrc: "",
            totalCount1: 0,
            activeName1: 'first',
            loading: false,
            id: 0,
            endTime: "",
            status: 1,
            hidden: true,
            templateList: [],
            dialogTableVisible: false,
            pageIndex: 1,
            pageSize: 12,
            count: 0,
            stepper2Form: this.comboGiftVoucherECardFrom,
            templatePicUrl: '',
            canChooseTemplate: true,
            filList: [],
            choiceIndex: 0,
            comboGiftVoucherECardListParams: {
                current: 1,
                size: 9,
                title: "",
                startCreateTime: "",
                endCreateTime: "",
                type: 1,
                time: []
            },
            imgSrc: "",
            dialogVisible: false,
            /* 弹窗实体 */
            // 请求参数
            paramss: {
                isTwoShow: 1,
                telNum: "",
                title: "",
                type: "1",
                categoryId1: "",
                categoryId2: "",
                size: 10,
                current: 1,
                isJd: 2,
                maxPrice: "",
                minPrice: "",
                minRightRate: "",
                maxRightRate: "",
                minSupplPrice: "",
                maxSupplPrice: "",
                minQuanRightRate: "",
                maxQuanRightRate: "",
                minPoolSupplPrice: "",
                maxPoolSupplPrice: "",
                maxStock: "",
                minStock: "",
            },
            idArr: [],
            categoryId1: [],
            categoryId2: [],
            selGoodsList: [],
            goodsListDialog: false,
            totalCount: 999,
            hidenCategory: true,
            goodsListHiden: true,
            // 商品池
            optionss: [
                {
                    value: '1',
                    label: '自有商品池'
                },
                {
                    value: '2',
                    label: '全国商品池'
                }
            ],
            // 商品来源
            jdType: [
                {
                    type: 1,
                    name: "京东商品"
                },
                {
                    type: 2,
                    name: "厂供商品"
                }
            ],
            openExportTool: false,
        };
    },
    //监听属性 类似于data概念
    computed: {
        //goodsIds() {
        //    return this.goodList.map(item => item.id);
        //},
        ...mapState("goods", ["pptGoodsList"]),
        // ...mapState("user", ["role"]),
        // 根据isJd将不同id放进不同数组 先过滤 再分组
        idsArr() {
            return this.pptGoodsList.filter(e => e.isJD == this.paramss.isJd).map(e => e.id);
        },
    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        // 打开导出工具弹窗
        exportTool() {
            this.$refs.exportTool.open();
        },
        // execl或者ppt等弹窗打开或则和导出到excel
        exportData(index, type) {
            if (type === 2 && index === 0) {
                this.$refs.exportTool.close()
                this.setPPTGoodsList([]);
            }
            // ppt
            if (type === 2 && index === 2) {
                this.$refs.pptExportProgramme.close()
                this.setPPTGoodsList([]);
            } else if (type === 1 && index === 2) {
                this.$refs.pptExportProgramme.open();
                this.setPPTGoodsList([]);
            }
            // 导出excel数据
            if (type === 1 && index === 1) {
                this.excelLoading = true
                this.$refs.OwnGoods.exp();
                this.excelLoading = false
            }
            // pdf
            if (type === 1 && index === 3) {
                this.$swal.fire({
                    icon: "warning",
                    title: "功能暂未开放",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
            // 挑选商品
            if (type === 1 && index === 4) {
                this.$refs.goodsListDialog.open();
                this.initCategory()
                this.resetBtn();
            }
        },
        /* 弹窗方法 */
        ...mapActions("goods", ["setPPTGoodsList"]),
        // 打开弹窗
        addGoods() {
            if (this.pptExportProgramme.goodsDisplayModel == 1 && !this.pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList) {
                this.setPPTGoodsList([]);
            }
            this.$refs.goodsListDialog.open();
            this.initCategory()
            this.resetBtn();
        },
        // 数据筛选
        shaixuan() {
            this.first = true;
            this.findGoodsList()
        },
        // 筛选数据清空
        resetBtn() {
            this.first = true;
            this.paramss.isTwoShow = ""
            if (this.paramss.isJd == 2) {
                this.paramss.isTwoShow = 1
            }
            this.paramss = {
                telNum: "",
                title: "",
                isTwoShow: this.paramss.isTwoShow,
                type: this.paramss.type,
                categoryId1: "",
                categoryId2: "",
                size: 10,
                current: 1,
                isJd: this.paramss.isJd,
                maxPrice: "",
                minPrice: "",
                minRightRate: "",
                maxRightRate: "",
                minSupplPrice: "",
                maxSupplPrice: "",
                minQuanRightRate: "",
                maxQuanRightRate: "",
                minPoolSupplPrice: "",
                maxPoolSupplPrice: "",
                maxStock: "",
                minStock: "",
            }
            this.categoryId2 = []
            this.$refs.Pagination.goToPage(1)
            this.findGoodsList()
        },
        // 初始化分类
        async initCategory() {
            let {data} = await getCategoryList({
                parentId: 0
            })
            this.categoryId1 = data.list
        },
        // 查询分类数据
        async getCategory(item) {
            this.first = true;
            if (item === 1) {
                this.paramss.categoryId2 = ""
                let parentId = this.paramss.categoryId1
                let {data} = await getCategoryList({
                    parentId
                })
                this.categoryId2 = data.list
            }
            this.findGoodsList()
        },
        // 查询商品数据
        async findGoodsList() {
            if (this.paramss.minStock || this.paramss.maxStock
                || this.minRightRate || this.paramss.maxRightRate) {
                this.paramss.maxPoolSupplPrice = ""
                this.paramss.minPoolSupplPrice = ""
                this.paramss.maxQuanRightRate = ""
                this.paramss.minQuanRightRate = ""
            }
            if (this.paramss.maxPoolSupplPrice || this.paramss.minPoolSupplPrice ||
                this.paramss.maxQuanRightRate || this.paramss.minQuanRightRate) {
                this.paramss.minStock = ""
                this.paramss.maxStock = ""
                this.paramss.minRightRate = ""
                this.paramss.maxRightRate = ""
            }
            if (this.paramss.maxQuanRightRate) {
                this.paramss.maxQuanRightRate = (this.paramss.maxQuanRightRate / 100).toFixed(4)
            }
            if (this.paramss.minQuanRightRate) {
                this.paramss.minQuanRightRate = (this.paramss.minQuanRightRate / 100).toFixed(4)
            }

            if (this.paramss.type == 1) {
                this.paramss.isJd = 2;
                this.loading = true;
                this.paramss.isTwoShow = 1;
                this.paramss.isShow = 1;
                let {data} = await getSjGoodsListApi(this.paramss)
                this.selGoodsList = data.rows;
                this.loading = false;
                if (this.first) {
                    let {data} = await getSjGoodsListCountApi(this.paramss)
                    this.totalCount = data
                }
            } else {
                this.loading = true;
                let json = JSON.parse(JSON.stringify(this.paramss))
                json.sTitle = json.title;
                delete json.title;
                let {data} = await getChooseGoodsListApi(json)
                this.selGoodsList = data.rows;
                this.loading = false;
                if (this.first) {
                    let {data} = await getChooseGoodsListCountApi(json)
                    this.totalCount = data
                }
            }
            this.first = false
        },
        // 商品分页
        pageChange(currentPage, pageSize) {
            this.paramss.current = currentPage;
            this.paramss.size = pageSize;
            this.findGoodsList();
        },
        // 添加商品到组合礼包
        async addGroupGoods(item) {
            item.showPrice = "";
            item.sort = "";
            // 将item对象添加进vuex中
            this.pptGoodsList.push(item);
            if (this.pptExportProgramme.goodsDisplayModel == 1) {
                this.pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList = JSON.parse(JSON.stringify(this.pptGoodsList))
            }
        },
        // 删除商品
        delGroupGoods(item) {
            let arr = []
            if (this.pptGoodsList) {
                this.pptGoodsList.forEach(e => {
                    !(e.id === item.id && e.isJd === item.isJd) && arr.push(e)
                })
            }
            this.setPPTGoodsList(arr);
            if (this.pptExportProgramme.goodsDisplayModel == 1) {
                if (this.pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList) {
                    let index = this.pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList.findIndex(e => e.id == item.id && e.isJd == item.isJd)
                    if (index !== -1) {
                        this.pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList.splice(index, 1);
                    }
                }
            }
        },
        clearSelectedGoods() {
            this.goodsListHiden = false;
            this.setPPTGoodsList([]);
            if (this.pptExportProgramme.goodsDisplayModel == 1) {
                this.pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList = []
            }
            this.goodsListHiden = true;
        },
        //移除图片 不删除oss 但要删除数据库
        removeImages(item, index) {
            if (this.pptExportProgramme.goodsDisplayModel == 1) {
                this.pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList.splice(index, 1);
            }
        },
        async clickPPT() {

            this.pptLoading = true;
            await this.dataExportPPT();
            this.pptLoading = false;
        },
        // 将商品数据导出到ppt
        async dataExportPPT() {
            if (this.role.includes("vipMember")) {
                try {
                    await checkPptCanDownloadApi()
                } catch (e) {
                    if (e.code === 700009) {
                        this.$confirm('今日方案输出次数已达上限，是否增购？（10元/次）\n' +
                            '说明：加购次数不受时间影响', '提示', {
                            confirmButtonText: '增购',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.$refs.VipPayComponent.openPay({
                                type: 1,
                                rerunUrl: process.env.NODE_ENV === "development" ? "http://localhost:8080/#/payCallback" : "https://saas.zhelidao.com/#/payCallback"

                            })
                        }).catch(() => {

                        });
                        return;
                    }
                }
            }

            // if (this.pptExportProgramme.goodsDisplayModel == 2) {
            //     this.$message.warning("礼包详情的商品表格排版暂未开放！")
            //     return
            // }

            if (!this.pptModule) {
                this.$message.warning("请选择ppt模板")
                return
            }
            if (this.pptExportProgramme.goodsDisplayModel == 1 && this.pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList.length < 1) {
                this.$message.warning("请选择商品")
                return
            }
            // 模板名称赋值
            this.pptExportProgramme.pptModel = this.pptModule.pptName;
            if (this.pptExportProgramme.goodsDisplayModel == 1) {
                let arr = this.pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList;
                // 对数据进行处理
                arr = arr.map(item => {
                    return {
                        id: item.id,
                        isJD: item.isJD,
                        number: 1
                    };
                });
            }
            var formData = this.getFormData(this.pptExportProgramme);
            if (this.imageLOGOSrc != "") {
                formData.append("file", this.$refs.avatarLOGOFile.files[0])
            }

            this.$refs.pptExportProgramme.close()


            let data = await exportGoodsPptApi(formData)
            //let blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.presentationml.presentation"});
            let blob = new Blob([data]);
            //let blob = new Blob([data], {type: "application/octet-stream"});
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = `商品方案-${sd.format(new Date(), "YYYY-MM-DD_HHmmss")}.ppt`; // 设置下载的文件名，根据需要修改
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

        },
        // 放大图片
        view(picUrl) {
            if (picUrl === 1) {
                this.templatePicUrl = '/images/Goods_Detail.png'
            }
            if (picUrl === 2) {
                this.templatePicUrl = '/images/Gift_Pack_Detail.png'
            }
            if (picUrl === 3) {
                this.templatePicUrl = this.pptModule.pptImg
            }
            this.templatePicUrlVisible = true
        },

        // 打开抽屉并请求数据
        async openDraweAndQuery() {

            this.drawer = true;
            let {data} = await getPPTModuleApi();
            this.pptModules = data;
        },
        // 选择ppt模板
        changePPTModule() {
            if (this.pptModule) {
                this.drawer = false;
            }
        },
        /**
         * 上传图片
         *
         * @param type 表单中具体的上传框
         */
        uploadPic(type) {
            const fileInput = this.$refs[type];
            if (fileInput.files.length > 0) {
                const selectedFile = fileInput.files[0];
                // 使用FileReader读取上传的文件
                const reader = new FileReader();

                reader.onload = () => {
                    // 将文件转换为Base64格式
                    if (type == "avatarFile") {
                        this.imageSrc = reader.result;
                    } else {
                        this.imageLOGOSrc = reader.result;
                    }
                };

                reader.readAsDataURL(selectedFile);
            }
        },

        getFormData(object) {
            const formData = new FormData();
            Object.keys(object).forEach(key => {
                const value = object[key];
                if (Array.isArray(value)) {
                    value.forEach((subValue, i) => {
                        if (typeof subValue === 'object' && subValue !== null) {
                            // 处理嵌套的对象
                            const subFormData = this.getFormData(subValue);
                            subFormData.forEach((subFormDataValue, subFormDataKey) => {
                                formData.append(key + `[${i}].${subFormDataKey}`, subFormDataValue);
                            });
                        } else {
                            formData.append(key + `[${i}]`, subValue);
                        }
                    });
                } else if (typeof value === 'object' && value !== null) {
                    // 处理嵌套的对象
                    const subFormData = this.getFormData(value);
                    subFormData.forEach((subFormDataValue, subFormDataKey) => {
                        formData.append(key + '.' + subFormDataKey, subFormDataValue);
                    });
                } else {
                    formData.append(key, value);
                }
            });
            return formData;
        }


    },

    beforeCreate() {
    }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.openExportTool = this.$route.query.openExportTool;
        if (this.openExportTool) {
            this.$nextTick(() => {
                this.exportTool();
            });
        }
    },
    beforeMount() {
    }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() {
    }, //生命周期 - 更新之前
    updated() {
    }, //生命周期 - 更新之后
    beforeDestroy() {
    }, //生命周期 - 销毁之前
    destroyed() {
    }, //生命周期 - 销毁完成
    activated() {
    }, //如果页面有keep-alive缓存功能，这个函数会触发
}
