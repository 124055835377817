<template>
  <div>
    <div ref="chartContainer" style="width: 100%; height: 400px"></div>
  </div>
</template>
  <script>
import echarts from "echarts";

export default {
  name: "salePricEcharts",
  data() {
    return {
      data: [],
      chartOptions: {
        title: {
          left: "3%",
          text: "销售额统计",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255, 255, 255, 0.8)", // 提示框背景颜色
          borderColor: "#333", // 提示框边框颜色
          textStyle: {
            color: "#000", // 提示框文字颜色
          },
          formatter: function (params) {
            // let content = params[0].name + "<br>"; // X 轴刻度值
            let content = ""; // X 轴刻度值
            params.forEach(function (item) {
              content += item.seriesName + ": " + item.value + "元" + "<br>";
            });

            return content;
          },
        },
        legend: {
          data: ["供应商商品", "自营商品"],
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "0%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["12-07", "12-08", "12-09", "12-10", "12-11", "12-12", "12-13"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "供应商商品",
            type: "line",
            stack: "Total",
            data: [120, 132, 101, 134, 90, 230, 210],
            itemStyle: {
              color: "rgb(209,38,16)",
            },
          },
          {
            name: "自营商品",
            type: "line",
            stack: "Total",
            data: [220, 182, 191, 234, 290, 330, 310],
            itemStyle: {
              color: "rgb(55,183,243)",
            },
          },
        ],
      },
    };
  },
  mounted() {
    // 在组件挂载后初始化图表

    let base = +new Date(1988, 9, 3);
    let oneDay = 24 * 3600 * 1000;
    let data = [[base, Math.random() * 300]];
    for (let i = 1; i < 20000; i++) {
      let now = new Date((base += oneDay));
      data.push([
        +now,
        Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
      ]);
    }
    this.data = data;
    this.initChart();
  },
  methods: {
    initChart() {
      // 获取图表容器
      let chartContainer = this.$refs.chartContainer;
      // 初始化图表
      let myChart = echarts.init(chartContainer);

      // 使用 setOption 方法设置图表配置和数据
      myChart.setOption(this.chartOptions);
    },
  },
};
</script>