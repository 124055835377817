<template>
  <div>
    <!--顶部标题-->
    <div>
      <div class="toolbar"
        style="background-color: #fff; padding: 0.5rem;border-radius: 15px;margin: 10px auto; max-width:100%;">
        <div id="kt_toolbar_container1" class="container-fluid d-flex flex-stack" style="padding: 0 30px">
          <div class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0" data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container1'}">
            <h1 :style="`font-size: 1.17rem !important`" class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              提现记录
            </h1>
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">Cash Out Record</li>
            </ul>
          </div>

        </div>

      </div>
    </div>
    <!--  下方卡片  -->
    <!-- 列表开始 -->
    <div v-loading="loading" class="card mb-5 mb-xl-8" element-loading-background="rgba(0, 0, 0, 0.8)"
      element-loading-spinner="el-icon-loading" element-loading-text="拼命加载中" style="margin-left:3px;width:100%;">
      <div class="card-body py-3">
        <div class="table-responsive">
          <table id="kt_ecommerce_products_table"
            class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
            <thead>
              <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">

                <th aria-controls="withdrawal_amount" class="min-w-50px" style="width: 120px; " tabindex="0">提现金额
                </th>
                <th aria-label="withdrawal_time" class=" min-w-50px" style="width: 120px; " tabindex="0">提现时间
                </th>
                <th aria-label="order_quantity" class=" min-w-50px" tabindex="0">订单数量
                </th>
                <th aria-label="settlement_date" class=" min-w-50px" tabindex="0">结算日期
                </th>
                <th aria-label="received_or_not" class=" min-w-50px" tabindex="0">是否到账
                </th>
                <th aria-label="withdrawal_method" class=" min-w-50px" tabindex="0">提现方式
                </th>
                <th aria-label="collection_account" class=" min-w-50px" tabindex="0">收款账号
                </th>
                <th aria-label="name_of_payee" class=" min-w-50px" tabindex="0">收款人姓名
                </th>
                <th aria-label="opening_bank" class=" min-w-50px" tabindex="0">开户行
                </th>
                <th aria-label="account_type" class=" min-w-50px" tabindex="0">账号类型
                </th>
                <th aria-label="invoicing_method" class=" min-w-50px" tabindex="0">开票方式
                </th>
                <th aria-label="express_delivery_company" class=" min-w-50px" tabindex="0">快递公司
                </th>
                <th aria-label="express_tracking_number" class=" min-w-50px" tabindex="0">快递单号
                </th>
                <th style="text-align: center;">操作
                </th>
              </tr>
            </thead>
            <tbody class="fw-semibold text-gray-600">
              <tr v-for="(item, index) in cashOutList" :key="index">
                <!-- 提现金额 -->
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center">{{ item.money | format }} </span>
                  </div>
                </td>
                <!--提现时间-->
                <td class=" pe-0">
                  <span class="fw-light">{{ item.crTime }} </span>
                </td>
                <!-- 订单数量 -->
                <td class=" pe-0">
                  <span class="fw-light">{{ item.orderNum }}</span>
                </td>
                <!-- 结算日期 -->
                <td class=" pe-0">
                  <span class="">{{ item.overTime }}</span>
                </td>
                <!-- 是否到账 -->
                <td class=" pe-0">
                  <span class="">{{ item.isState }}</span>
                </td>
                <!-- 提现方式 -->
                <td class=" pe-0">
                  <span class="">{{ item.type }}</span>
                </td>
                <!-- 收款账号 -->
                <td class=" pe-0">
                  <span v-if="item.type != '微信'" class="">{{ item.content }}</span>
                  <img :src="item.content" alt="" v-else width="50px">
                </td>
                <!-- 收款人姓名 -->
                <td class=" pe-0">
                  <span class="">{{ item.name }}</span>
                </td>
                <!-- 开户行 -->
                <td class=" pe-0">
                  <span class="">{{ item.bankName }}</span>
                </td>
                <!-- 账号类型 -->
                <td class=" pe-0">
                  <span class="">{{ item.isBank }}</span>
                </td>
                <!-- 开票方式 -->
                <td class=" pe-0">
                  <span class="">{{ item.fpTpe }}</span>
                </td>
                <!-- 快递公司 -->
                <td class=" pe-0">
                  <span class="">{{ item.expressName }}</span>
                </td>
                <!-- 快递单号 -->
                <td class=" pe-0">
                  <span class="">{{ item.expressNo }}</span>
                </td>
                <td style="text-align: center;">
                  <el-button size="small" type="primary" @click="getInfo(item.id)">查看详情</el-button>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination :total-items="totalCount" @page-change="pageChange"></Pagination>
        </div>
      </div>
    </div>
    <el-dialog title="提现详情" :visible.sync="dialogFormVisible">
      <el-table :data="txList" style="width: 100%">
        <el-table-column prop="companyName" label="增值税抬头">
          <template slot-scope="scope">
            <el-popover v-if="scope.row.money != 0" placement="top-start" trigger="hover">
              <p class="fw-bold">发票信息</p>
              <p>发票抬头: {{ scope.row.companyName }}</p>
              <p>发票税号: {{ scope.row.taxID }}</p>
              <p>开户行及账号: {{ scope.row.openingBankAndAccountNumber }}</p>
              <p>开户行地址: {{ scope.row.bankAddress }}</p>
              <p>收票地址: {{ scope.row.ticketReceivingAddress }}</p>
              <p>收票邮箱: {{ scope.row.ticketReceivingEmail }}</p>
              <div slot="reference" class="name-wrapper">
                {{ scope.row.companyName }} <i class="el-icon-question"></i>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="ordersNos" label="订单数">
          <template slot-scope="scope">
            {{ scope.row.ordersNos.length }}
          </template>
        </el-table-column>
        <el-table-column prop="money" label="金额">
          <template slot-scope="scope">
            {{ scope.row.money | format }}
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCashOutListApi, getCashOutOrderInfoByIdApi } from "@/api/sjCashOutApi";
import { formatMoney } from "@/utils/formatMoney";

export default {
  name: "CashOutList",
  data() {
    return {
      dialogFormVisible:false,
      loading: false,
      cashOutList: [],
      totalCount: 10,
      params: {
        iDisplayStart: 1,
        iDisplayLength: 10,
        sEcho: "",
      },
      txList: []
    };
  },
  methods: {
    async getInvoiceList() {
      this.loading = true
      let { data } = await getCashOutListApi(this.params);
      this.cashOutList = data.data;
      this.totalCount = data.total
      this.loading = false
    },
    pageChange(currentPage, pageSize) {
      this.params.iDisplayStart = currentPage;
      this.params.iDisplayLength = pageSize;
      this.getInvoiceList();
    },
    async getInfo(id) {
      let { data } = await getCashOutOrderInfoByIdApi({
        id
      })
      this.txList = data.filter(e=>{
        return e.money!=0
      })
      this.dialogFormVisible=true
    }
  },
  filters: {
    format(num) {
      return formatMoney(num)
    }
  },
  created() {
    this.getInvoiceList();
  }
}
</script>

<style scoped></style>