import {
    deleteAllItemApi, deleteAllWxShopItemApi,
    generateKeyApi, generateWxShopKeyApi,
    getECardListApi,
    getWxShopECardVoucherItemApi,
    updateBatchKeyApi, updateBatchWxShopKeyApi
} from "@/api/eCardApi";
import {keyType, keyStatus} from "@/utils/keyType";
import sd, {format} from "silly-datetime"

export const eCardList = {
    name: "eCardList",
    props: {
        id: {
            type: String,
            default: ""
        },
        endTime: {
            type: String,
            default: ""
        },
        type: {
            type: Number,
        }
    },
    filters: {
        keyTypeFilters(val) {
            const type = keyType.find(item => item.value === val);
            return type ? type.label : '';
        },
        keyStatusFilters(val) {
            const type = keyStatus.find(item => item.value === val);
            return type ? type.label : '';
        },
        keyStatusTypeFilters(val) {
            const type = keyStatus.find(item => item.value === val);
            return type ? type.type : '';
        }
    },
    data() {
        var checkNum = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('生成数量不能为空'));
            }
            if (!Number.isInteger(value)) {
                callback(new Error('请输入数字值'));
            } else {
                if (value > 30000) {
                    callback(new Error('最大生成数不能超过30,000'));
                } else {
                    callback();
                }
            }
        };
        var checkCardNo = (rule, value, callback) => {
            if (!(this.updateObj.startCardNo.trim() && this.updateObj.endCardNo.trim())) {
                return callback(new Error('请正确填写区间'));
            }
            callback();
        };
        return {
            idArr: [],
            multipleSelection: [],
            generateParamsRules: {
                num: [
                    {required: true, message: '请填写生成数量', trigger: 'blur'},
                    {type: 'number', message: '生成数量必须为数字值'},
                    {validator: checkNum, trigger: 'blur'}
                ]
            },
            generateParams: {
                num: "",
                comboVoucherId: this.id,
                cardNoPrefix: "",
                endTime: this.endTime
            },
            dialogVisible: false,
            isMake: [
                {
                    label: "请选择",
                    value: ""
                },
                {
                    label: "是",
                    value: true
                },
                {
                    label: "否",
                    value: false
                }
            ],
            activeUpdateTime: [],
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            crTime: [],
            keyStatus,
            params: {
                comboVoucherId: this.id,
                size: 10,
                current: 1,
                total: 0,
                cardNo: "",
                cardNoPrefix: "",
                cardSecret: "",
                status: "",
                tel: "",
                isMake: "",
                startCreateTime: "",
                endCreateTime: "",
                startActiveUpdateTime: "",
                endActiveUpdateTime: ""
            },
            rows: [],
            loading: false,
            checkboxInput: false,
            dialogVisible2: false,
            updateObj: {
                ids: [],
                status: "",
                type: "0",
                cardNoPrefix: "",
                startCardNo: "",
                endCardNo: "",
                startActiveUpdateTime: "",
                endActiveUpdateTime: "",
                time: []
            },
            statusOption:[
                {
                    label:"激活",
                    key:"1"
                },
                {
                    label:"失效",
                    key:"3"
                }
            ],
            updateObjRules: {
                status: [
                    {required: true, message: '请选择操作类型', trigger: 'change'},
                ],
                startCardNo: [
                    {required: true, message: '请填写卡号区间', trigger: 'change'},
                    {validator: checkCardNo, trigger: 'change'},
                ],
                cardNoPrefix: [
                    {required: true, message: "请输入卡号前缀", trigger: 'change'},
                ],
                time: [
                    {required: true, message: "请输入激活时间区间", trigger: 'change'},
                ],
            }
        }
    },
    methods: {
        groupType(){
            if (this.updateObj.type==2){
                this.statusOption=[
                    {
                        label:"失效",
                        key:"3"
                    }
                ]
                this.updateObj.status="3"

            }else{
                this.statusOption=[
                    {
                        label:"激活",
                        key:"1"
                    },
                    {
                        label:"失效",
                        key:"3"
                    }
                ]
            }
        },
        openNotify(){
            this.$message({
                type: 'warning',
                message: '一键制作实体卡即将开放'
            });
        },
        async deleteAllItem() {
            this.$confirm('此操作将永久删除未使用卡密, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                if (this.type == 2) {
                    let {data} = await deleteAllItemApi({
                        id: this.id
                    });
                } else {
                    let {data} = await deleteAllWxShopItemApi({
                        id: this.id
                    });
                }

                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                this.getCardList()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        async updateKey(status) {
            let obj = {}
            obj.status = this.updateObj.status;
            if (this.updateObj.type == 0) {
                obj.startCardNo = this.updateObj.startCardNo;
                obj.endCardNo = this.updateObj.endCardNo;
            }

            if (this.updateObj.type == 1) {
                obj.cardNoPrefix = this.updateObj.cardNoPrefix
            }

            if (this.updateObj.type == 2) {
                obj.startActiveUpdateTime = this.updateObj.time[0]
                obj.endActiveUpdateTime = this.updateObj.time[1]
            }
            if (status) {
                if (this.type == 2) {
                    await updateBatchKeyApi({
                        comboVoucherId:this.id,
                        ids: this.idArr,
                        status
                    })
                } else {
                    await updateBatchWxShopKeyApi({
                        voucherId: this.id,
                        ids: this.idArr,
                        status,
                        type:2
                    })
                }
                this.$message({
                    message: '操作成功',
                    type: 'success'
                });
                this.getCardList()
                this.idArr = []
                this.checkboxInput = false;
                return;
            }
            for (const key in obj) {
                if (obj[key] === "") {
                    this.$message({
                        message: '请正确填写数据',
                        type: 'warning'
                    });
                    return;
                }
            }
            this.$message.closeAll();
            if (this.type !== 2) {
                obj.type=2;
                obj.voucherId=this.id
                await updateBatchWxShopKeyApi(obj)
            }else{
                obj.comboVoucherId=this.id
                await updateBatchKeyApi(obj)
            }
            this.$message({
                message: '操作成功',
                type: 'success'
            });
            this.dialogVisible2 = false;
            this.getCardList()
        },
        checkboxInputEvent() {

            if (this.checkboxInput) {
                this.rows.forEach((e) => {
                    if (!this.idArr.includes(e.id)) {
                        this.idArr.push(e.id);
                    }
                });
            } else {
                this.rows.forEach((e) => {
                    let index = this.idArr.indexOf(e.id);
                    if (index !== -1) {
                        this.idArr.splice(index, 1);
                    }
                });
            }
        },
        addIdArr(id) {
            if (this.idArr.includes(id)) {
                let index = this.idArr.indexOf(id);
                if (index !== -1) {
                    this.idArr.splice(index, 1);
                }
            } else {
                this.idArr.push(id);
            }
            let a = true;
            this.rows.forEach((e) => {
                if (!this.idArr.includes(e.id)) {
                    a = false;
                }
            });
            this.checkboxInput = a;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        postCreateKey() {
            //
            this.$refs.generateParams.validate(async (valid) => {
                if (valid) {
                    this.loading = true;
                    try {
                        let obj = JSON.parse(JSON.stringify(this.generateParams))
                        obj.endTime = sd.format(obj.endTime)
                        if (this.type == 2) {
                            await generateKeyApi(obj);
                        } else {
                            obj.voucherId = this.id;
                            obj.type = 2;
                            await generateWxShopKeyApi(obj);
                        }
                        this.loading = false;
                        this.dialogVisible = false;
                        this.$message({
                            message: '卡密生成成功!',
                            type: 'success',
                        });
                        setTimeout(() => {
                            this.getCardList();
                        }, 1000)
                    } catch (e) {
                        this.loading = false;
                    }
                } else {
                    return false;
                }
            });
        },
        createKey() {
            this.generateParams = {
                num: "",
                comboVoucherId: this.id,
                cardNoPrefix: "",
                endTime: this.endTime
            }
                this.dialogVisible = true;
        },
        reloadCardList() {
            this.params = {
                comboVoucherId: this.id,
                size: 10,
                current: 1,
                total: 0,
                cardNo: "",
                cardNoPrefix: "",
                cardSecret: "",
                status: "",
                tel: "",
                isMake: "",
                startCreateTime: "",
                endCreateTime: "",
                startActiveUpdateTime: "",
                endActiveUpdateTime: ""
            }
            this.activeUpdateTime = []
            this.crTime = []
            this.getCardList();
        },
        queryCardList() {
            if (this.activeUpdateTime.length > 0) {
                this.params.startActiveUpdateTime = this.activeUpdateTime[0]
                this.params.endActiveUpdateTime = this.activeUpdateTime[1]
            }
            if (this.crTime.length > 0) {
                this.params.startCreateTime = this.crTime[0]
                this.params.endCreateTime = this.crTime[1]
            }
            this.getCardList();
        },
        async getCardList() {
            this.loading = true;
            let data;
            if (this.type == 2) {
                let {data: res} = await getECardListApi(this.params)
                data = res;
            } else {
                this.params.voucherId = this.id;
                let {data: res} = await getWxShopECardVoucherItemApi(this.params)
                console.log(res)
                data = res;
            }
            this.rows = data.rows
            this.params.total = data.total
            let a = true;
            this.rows.forEach((e) => {
                if (!this.idArr.includes(e.id)) {
                    a = false;
                }
            });
            this.checkboxInput = a;
            this.loading = false;
        },
        handleCurrentChange(param) {
            this.params.current = param
            this.getCardList();
        },
        handleSizeChange(param) {
            this.params.current = 1
            this.params.size = param
            this.getCardList();
        },
    },
    mounted() {
        this.getCardList()
    }
}