<template>
  <div>
    <NavComponent />
    <!-- <keep-alive> -->
      <router-view></router-view>
    <!-- </keep-alive> -->
    <FooterComponent />
    <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
      <i class="ki-outline ki-arrow-up"></i>
    </div>
  </div>
</template>
  
<script>
import { mapActions, mapState } from "vuex";
import NavComponent from "@/components/NavComponent";
import FooterComponent from "@/components/FooterComponent";
export default {
  components: {
    NavComponent,
    FooterComponent,
  },
  computed: {
    ...mapState("user", {
      permissionList: (state) => state.permissionList,
    }),
  },
  methods: {
    ...mapActions("user", ["getUserPermission", "getUserInfo"]),
  },

  created() {
    this.getUserInfo();
  },
};
</script>
  