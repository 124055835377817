<template>
  <div id="kt_app_header" style="height: 80px !important" class="app-header" data-kt-sticky="true"
    data-kt-sticky-activate="{default: false, lg: true}" data-kt-sticky-name="app-header-sticky"
    data-kt-sticky-offset="{default: false, lg: '50px'}">
    <div class="app-container container-xxl d-flex align-items-stretch justify-content-between"
      id="kt_app_header_container" style="max-width: 100%">
      <div class="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu">
        <div class="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_header_menu_toggle">
          <i class="ki-outline ki-abstract-14 fs-2"></i>
        </div>
      </div>
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-1 me-lg-13">
        <router-link to="/">
          <img alt="Logo" src="/logo.png" class="h-20px h-lg-30px" />
        </router-link>
      </div>
      <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
        <div class="d-flex align-items-stretch" id="kt_app_header_menu_wrapper">
          <div class="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true"
            data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_menu_wrapper'}">
            <div style="min-width: 700px; justify-content: space-around"
              class="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-600 menu-state-dark menu-arrow-gray-400 fw-semibold fw-semibold fs-6 align-items-stretch my-5 my-lg-0 px-2 px-lg-0"
              id="#kt_app_header_menu" data-kt-menu="true">
              <navicon v-for="(item, index) in navList" :key="index" :item="item"></navicon>
            </div>
          </div>
        </div>
        <!-- 用户详情-->
        <UserComponent></UserComponent>
      </div>
    </div>
  </div>
</template>
<script>
import UserComponent from "./components/UserComponent";
import Navicon from "./components/Navicon";
import { mapState } from "vuex";
import router from "@/router";
import Log from "echarts/src/scale/Log";
export default {
  name: "NavComponent",
  data() {
    return {
      navList: [],
      tempList: [],
    };
  },
  methods: {
    hasCommonElements(array1, array2) {
      //   if (!array1) {
      //     if (this.$route.path !== "/") {
      //       this.$router.push({ name: "Dashboard" });
      //     }
      //     return false;
      //   }
      return array1.filter((item) => array2.includes(item)).length > 0;
    },
    initNav() {
      const permissions = JSON.parse(localStorage.getItem("permissions")) || [];
      this.$store.commit("user/setPermissionList", permissions);
      this.navList = [];
      this.routes.forEach((element) => {
        let obj = {};
        obj.title = element.title;
        obj.subTitle = element.subTitle;
        obj.icon = element.icon;
        obj.path = [];
        obj.path.push(element.path);
        if (element.children && element.children.length > 0) {

          obj.children = [];
          element.children.forEach((e) => {
            let a = {};
            a.title = e.title;
            a.subTitle = e.subTitle;
            a.icon = e.icon;
            if (this.hasCommonElements(e.role, permissions)) {
              if (e.path != "") {
                a.path = element.path + "/" + e.path;
              } else {
                a.path = element.path;
              }
              if (e.children && e.children.length > 0) {
                a.children = [];
                e.children.forEach((el) => {
                  let b={}
                  if (this.hasCommonElements(el.role, permissions)) {
                    if (el.path != "") {
                      b.path = a.path + "/" + el.path;
                      obj.path.push(b.path)
                    }
                    b.title=el.title;
                    a.children.push(b)
                  }
                })
              }
              obj.children.push(a);
                obj.path.push(a.path);


            }
          });
        }
        if (element.role) {
          if (this.hasCommonElements(element.role, permissions)) {
            this.navList.push(obj);
          }
        }
      });
      this.tempList = [];
      this.getNavList(this.tempRoutes);
      let router = this.$route.path;
      let role = [];
      this.tempList.forEach((e) => {
        if (router.length != 1) {
          if (router.endsWith("/")) {
            router = router.slice(0, -1);
          }
        }
        if (router.indexOf(e.path)) {
          role = e.role;
        }
      });
      if (!this.hasCommonElements(role, permissions)) {
        if (this.$route.path == "/login" || this.$route.path == "/register") {
          return;
        }
        if (this.$route.path !== "/") {
          this.$router.push({ name: "Dashboard" });
        }
      }
    },
    getNavList(nav, first) {
      nav.forEach((e) => {
        if (e.role && e.role.length > 0) {
          if (e.path) {
            if (first && first.path != "/") {
              this.tempList.push({
                path: (first.path + "/" + e.path).replace(/\/:.*/, ""),
                role: e.role,
              });
            } else {
              this.tempList.push({
                path: e.path.replace(/\/:.*/, ""),
                role: e.role,
              });
            }
          }
        }
        if (e.children && e.children.length > 0) {
          this.getNavList(e.children, e);
        }
      });
    },
  },
  components: {
    UserComponent,
    Navicon,
    router,
  },
  watch: {
    $route(to, from) {
      // 在路由变化时执行的逻辑
      this.initNav();
    },
  },

  computed: {
    ...mapState("web", ["obj"]),
    ...mapState({
      routes: (state) => state.permission.routes,
      tempRoutes: (state) => state.permission.tempRoutes,
    }),
  },
  beforeDestroy() { },
  created() {
    this.initNav();
  },
  updated() { },
  mounted() { },
};
</script>