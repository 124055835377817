import service from "@/utils/request"
// 获取卡包列表
export function getRechargeRecordListApi(data) {
    return service({
        url: '/staffCz/getStaffCzList',
        method: 'get',
        params:data
    })
}
export function getRechargeRecordInfoApi(data) {
    return service({
        url: '/staffCz/getInfo',
        method: 'get',
        params:data
    })
}

export function addStaffCz(data) {
    return service({
        url: '/staffCz/addStaffCz',
        method: 'post',
        data
    })
}
export function updateStaffCzApi(data) {
    return service({
        url: '/staffCz/updateStaffCz',
        method: 'PUT',
        data
    })
}
// addStaffCz