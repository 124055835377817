import service from "@/utils/request"
// 获取全国商品池列表
export function getJdRegionList(data) {
    return service({
        url: '/jdRegion/getJdRegionList',
        method: 'get',
        params:data
    })
}

export function getJdRegionDetail(data) {
    return service({
        url: '/jdRegion/getCheckBox',
        method: 'get',
        params: data
    })
}