import service from "@/utils/request"
// 获取送货上门
export function getDeliveryListApi(data) {
    return service({
        url: '/delivery/getDeliverySelect',
        method: 'post',
        data
    })
}

export function getDeliveryPageApi(data) {
    return service({
        url: '/delivery/getDeliveryList',
        method: 'get',
        params: data
    })
}

export function updateDeliveryIsStatusApi(data) {
    return service({
        url: '/delivery/updateIsStatus',
        method: 'post',
        data
    })
}

export function deleteDeliveryByIdApi(data) {
    return service({
        url: '/delivery/delDelivery',
        method: 'delete',
        data
    })
}

export function addDeliveryTemplate(data) {
    return service({
        url: '/delivery/addDelivery',
        method: 'post',
        data
    })
}

