<!-- 组件模板内容 -->
<template>
  <div style="min-height: 100vh">
    <TitleCard title="权限管理" style="max-width: 97%; margin: 10px auto">
      <template v-slot:subTitle>
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted me-0" rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
              style="font-family: 'Roboto', sans-serif">
            Permission Management
          </li>
        </ul>
      </template>
    </TitleCard>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div
          style="max-width: 97%; margin-bottom: 15px; min-height: 85vh;background: #fff;border-radius:10px;padding: 10px;"
          id="kt_content_container" class="container">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="角色管理" name="second">
            <div style="float: right;margin-right: 100px;">
              <el-button type="primary" size="mini" @click="createRole">创建角色</el-button>
            </div>
            <el-table :data="roleList" style="width: 100%">
              <el-table-column prop="id" label="ID">
              </el-table-column>
              <el-table-column prop="roleName" label="角色名">
              </el-table-column>
              <el-table-column prop="roleNickName" label="角色别名">
              </el-table-column>
              <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="editRole(scope.row)">修改信息</el-button>
                  <el-button type="text" size="small" @click="editRoleP(scope.row)">修改权限</el-button>
                  <el-button type="text" size="small" @click="deleteRole(scope.row)">删除权限</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>

          <el-tab-pane label="用户管理" name="first">
            <el-row :spans="6">
              <el-col>
                <label>用户名：</label>
                <el-input v-model="params.userName" placeholder="请输入用户名" size="small" style="width: 200px;"></el-input>
                <label>用户状态：</label>
                <el-select v-model="params.status" placeholder="请选择状态">
                  <el-option
                      v-for="item in statusOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-button type="primary" size="small" @click="getStaffList">搜索</el-button>
              </el-col>
            </el-row>

            <el-table :data="userList" style="width: 100%">
              <el-table-column prop="id" label="ID">
              </el-table-column>
              <el-table-column prop="userName" label="用户名">
              </el-table-column>

              <el-table-column prop="tel" label="手机号">
              </el-table-column>

              <el-table-column label="营业执照">
                <template slot-scope="scope">
                  <div class="overlay col-xl-3" style="margin-left: 30px">
                    <div class="overlay-wrapper"
                         style=" display: flex;justify-content: center;align-items: center;">
                      <img :src="scope.row.licensePicPath" class="rounded w-200px h-100px">
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-5 rounded"
                         style="display: flex;flex-direction: column;">
                      <a href="#" class="btn btn-sm btn-primary btn-shadow"
                         @click="view(scope.row.licensePicPath)">查看大图</a>
                    </div>
                  </div>
                </template>
              </el-table-column>


              <el-table-column label="用户状态">
                <template slot-scope="scope">
                  <div v-if="scope.row.staffStatus === 1" class="text-success">账号启用</div>
                  <div v-if="scope.row.staffStatus === 0" class="text-warning">账号待审核</div>
                  <div v-if="scope.row.staffStatus === -1" class="text-danger">账号禁用</div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="editUser(scope.row)">修改权限</el-button>
                  <el-button type="text" size="small" @click="setUserRole(scope.row)">设置角色</el-button>
                  <el-button class="text-danger" v-if="scope.row.staffStatus === 1" type="text" size="small" @click="editUserStatus(scope.row,-1)">禁用</el-button>
                  <el-button class="text-success" v-else-if="scope.row.staffStatus === 0"
                             type="text" size="small" @click="editUserStatus(scope.row,1)">通过审核
                  </el-button>
                  <el-button class="text-success" v-else-if="scope.row.staffStatus === -1 || scope.row.staffStatus === -2"
                             type="text" size="small" @click="editUserStatus(scope.row,1)">启用
                  </el-button>
                  <el-button class="text-danger" v-if="scope.row.staffStatus === 0" type="text" size="small" @click="editUserStatus(scope.row,-2)">审核不通过</el-button>

                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <ModalDialog title="修改权限" ref="permissionDialog" width="30%">
      <el-tree :data="permissionObj" show-checkbox node-key="id" ref="tree" highlight-current :props="defaultProps">
      </el-tree>
      <div style="margin-top: 30px;">
        <el-button @click="$refs.permissionDialog.close()">取 消</el-button>
        <el-button type="primary" @click="savePermission()" :loading="loading">保 存</el-button>
      </div>
    </ModalDialog>
    <el-dialog title="修改角色" :visible.sync="dialogVisible" width="30%">
      <el-form ref="roleObj" :model="roleObj" label-width="80px">
        <el-form-item label="角色名">
          <el-input v-model="roleObj.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色别名">
          <el-input v-model="roleObj.roleNickName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEditRole">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="创建角色" :visible.sync="createDialog" width="30%">
      <el-form ref="roleObj" :model="roleObj" label-width="80px">
        <el-form-item label="角色名">
          <el-input v-model="roleObj.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色别名">
          <el-input v-model="roleObj.roleNickName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createDialog = false">取 消</el-button>
        <el-button type="primary" @click="createSub">确 定</el-button>
      </span>
    </el-dialog>
    <ModalDialog title="修改用户角色" ref="editUserRole" width="30%">
      <el-tree :data="userRoleList" show-checkbox default-expand-all node-key="id" ref="userRoletree" highlight-current
               :props="defaultProps">
      </el-tree>
      <div style="margin-top: 30px;">
        <el-button @click="createDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveUserEdit">确 定</el-button>
      </div>
    </ModalDialog>
    <el-dialog :visible.sync="templatePicUrlVisible" width="60%">
      <el-image style="width: 100%; height: 50%" :src="templatePicUrl"></el-image>
    </el-dialog>

  </div>
</template>

<script>
import {
  getAllPermissionApi,
  setUserRoleApi,
  getAllRoleApi,
  getRoleIdByUserIdApi,
  deleteRoleApi,
  updateUserPApi,
  getStaffPermissionApi,
  getStaffListApi,
  addRolesApi,
  getRolePermissionByRoleIdApi,
  updateRoleApi,
  addRoleApi,
  updateStaffStatusApi
} from '@/api/userApi';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: '',
  // 组件的props定义,用于子组件接收父组件传值
  props: {},
  // 组件的data属性
  data() {
    return {
      editUserRole: false,
      loading: false,
      dialogVisible: false,
      permissionDialog: false,
      createDialog: false,
      activeName: 'first',
      total: 0,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      statusOptions: [
        {
          label: '启用',
          value: 1
        },
        {
          label: '禁用',
          value: -1
        },
        {
          label: '待审核',
          value: 0
        },
        {
          label: '审核不通过',
          value: -2
        }
      ],
      params: {
        userName: "",
        size: 10,
        current: 1,
        status: ""
      },
      userRoleList: [],
      allPermission: [],
      permissionObj: [],
      roleList: [],
      userList: [],
      permissionList: [],
      status: 1,
      roleObj: {},
      userObj: {},
      // 方法图片
      templatePicUrlVisible: false,
      templatePicUrl: '',

    };
  },
  // 组件的方法
  methods: {
    handleSizeChange(val) {
      this.params.size = val;
      this.getStaffList()
    },
    handleCurrentChange(val) {
      this.params.current = val;
      this.getStaffList()
    },
    async saveUserEdit() {
      let a = this.$refs.userRoletree.getCheckedKeys();
      await setUserRoleApi({
        userId: this.userObj.id,
        roleIds: a
      })
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.$refs.editUserRole.close();
    },
    async setUserRole(item) {
      let {data} = await getRoleIdByUserIdApi({userId: item.id})
      this.userObj = item;
      this.userRoleList = []
      this.$refs.editUserRole.open();
      await this.getAllRole()
      let arr = []
      this.roleList.forEach((e) => {
        arr.push({
          name: e.roleNickName,
          id: e.id
        })
      })
      this.userRoleList = [
        {
          name: "全部",
          children: arr
        }
      ]
      this.$refs.userRoletree.setCheckedKeys([...data]);
    },
    async deleteRole(item) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteRoleApi({
          roleId: item.id
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.getAllRole()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    createRole() {
      this.roleObj = {}
      this.createDialog = true;
    },
    async editRole(item) {
      this.roleObj = item;
      this.dialogVisible = true
    },
    async createSub() {
      await addRolesApi(this.roleObj)
      this.createDialog = false;
      this.getAllRole()
    },
    async saveEditRole() {
      await updateRoleApi(JSON.stringify({
        id: this.roleObj.id,
        roleName: this.roleObj.roleName,
        roleNickName: this.roleObj.roleNickName
      }))
      this.getAllRole()
      this.dialogVisible = false
      this.$message({
        message: '修改成功',
        type: 'success'
      });
    },
    async savePermission() {
      this.loading = true
      //修改用户权限
      let a = this.getCheckedKeys()
      if (this.status == 1) {
        // console.log(this.roleObj);
        await updateUserPApi({
          userId: this.roleObj.id, permissionIds: a
        })
      }
      //修改角色权限
      else {
        let roleId = this.roleObj.id;
        await addRoleApi({
          roleId, permissionIds: a
        })
      }
      this.$refs.permissionDialog.close()
      this.$message({
        message: '修改成功',
        type: 'success'
      });
      this.loading = false
    },
    async editUser(item) {
      this.roleObj = item;
      this.status = 1;
      this.permissionObj = [];
      this.permissionList = [];
      this.$refs.permissionDialog.open()
      this.getAllPermission()
      let {data} = await getStaffPermissionApi({
        userId: item.id
      })
      data.forEach((e) => {
        this.permissionList.push(e.nodeId)
      })
      this.$refs.tree.setCheckedKeys(this.permissionList);
    },
    async editRoleP(item) {
      this.roleObj = item;
      this.status = 2;
      this.permissionObj = [];
      this.permissionList = [];
      this.$refs.permissionDialog.open()
      this.getAllPermission()
      let {data} = await getRolePermissionByRoleIdApi({
        roleId: item.id
      })
      data.forEach((e) => {
        this.permissionList.push(e.nodeId)
      })
      this.$refs.tree.setCheckedKeys(this.permissionList);
    },
    async getStaffList() {
      let {data} = await getStaffListApi(this.params);
      this.userList = data.rows
      this.total = data.total
    },
    async getAllRole() {
      let {data} = await getAllRoleApi({
        size: 9999
      });
      this.roleList = data.rows
    },
    handleClick() {
      if (this.activeName == 'first') {
        this.getStaffList()
      } else {
        this.getAllRole()
      }
    },
    getCheckedNodes() {
      console.log(this.$refs.tree.getCheckedNodes());
    },
    getCheckedKeys() {
      let arr = this.$refs.tree.getCheckedKeys();
      arr = arr.filter(e => {
        return e != undefined;
      })
      return arr;
    },
    setCheckedNodes() {
      this.$refs.tree.setCheckedNodes([{
        id: 5,
        label: '二级 2-1'
      }, {
        id: 9,
        label: '三级 1-1-1'
      }]);
    },
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([3]);
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    },
    async getAllPermission() {
      let {data} = await getAllPermissionApi();
      this.allPermission = data;
      this.setObj()
    },
    setObj() {
      let arr = [];
      let arr1 = this.allPermission;
      let arr2 = []
      arr1.forEach((e) => {
        e.name = e.description
        e.id = e.nodeId
        arr2.push(e)
      })
      const groupedData = arr2.reduce((acc, item) => {
        const category = item.category;
        if (acc[category]) {
          acc[category].push(item);
        } else {
          // 否则，创建一个新的分类并添加当前项
          acc[category] = [item];
        }
        return acc;
      }, {});
      for (const key in groupedData) {
        arr.push({
          name: key,
          children: groupedData[key]
        })
      }
      this.permissionObj = [{
        "name": "全部",
        children: arr
      }];
    },
    // 放大图片
    view(picUrl) {
      this.templatePicUrl = picUrl
      this.templatePicUrlVisible = true
    },
    async editUserStatus(item, status) {
      let user = {
        ...item,
        staffStatus: status
      }
      await updateStaffStatusApi(user)
      this.getStaffList()
      /*item.staffStatus = status;
      await updateStaffStatusApi(item);*/
    }
  },
  mounted() {
    if ('WebSocket' in window) {
      this.websocket = new WebSocket('ws://localhost:10001/websocket')
    }
  },
  beforeDestroy() {
    this.onbeforeunload();
  },
  // 在created钩子中执行的操作
  created() {
    if (this.activeName == 'first') {
      this.getStaffList()
    } else {
      this.getAllRole()
    }
  }
};
</script>

<!-- 组件的样式 -->
<style scoped></style>