<template>
    <div>
        <div class="modal-content">
            <!--begin::Modal header-->
            <div class="modal-header">
                <!--begin::Modal title-->
                <h2>活动管理</h2>
                <!--end::Modal title-->
                <!--begin::Close-->
                <div class="btn btn-sm btn-primary" @click="saveTopicVisible = true">
                    创建活动
                </div>

            </div>
            <!--end::Modal header-->
            <!--begin::Modal body-->
            <div class="modal-body py-lg-10 px-lg-10">
                <div id="topicTable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive">
                        <table
                            class="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder no-footer dataTable"
                            id="topicTable" role="grid" aria-describedby="topicTable_info" style="width: 590px;">
                            <!--begin::Head-->
                            <thead class="fs-7 text-gray-400 text-uppercase">
                                <tr role="row">
                                    <th  class="sorting_disabled" rowspan="1" colspan="1">活动名称</th>
                                    <th  class="sorting_disabled" rowspan="1" colspan="1">图片</th>
                                    <th  class="sorting_disabled" rowspan="1" colspan="1">创建时间</th>
                                    <th  class="sorting_disabled" rowspan="1" colspan="1">操作</th>
                                </tr>
                            </thead>
                            <tbody id="topicTableBody" class="fs-6">

                                <tr class="odd" v-for="(item,index) in topicList" :key="index">
                                    <td>{{ item.title }}</td>
                                    <td><img :src="item.bannerUrl"
                                            style="width:190px;height:57px;object-fit: contain;"></td>
                                    <td>{{ item.crTime }}</td>
                                    <td><a class="btn btn-info btn-sm px-4 mx-sm-1 my-sm-1"
                                            @click="editTopic(item.id)">&nbsp;编辑</a><a
                                            class="btn btn-success btn-sm px-4 mx-sm-1 my-sm-1"
                                            @click="editGoods(item.id)">&nbsp;商品维护</a><a
                                            class="btn btn-danger btn-sm px-4 mx-sm-1 my-sm-1"
                                            @click="deleteTopic(item.id)">&nbsp;删除</a></td>
                                </tr>
                            </tbody>

                        </table>
                        <div id="topicTable_processing" class="dataTables_processing" style="display: none;"></div>
                    </div>
                  <el-pagination style="display:flex;justify-content:flex-end" @size-change="handleSizeChange"
                                 @current-change="handleCurrentChange" :current-page="topicListQuery.pageIndex" :page-sizes="[10,20,30]"
                                 :page-size="topicListQuery.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="count"
                                 background>
                  </el-pagination>
                </div>
            </div>
            <!--end::Modal body-->
            <el-dialog title="创建活动" v-if="saveTopicVisible" :visible.sync="saveTopicVisible" width="40%" append-to-body=true
                :before-close="handleSaveClose" >
                <template #title>
                    <div class="modal-content">
                        <div class="modal-header">
                            <h2 v-if="saveShopMode == 1">创建活动</h2>
                            <h2 v-if="saveShopMode == 2">编辑活动</h2>
                        </div>
                    </div>
                </template>
                <div class="modal-body" style="width: 70%;margin-left: 15%">
                    <el-form :model="saveTopicForm" :rules="saveTopicFormRule" ref="saveTopicForm" label-position="top">
                        <el-form-item prop="title">
                            <span slot="label" style="display:inline-block;">
                                <label class="form-label fw-bolder text-dark fs-6">活动名称</label>
                            </span>
                            <el-input v-model="saveTopicForm.title"></el-input>
                        </el-form-item>
                        <el-form-item prop="subTitle">
                              <span slot="label" style="display:inline-block;">
                                  <label class="form-label fw-bolder text-dark fs-6">活动副标题</label>
                              </span>
                          <el-input v-model="saveTopicForm.subTitle"></el-input>
                        </el-form-item>
                        <el-form-item prop="sortNum">
                                <span slot="label" style="display:inline-block;">
                                    <label class="form-label fw-bolder text-dark fs-6">活动序号</label>
                                        <el-tooltip effect="dark" content="控制在商城中" placement="bottom">
                                      <i class='el-icon-question'/>
                                      </el-tooltip>
                                </span>
                          <el-input type="number" v-model="saveTopicForm.sortNum"></el-input>
                        </el-form-item>
                        <el-form-item label="首页logo(建议尺寸：190x57)" prop="bannerUrl">
                            <span slot="label" style="display:inline-block;">
                                <label class="form-label fw-bolder text-dark fs-6">广告图(建议尺寸：190x57)</label>
                            </span>
                            <uploadImg moduleName="wxShopTopic" :imgUrl="saveTopicForm.bannerUrl" @upload-success="uploadSuccess"
                                @cancel-success="cancelSuccess"></uploadImg>
                        </el-form-item>

                    </el-form>
                </div>

                <div class="modal-footer">
                    <button v-if="saveShopMode == 1" @click="submitAddTopic" type="button"
                        class="btn btn-primary">提交</button>
                    <button v-if="saveShopMode == 2" @click="submitUpdateTopic" type="button"
                        class="btn btn-primary">保存</button>
                </div>
            </el-dialog>

          <!-- 商品的弹窗 -->
          <el-dialog title="活动商品" v-if="wxTopicGoodsVisible" :visible.sync="wxTopicGoodsVisible" width="90%" append-to-body=true>
            <template #title>
              <div class="modal-content">
                <div class="modal-header">
                  <h2>活动商品</h2>
                </div>
              </div>
            </template>
            <topicGoodsModal :currentTopicId="currentTopicId"></topicGoodsModal>
          </el-dialog>
        </div>

</div></template>

<script>
import Pagination from '@/components/Pagination/index.vue'
import uploadImg from "./uploadImg.vue"
import {
  addWxShopTopic,
  changeTopicStatus,
  getWxShopTopicInfo,
  getWxShopTopicList,
  updateWxShopTopic
} from '@/api/shopTopicApi'
import topicGoodsModal from "@/views/Shop/component/topicGoodsModal.vue";

export default {
    components: {
      topicGoodsModal,
        Pagination, uploadImg
    },
    data() {
        return {
            wxTopicGoodsVisible:false,
            saveTopicVisible: false,
            saveShopMode: 1,
            saveTopicForm: {
                title: "",
                subTitle:"",
                sortNum:"",
                bannerUrl: ""
            },
            saveTopicFormRule: {
                title: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
                ]
            },
            topicListQuery:{
                pageIndex:1,
                pageSize:10
            },
            topicList:[],
            currentTopic:{},
            currentTopicId: -1,

            count:0
        }
    },
    mounted(){
        this.$bus.$on('message-close', this.closeMessage);
    },
    methods: {
        async getTopicList(){
          const {data} = await getWxShopTopicList(this.topicListQuery);
          this.topicList = data.rows
          this.count = data.count
        },
      handleCurrentChange(param) {
        this.topicListQuery.pageIndex = param
        this.getTopicList();
      },
      handleSizeChange(param) {
        this.topicListQuery.pageIndex = 1
        this.topicListQuery.pageSize = param
        this.getTopicList();
      },
        uploadSuccess(res) {
            console.log(res)
            this.saveTopicForm.bannerUrl = res.data.fileUrl
        },
        cancelSuccess(res) {
            if (res) {
                console.log(res)
              this.saveTopicForm.bannerUrl = ""
            }
        },
        handleSaveClose() {
            this.saveTopicVisible = false
            this.saveTopicForm.bannerUrl = ""
            this.saveTopicForm.title = ""
            this.saveTopicForm.sortNum=""
            this.saveTopicForm.subTitle=""
        },
        async submitAddTopic() {
          const res = await addWxShopTopic(this.saveTopicForm);
          console.log(res)
            if(res.code == 200){
                this.$message({
                type: 'success',
                message: '创建成功!'
                });
              this.saveTopicVisible = false

              await this.getTopicList()
            }
        },
        async editTopic(id){
            this.saveTopicVisible = true
          const queryOne = {wxShopTopicId: id};
          const {data} = await getWxShopTopicInfo(queryOne);
          console.log(data)
            this.saveShopMode = 2
            this.currentTopic = data
            this.saveTopicForm.title = data.title
            this.saveTopicForm.bannerUrl = data.bannerUrl
            this.saveTopicForm.subTitle = data.subTitle
            this.saveTopicForm.sortNum = data.sortNum
        },
        async submitUpdateTopic() {
          this.currentTopic.title = this.saveTopicForm.title
          this.currentTopic.bannerUrl = this.saveTopicForm.bannerUrl
          this.currentTopic.subTitle = this.saveTopicForm.subTitle
          this.currentTopic.sortNum = this.saveTopicForm.sortNum
          const res = await updateWxShopTopic(this.currentTopic);
          this.$message({
                type: 'success',
                message: '编辑成功!'
            });
            this.saveTopicVisible = false
            await this.getTopicList()
        },

        async deleteTopic(id){
          const deleteParam = {
            id: id,
            status: 2
          };
          const res = await changeTopicStatus(deleteParam);
          if(res.code==200){
                this.$message({
                type: 'success',
                message: '删除成功!'
                });
                await this.getTopicList()
            }
        },
      editGoods(currentTopcId){
          this.currentTopicId = currentTopcId
          this.wxTopicGoodsVisible = true
      }

    },
    created(){
        this.getTopicList()
    }
}

</script>


<style scoped></style>
