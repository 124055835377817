import service from "@/utils/request"

export function addWxShopTopic(data){
    return service({
        url:'/wxShopTopic/addWxShopTopic',
        method:'post',
        params:data
    })
}

export function updateWxShopTopic(data){
    return service({
        url:'/wxShopTopic/updateWxShopTopic',
        method:'post',
        params:data
    })
}

export function getWxShopTopicInfo(data){
    return service({
        url:'/wxShopTopic/getWxShopTopicInfo',
        method:'get',
        params:data
    })
}

export function getWxShopTopicList(data){
    return service({
        url:'/wxShopTopic/getWxShopTopicList',
        method:'get',
        params:data
    })
}

export function changeTopicStatus(data){
    return service({
        url:'/wxShopTopic/changeTopicStatus',
        method:'get',
        params:data
    })
}