export const keyType=[
    {
        label:'仅卡密',
        value:1
    },
    {
        label: '卡号+卡密',
        value: 2
    }
]
export const keyStatus=[
    {
        label:'请选择',
        value:"",
        type:""
    },
    {
        label:'未激活',
        value:0,
        type:"info"
    },
    {
        label: '激活可兑换',
        value: 1,
        type:"warning"
    },
    {
        label: '已兑换',
        value: 2,
        type:"success"
    },
    {
        label: '已失效',
        value: 3,
        type:"danger"
    }
]