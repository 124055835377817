<!-- 组件模板内容 -->
<template>
  <div style="background: #fff">
    <div class="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row" id="kt_stepper_example_vertical">
      <div class="d-flex flex-row-auto w-100 w-lg-300px" style="border-right: 1px solid #0000004a;">
        <div class="stepper-nav flex-cente">
          <div class="stepper-item me-5" data-kt-stepper-element="nav" v-for="(item, ind) in list" :key="ind"
            :class="listIndex == ind + 1 ? 'current' : (listIndex > ind + 1) ? 'completed' : ''">
            <!--  -->
            <div class="stepper-wrapper d-flex align-items-center">
              <div class="stepper-icon w-40px h-40px">
                <i class="stepper-check fas fa-check"></i>
                <span class="stepper-number">{{ ind + 1 }}</span>
              </div>
              <div class="stepper-label">
                <h3 class="stepper-title">{{ item.title }}</h3>
                <div class="stepper-desc">{{ item.subTitle }}</div>
              </div>
            </div>
            <div class="stepper-line h-40px" v-if="ind != arr.length - 1"></div>
          </div>
        </div>
      </div>
      <div class="flex-row-fluid">
        <form class="form mx-auto" novalidate="novalidate">
          <div class="mb-5">
            <slot></slot>
          </div>
          <!-- <div class="d-flex flex-stack">
            <div class="me-2">
              <button
                type="button"
                class="btn btn-light btn-active-light-primary"
                v-if="arr.length != ind && listIndex != 1"
                @click="back"
              >
                上一步
              </button>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-primary"
                v-if="arr.length == listIndex"
                @click="submit"
              >
                提交
              </button>

              <button
                type="button"
                class="btn btn-primary"
                v-if="arr.length > listIndex"
                @click="next"
              >
                下一步
              </button>
            </div>
          </div> -->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepComponent",
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    arr: {
      type: Array,
      default: [
        { title: "第一步", subTitle: "第一步介绍" },
        { title: "第一步", subTitle: "第一步介绍" },
        { title: "第一步", subTitle: "第一步介绍" },
        { title: "第一步", subTitle: "第一步介绍" },
      ],
    },
    index: {
      type: Number,
      default: 1,
    },
  },
  // 组件的data属性
  data() {
    return {
      listIndex: this.index,
      ind: 0,
      list: this.arr
    };
  },
  // 组件的方法
  methods: {
    next() {
      this.listIndex++
      this.$emit("index-change", this.listIndex);
    },
    back() {
      this.listIndex--
      this.$emit("index-change", this.listIndex);
    },
    submit() {
      // 提交事件
      this.$emit("submit-event");
    }

  },
  watch: {
    "index": {
      handler(newVal, oldVal) {
        this.listIndex = newVal
      }
    }
  },
  // 在created钩子中执行的操作
  created() { },
};
</script>

<!-- 组件的样式 -->
<style scoped></style>