var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-8 mb-xl-8"},[_c('div',{staticClass:"card h-md-100"},[_vm._m(0),_c('div',{staticClass:"card-body pt-2"},[_c('ul',{staticClass:"nav nav-pills nav-pills-custom mb-3"},_vm._l((_vm.navList),function(item,index){return _c('li',{key:index,staticClass:"nav-item mb-3 me-3 me-lg-6"},[_c('a',{staticClass:"nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-80px h-85px py-4",class:index===0?'active':'',attrs:{"data-bs-toggle":"pill","href":`#kt_stats_widget_2_tab_${index+1}`}},[_c('div',{staticClass:"nav-icon"},[_c('img',{attrs:{"alt":"","src":item.imagesSrc}})]),_c('span',{staticClass:"nav-text text-gray-700 fw-bold fs-7 lh-1"},[_vm._v(" "+_vm._s(item.title.substring(0, 4))+" ")]),_c('span',{staticClass:"bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"})])])}),0),_c('div',{staticClass:"tab-content"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,staticClass:"tab-pane fade",class:index===0?'show active':'',attrs:{"id":`kt_stats_widget_2_tab_${index+1}`}},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-row-dashed align-middle gs-0 gy-4 my-0"},[_vm._m(1,true),_c('tbody',_vm._l((item.list),function(i,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('a',{staticClass:"symbol symbol-50px",attrs:{"href":"javascript:void(0);"}},[_c('img',{staticClass:"symbol-label lozad",attrs:{"src":i.imagesUrl}})]),_c('div',{staticClass:"ms-3"},[_c('a',{staticClass:"text-gray-800 text-hover-primary fs-6 fw-bold",staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","width":"220px","display":"block"},attrs:{"href":"javascript:void(0);","title":i.title,"data-kt-ecommerce-product-filter":"product_name"},on:{"click":function($event){return _vm.openGoodInfo(
                                i.id,
                                i.isJd
                              )}}},[_vm._v(_vm._s(i.title))])])])]),_c('td',{staticClass:"text-center pe-0"},[_c('span',{staticClass:"text-gray-800 fw-bold d-block fs-6"},[_vm._v(_vm._s(_vm._f("format")(i.price)))])]),_c('td',{staticClass:"text-center pe-0"},[_c('span',{staticClass:"text-gray-800 fw-bold d-block fs-6"},[_vm._v(_vm._s(_vm._f("format")(i.disPrice)))])]),_c('td',{staticClass:"text-center pe-0"},[_c('span',{staticClass:"text-gray-800 fw-bold d-block fs-6"},[_vm._v(_vm._s((i.discount*100).toFixed(2))+"%")])]),_vm._m(2,true),_vm._m(3,true)])}),0)])])])}),0)])]),_c('ModalDialog',{ref:"goodInfo",attrs:{"title":"商品详情","id":"goodInfo","width":"1000px"}},[_c('GoodInfoComponent',{ref:"goodInfoComponent"})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header align-items-center border-0"},[_c('h3',{staticClass:"fw-bold text-gray-900 m-0"},[_vm._v("全国商品池，甄选爆品")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"fs-7 fw-bold text-gray-500 border-bottom-0"},[_c('th',{},[_vm._v("商品信息")]),_c('th',{staticClass:"pe-0 text-center min-w-100px"},[_vm._v("市场价")]),_c('th',{staticClass:"pe-0 text-center min-w-100px"},[_vm._v("分销价")]),_c('th',{staticClass:"pe-0 text-center min-w-100px"},[_vm._v("利润率")]),_c('th',{staticClass:"pe-0 text-center min-w-100px"},[_vm._v("商品来源")]),_c('th',{staticClass:"pe-0 text-center min-w-100px"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center pe-0"},[_c('span',{staticClass:"text-gray-800 fw-bold d-block fs-6"},[_vm._v("京东商品")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center pe-0"},[_c('span',{staticClass:"text-gray-800 fw-bold d-block fs-6"})])
}]

export { render, staticRenderFns }