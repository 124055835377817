<!-- 组件模板内容 -->
<template>
  <div>
    <div id="myTabContent" class="tab-content">
      <div
          id="kt_tab_pane_2"
          class="tab-pane fade show active"
          role="tabpanel"
      >
        bbbb
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
// 组件的props定义,用于子组件接收父组件传值
  props: {},
// 组件的data属性
  data() {
    return {};
  },
// 组件的方法
  methods: {},
// 在created钩子中执行的操作
  created() {
    console.log("bbb")
  }
};
</script>

<!-- 组件的样式 -->
<style scoped>

</style>