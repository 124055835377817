<!-- 组件模板内容 -->
<template>
    <div>
        <StepComponent
            :index="index"
            @submit-event="submitEvent"
            :arr="arr"
            @index-change="indexChange"
        >
            <stepper1 :saveShopVoucherForm="saveShopVoucherForm" @saveForm-change="saveFormChange" v-if="index==1" @indexAdd="index++"></stepper1>
            <stepper2 :saveShopVoucherForm="saveShopVoucherForm" @saveForm-change="saveFormChange" @closePopup="closePopup" v-if="index==2" @indexSub="index--"></stepper2>
        </StepComponent>

    </div>
</template>
  
<script>
import { hasVoucherData } from '@/api/shopVoucherApi.js'

import StepComponent from '@/components/StepComponent'
import stepper1 from './stepperForm/stepper1'
import stepper2 from './stepperForm/stepper2'
export default {
    name: "Demo",
    components: {
        StepComponent,
        stepper1,
        stepper2
    },
    // 组件的props定义,用于子组件接收父组件传值
    props: ['currentWxShopId'],
    // 组件的data属性
    data() {
        return {

            index: 1,
            arr: [
                { title: "基本信息", subTitle: "填写卡券基本信息" },
                { title: "界面模板", subTitle: "选择一个卡券的外观样式" },
            ],
            saveShopVoucherForm: {
                voucherTemplateId:null,
                wxShopId: this.currentWxShopId,
                title:"",
                num:"",
                score:null,
                type:1,
                staffId: null,
                cardNoPrefix: "",
                secretLength:6,
                secretContent:6
            },
        };
    },
    // 组件的方法
    methods: {
        submitEvent() {
            console.log("提交");
        },
        indexChange(i) {
            this.index = i;
        },
        saveFormChange(a){
            console.log("saveFormChange",a);
            this.saveShopVoucherForm = a
        },
        async shopHasVoucherData(){
          const queryParams = {wxShopId: this.currentWxShopId};
          const {data} = await hasVoucherData(queryParams);
          console.log(data)
          if(data){
             this.saveShopVoucherForm.title = data.title
             this.saveShopVoucherForm.voucherTemplateId = data.voucherTemplateId
             
           }
        },
      closePopup(){
          console.log("窗口要被关闭")
        this.$emit('closePopup')
      }
    },
    // 在created钩子中执行的操作
    created() {
        this.shopHasVoucherData()
    },
    updated() {
        // 当数据更新后，可以在此处手动初始化下拉菜单插件
        // this.rows.forEach((_, index) => {
        //     this.toggleDropdown(index);
        // });
    }
};
</script>
  
  <!-- 组件的样式 -->
<style scoped></style>