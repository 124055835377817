import service from "@/utils/request"


export function getComboGiftVoucherECardList(data) {
    return service({
        url: '/e-card-combo-gift-voucher/getECardList',
        method: 'get',
        params:data
    })
}

export function addComboGiftVoucherECard(data) {
    return service({
        url: '/e-card-combo-gift-voucher/add',
        method: 'post',
        data
    })
}
//创建 数字卡卷
// saveComboGiftVoucherECard
export function updateComboGiftVoucherECard(data) {
    return service({
        url: '/e-card-combo-gift-voucher/update',
        method: 'put',
        data
    })
}


//获取积分卡卷或现金卡卷
export function getECardVoucherListApi(data) {
    return service({
        url: '/wxShopECardVoucher/getECardVoucherList',
        method: 'post',
        data
    })
}