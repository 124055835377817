<template>
  <div style="min-height: 89vh">
    <TitleCard title="全国商品池" style="max-width: 97%; margin: 10px auto">
      <template v-slot:subTitle>
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted">正品优选</li>
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-200 w-5px h-2px"></span>
          </li>
          <li class="breadcrumb-item text-muted">源头发货</li>
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-200 w-5px h-2px"></span>
          </li>
          <li class="breadcrumb-item text-muted">合作共赢</li>
        </ul>
      </template>
      <div style="position: absolute; right: 70px">
        <a href="javascript:void(0)" class="el-button el-button--primary mx-2 el-button--mini"
          @click="$router.push({ name: 'GroupManage' })">分组管理</a>
        <button type="button" class="el-button el-button--primary el-button--mini"
          :class="idArr.length ? '' : 'is-disabled'" @click="pushAll">
          <i class="el-icon-edit"></i><span> 批量推送</span>
        </button>
      </div>
    </TitleCard>

    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div style="max-width: 98%; flex-direction: row !important; display: flex" id="kt_content_container"
        class="container">
        <!-- 搜索开始 -->
        <div class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5">
          <form action="#" id="category_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem">
                <div class="mb-5">
                  <div class="nav-group nav-group-fluid">
                    <label>
                      <input type="radio" class="btn-check" name="goodsPlace" @click="changIsJd(1)" value="1" ref="goodsPlace1"/>
                      <span class="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                        京东货源
                      </span>
                    </label>
                    <label>
                      <input type="radio" class="btn-check" name="goodsPlace" @click="changIsJd(2)" value="2"
                        checked="checked" ref="goodsPlace2" />
                      <span class="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                        厂供货源
                      </span>
                    </label>
                  </div>
                </div>
                <div class="separator separator-dashed my-8"></div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">商品标题</label>
                  <div class="position-relative">
                    <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                        height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                            fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                          <path
                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                            fill="#000000" fill-rule="nonzero"></path>
                        </g>
                      </svg>
                    </span>
                    <input id="sTitle" type="text" class="form-control form-control-solid ps-10" name="search"
                      v-model="params.sTitle" value="" placeholder="请输入需要查询的商品标题名称" />
                  </div>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">一级分类</label>
                  <select class="form-select form-select-solid" v-model="params.categoryId1" data-placeholder="全部"
                    @change="findCategory(1)">
                    <option value="">全部</option>
                    <option v-for="(item, index) in categoryOne" :key="index" :value="item.categoryId">
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">二级分类</label>
                  <select class="form-select form-select-solid" v-model="params.categoryId2" @change="findCategory(2)"
                    data-placeholder="全部">
                    <option value="">全部</option>
                    <option v-for="(item, index) in categoryTwo" :key="index" :value="item.categoryId">
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>
                <div class="mb-5" v-if="params.isJd != 1">
                  <label class="fs-6 form-label fw-bolder text-dark">手机号</label>
                  <input id="sPhone" type="text" v-model="params.sPhone"
                    class="form-control form-control form-control-solid" placeholder="请输入手机号" />
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">全国分销价区间</label>
                  <div class="row">
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input type="text" id="minSupplyPrice" name="minSupplyPrice"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        v-model="params.minSupplyPrice"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最小值" value="" />
                      <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input type="text" id="maxSupplyPrice" name="maxSupplyPrice"
                        class="form-control form-control-lg form-control-solid" v-model="params.maxSupplyPrice"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最大值" value="" />
                      <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                  </div>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">利润率区间</label>
                  <div class="row">
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input type="text" id="minProfitMargin" name="minProfitMargin"
                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="params.minRightRate"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最小值" value="" />
                      <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input type="text" id="maxProfitMargin" name="maxProfitMargin"
                        class="form-control form-control-lg form-control-solid" v-model="params.maxRightRate"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                        placeholder="最大值" value="" />
                      <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                  <a @click="shaixuan" href="javascript:void(0);" class="btn btn-primary">筛选</a>&nbsp;&nbsp;&nbsp;
                  <a @click="resetQuery" href="javascript:void(0);"
                    class="btn text-hover-primary bg-hover-light btn-color-gray-400 me-3">重置</a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- 列表开始 -->
        <div class="card mb-5 mb-xl-8" style="margin-left: 3px; width: 100%" element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" v-loading="loading">
          <div class="card-body py-3">
            <div class="table-responsive">
              <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
                id="kt_ecommerce_products_table">
                <thead>
                  <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                    <th class="w-10px pe-2 sorting_disabled" rowspan="1" colspan="1" aria-label=""
                      style="width: 29.8906px">
                      <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                        <input class="form-check-input" type="checkbox" data-kt-check="true"
                          data-kt-check-target="#kt_ecommerce_products_table .form-check-input" value="TRUE"
                          v-model="checkboxInput" @change="checkboxInputEvent" />
                      </div>
                    </th>
                    <th class="min-w-200px" tabindex="0" aria-controls="kt_ecommerce_products_table" rowspan="1"
                      colspan="1" aria-label="Product: activate to sort column ascending" style="width: 212.141px">
                      商品信息
                    </th>
                    <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="分类" style="width: 80px">
                      分类
                    </th>
                    <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="市场价" style="width: 80px">
                      市场价
                    </th>
                    <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="分销价" style="width: 80px">
                      分销价
                    </th>
                    <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="利润率" style="width: 80px">
                      利润率
                    </th>
                    <!-- <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="加价信息"
                      style="width: 80px"
                    >
                      加价信息
                    </th> -->
                    <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="销售方式" style="width: 80px">
                      销售方式
                    </th>
                    <th class="text-center" tabindex="0" rowspan="1" colspan="1" aria-label="库存" style="width: 80px"
                      v-if="params.isJd != 1">
                      库存
                    </th>
                    <!-- <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="状态"
                      style="width: 80px"
                    >
                      状态
                    </th> -->
                    <th class="text-center sorting_disabled" rowspan="1" colspan="1" aria-label="操作" style="width: 80px">
                      操作
                    </th>
                  </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                  <tr v-for="(item, index) in rows" :class="{ old: index % 2 == 0, even: index % 2 != 0 }" :key="index">
                    <!-- 复选按钮 -->
                    <td>
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input class="form-check-input" type="checkbox" value="1" @click="addIdArr(item.id)"
                          :checked="idArr.includes(item.id)" />
                      </div>
                    </td>
                    <!-- 商品信息 -->
                    <td>
                      <div class="d-flex align-items-center">
                        <a href="javascript:void(0);" class="symbol symbol-50px">
                          <img class="symbol-label lozad" v-if="item.picUrl.indexOf('http') == 0"
                            :src="`${item.picUrl}`" />
                          <img class="symbol-label lozad" v-else :src="`https://www.1c-1g.com${item.picUrl}`" />
                        </a>
                        <div class="ms-5">
                          <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-5"
                            data-kt-ecommerce-product-filter="product_name" @click="
                              openGoodInfo(
                                item.isJD == 1 ? item.skuId : item.id,
                                item.isJD
                              )
                              ">{{ item.title }}</a>
                        </div>
                      </div>
                    </td>
                    <!-- 分类 -->
                    <td class="text-center pe-0">
                      <span v-html="item.categoryName" v-if="item.categoryName">
                      </span>
                    <span v-else v-html="[
                        item.categoryId1Name,
                        item.categoryId2Name,
                        item.categoryId3Name,
                      ].join('<br>')
                        ">
                      </span>
                  </td>
                    <!-- 市场价 -->
                    <td class="text-center pe-0">
                      <span>{{ item.price | format }} </span>
                    </td>
                    <!-- 分销价 -->
                    <td class="text-center pe-0">
                      <span>{{
                          item.supplPrice | format
                        }}</span>
                    </td>
                    <!-- 利润率 -->
                    <td class="text-center pe-0">
                      <span>{{ item.maxRightRate }}</span>
                    </td>
                    <!-- 加价信息 -->
                    <!-- <td class="text-center pe-0">
                      <span class="fw-bold">{{ item.disPriceType }}</span>
                    </td> -->
                    <!-- 销售方式 -->
                    <td class="text-center pe-0">
                      <span>{{ item.buyType }}</span>
                    </td>
                    <!-- 库存 -->
                    <td class="text-center pe-0" v-if="params.isJd != 1">
                      <span>{{ item.stock }}</span>
                    </td>
                    <!-- 状态 -->
                    <!-- <td class="text-center pe-0">
                      <span class="fw-bold" v-if="item.isShow == '已上架'">
                        <span
                          class="badge badge-light-success fs-7 fw-bold"
                          style="
                            display: block;
                            text-align: center;
                            width: 80px;
                          "
                          >{{ item.isShow }}</span
                        >
                        <span
                          class="text-muted fw-bold text-muted d-block fs-7"
                          style="
                            display: block;
                            text-align: center;
                            width: 80px;
                          "
                          >{{ item.isShowTime }}</span
                        >
                      </span>
                      <span
                        class="badge badge-light-warning fs-7 fw-bold"
                        v-else
                        style="display: block"
                        >{{ item.isShow }}</span
                      >
                    </td> -->
                    <!-- 操作 -->
                    <td class="text-end">
                      <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary"
                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="toggleDropdown(index)">
                        操作
                        <i class="ki-outline ki-down fs-5 ms-1"></i>
                      </a>
                      <div :id="'dropdown-' + index"
                        class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                        data-kt-menu="true">
                        <!-- <div
                          class="menu-item px-3"
                          v-if="item.isShow == '已上架'"
                        >
                          <a
                            href="javascript:void(0)"
                            class="menu-link px-3 text-success"
                            >改价</a
                          >
                        </div> -->
                        <!-- <div
                          class="menu-item px-3"
                          v-if="item.isShow != '已上架'"
                        >
                          <a
                            href="javascript:void(0)"
                            class="menu-link px-3 text-success"
                            >上架</a
                          >
                        </div>
                        <div class="menu-item px-3" v-else>
                          <a
                            href="javascript:void(0)"
                            class="menu-link px-3 text-danger"
                            >下架</a
                          >
                        </div> -->
                        <div class="menu-item px-3">
                          <a href="javascript:void(0)" @click="pushGood(item.id, 1)" class="menu-link px-3">挑选上架</a>
                        </div>
                        <div class="menu-item px-3">
                          <a href="javascript:void(0)" class="menu-link px-3" @click="
                            updatePrice(
                              item.isJD == 1 ? item.skuId : item.id,
                              item.isJD
                            )
                            ">更新价格</a>
                        </div>
                        <div class="menu-item px-3">
                          <a href="javascript:void(0)" class="menu-link px-3" @click="
                            buyGoods(
                              item
                            )
                            ">直接购买</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination :total-items="totalCount" @page-change="pageChange" @page-change-size="pageChangeSize">
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 商品详情 -->
    <ModalDialog title="商品详情" id="goodInfo" ref="goodInfo" width="1000px">
      <GoodInfoComponent ref="goodInfoComponent"></GoodInfoComponent>
    </ModalDialog>
    <!-- 推送 -->
    <ModalDialog title="挑选上架" id="pushGoodsAll" ref="pushGoodsAll" width="850px">
      <div>
        <el-form ref="form" :model="pushGoodsObj" label-width="80px">
          <el-form-item label="推送目标" :required="true">
            <el-checkbox-group v-model="pushGoodsObj.selectionObjects" @change="changeSelectionObjects">
              <el-checkbox label="group" :disabled="pushGoodsObj.selectionObjects.includes('shop') || pushGoodsObj.selectionObjects.includes('topic')">分组</el-checkbox>
              <el-checkbox label="shop" :disabled="pushGoodsObj.selectionObjects.includes('group')">商城</el-checkbox>
              <el-checkbox label="topic" :disabled="pushGoodsObj.selectionObjects.includes('group')">活动</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="已选商品">
            <span style="color: red">{{ state ? "1" : idArr.length }} </span> 件商品
          </el-form-item>
          <el-form-item label="销售价" :required="pushGoodsObj.salePriceChange == '1'">
            <el-radio-group v-model="pushGoodsObj.salePriceChange">
              <el-radio label="1" :disabled="pushGoodsObj.selectionObjects.includes('group')">按照</el-radio>
            </el-radio-group>
            &nbsp;&nbsp;&nbsp;
            <el-select v-model="pushGoodsObj.salePriceDto.salePriceReference" style="width: 150px" placeholder="请选择"
              :disabled="pushGoodsObj.salePriceChange != 1" size="small" @change="selectChange">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            &nbsp;
            <el-select v-model="pushGoodsObj.salePriceDto.salePriceAdjust" style="width: 150px" placeholder="请选择"
              size="small" :disabled="pushGoodsObj.salePriceChange != 1">
              <el-option v-for="item in adjustOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            &nbsp;
            <el-input v-model="pushGoodsObj.salePriceDto.salePriceNum" placeholder="请输入内容" size="small"
              style="width: 150px" :disabled="pushGoodsObj.salePriceChange != 1"></el-input>
            &nbsp;
            <el-select style="width: 100px" v-model="pushGoodsObj.salePriceDto.salePriceUnit" placeholder="请选择"
              size="small" :disabled="pushGoodsObj.salePriceChange != 1">
              <el-option v-for="item in unitOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <br />
            <el-radio-group v-model="pushGoodsObj.salePriceChange">
              <el-radio label="2">不改变</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item
            label="分销价"
            :required="pushGoodsObj.supplPriceChange == '1'"
          >
            <el-radio-group v-model="pushGoodsObj.supplPriceChange">
              <el-radio label="1">按照</el-radio>
            </el-radio-group>
            &nbsp;&nbsp;&nbsp;
            <el-select
              v-model="pushGoodsObj.supplPriceDto.supplPriceReference"
              :disabled="pushGoodsObj.supplPriceChange != 1"
              placeholder="请选择"
              size="small"
              style="width: 150px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            &nbsp;
            <el-select
              v-model="pushGoodsObj.supplPriceDto.supplPriceAdjust"
              placeholder="请选择"
              style="width: 150px"
              :disabled="pushGoodsObj.supplPriceChange != 1"
              size="small"
            >
              <el-option
                v-for="item in adjustOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            &nbsp;
            <el-input
              v-model="pushGoodsObj.supplPriceDto.supplPriceNum"
              placeholder="请输入内容"
              size="small"
              style="width: 150px"
              :disabled="pushGoodsObj.supplPriceChange != 1"
            ></el-input
            >&nbsp;
            <el-select
              v-model="pushGoodsObj.supplPriceDto.supplPriceUnit"
              :disabled="pushGoodsObj.supplPriceChange != 1"
              style="width: 100px"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in unitOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <br />
            <el-radio-group v-model="pushGoodsObj.supplPriceChange">
              <el-radio label="2">不改变</el-radio>
            </el-radio-group>
          </el-form-item> -->
        </el-form>
      </div>
      <el-button type="primary" style="float: right; margin-right: 20px" @click="nextPush">下一步<i
          class="el-icon-arrow-right el-icon--right"></i></el-button>
    </ModalDialog>
    <ModalDialog title="选择具体推送目标" id="pushGrounp" ref="pushGrounp"
      :width="pushGoodsObj.selectionObjects.length * 400 + 'px'">
      <div style="display: flex">
        <div style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
          v-if="pushGoodsObj.selectionObjects.includes('group')">
          <h3>分组</h3>
          <div>
            <el-tree :data="groupProps" show-checkbox node-key="id" ref="groupTree" :current-node-key="groupProps.id" @check="groupTreeNodeClick">
            </el-tree>
          </div>
        </div>
        <div style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
          v-if="pushGoodsObj.selectionObjects.includes('shop')">
          <h3>商城</h3>
          <div>
            <el-tree :data="shopProps" show-checkbox node-key="id" ref="shopTree" @check="shopTreeNodeClick">
            </el-tree>
          </div>
        </div>
        <div style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
          v-if="pushGoodsObj.selectionObjects.includes('topic')">
          <h3>活动</h3>
          <div>
            <el-tree :data="topicProps" ref="topicTree" node-key="id" show-checkbox @check="topicTreeNodeClick">
            </el-tree>
          </div>
        </div>
      </div>
      <el-divider></el-divider>

      <el-button type="primary" style="float: right; margin-right: 20px" @click="submitPush">提交&nbsp;&nbsp;<i
          class="el-icon-arrow-right el-icon--right"></i></el-button>
      <el-button type="primary" style="float: right; margin-right: 20px" @click="$refs.pushGrounp.close()">上一步<i
          class="el-icon-arrow-left el-icon--left"></i></el-button>
    </ModalDialog>
    <!-- <GroupTools :typeArr="['group']"></GroupTools> -->
    <el-dialog title="商品信息" :visible.sync="dialogVisible" width="60%">
      <el-descriptions class="margin-top" title="" :column="1" border>
        <!-- <template slot="extra">
          <el-button type="primary" size="small">操作</el-button>
        </template> -->
        <el-descriptions-item>
          <template slot="label">
            标题
          </template>
          {{ item.title }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-mobile-phone"></i> -->
            市场价
          </template>
          {{ item.price | format }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-location-outline"></i> -->
            分销价
          </template>
          {{ item.supplPrice | format }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-tickets"></i> -->
            销售方式
          </template>
          {{ item.buyType }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-office-building"></i> -->
            收件人信息
          </template>
          <el-descriptions title="" direction="vertical" :column="5" border>
            <el-descriptions-item label="收件人">{{ addressObj.name }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ addressObj.tel }}</el-descriptions-item>
            <el-descriptions-item label="收件地址">{{ addressObj.proCity }}{{ addressObj.address }}</el-descriptions-item>
            <el-descriptions-item label="详细地址">{{ addressObj.address }}</el-descriptions-item>
            <el-descriptions-item label="操作"><el-link type="primary" @click="addAddress"><i
                  class="el-icon-plus el-icon--right"></i>新增</el-link><el-link type="primary" style="margin-left: 10px;"
                @click="moreAddress">更多</el-link></el-descriptions-item>
          </el-descriptions>

        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <!-- <i class="el-icon-office-building"></i> -->
            购买数量
          </template>
          <el-input-number v-model="num" :min="1"></el-input-number>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="payGoods">购 买</el-button>
      </span>
    </el-dialog>
    <!-- 修改或新增收货地址 -->
    <el-dialog :title="status == 1 ? '新增收货信息' : '编辑收货信息'" :close-on-click-modal="false" :visible.sync="createOrEdit"
      width="30%">
      <el-form :model="editAddressObj">
        <el-form-item label="收货人" style="width:100%">
          <el-input v-model="editAddressObj.name" placeholder="请输入收货人"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" style="margin-bottom:0">
        </el-form-item>
        <el-form :inline="true" label-position="top">
          <el-form-item label="省">
            <el-select v-model="editAddressObj.pro" style="width:120px" @change="proChange">
              <el-option v-for="item in proList" :label="item.name" :value="item.kid" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市">
            <el-select v-model="editAddressObj.city" style="width:120px" @change="cityChange">
              <el-option v-for="item in cityList" :label="item.name" :value="item.kid" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="县(区)">
            <el-select v-model="editAddressObj.area" style="width:120px" @change="areaChange">
              <el-option v-for="item in areaList" :label="item.name" :value="item.kid" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="街道">
            <el-select v-model="editAddressObj.stree" style="width:120px">
              <el-option v-for="item in streeList" :label="item.name" :value="item.kid" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form-item label="详细地址">
          <el-input v-model="editAddressObj.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="editAddressObj.tel" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-checkbox v-model="editAddressObj.isType" true-label="1" false-label="2">设置默认地址</el-checkbox>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createOrEdit = false">取 消</el-button>
        <el-button type="primary" @click="saveAddress">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 收货地址列表 -->
    <el-dialog title="收货信息列表" :visible.sync="addressListDialog" width="50%">
      <el-table :data="addressList">
        <el-table-column property="name" label="收件人"></el-table-column>
        <el-table-column property="proCity" label="收件地址"></el-table-column>
        <el-table-column property="address" label="详细地址"></el-table-column>
        <el-table-column property="tel" label="手机号"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="selectAddress(scope.row)">选择</el-button>
            <el-button type="text" size="small" @click="editAddress(scope.row)">编辑</el-button>
            <el-button type="text" size="small" v-if="scope.row.isType == 2"
              @click="setDefalt(scope.row)">设置默认</el-button>
            <el-button type="text" size="small" v-if="scope.row.isType == 2" @click="delAddress(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addressListDialog = false">取 消</el-button>
        <el-button type="primary" @click="addressListDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {mixins} from "./mixins"
export default {
  mixins:[mixins]
};
</script>
<style></style>