<template xmlns="http://www.w3.org/1999/html">
  <div>
    <!-- begin::Body-->
    <div class="">
      <!--begin::Body-->
      <div class="pb-12">
        <!--begin::Wrapper-->
        <div class="d-flex flex-column gap-7 gap-md-10">
          <!--begin::Order details-->
          <div class="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold">
            <div class="flex-root d-flex flex-column">
              <span class="text-muted">增值税发票抬头</span>
              <span class="fs-5">{{ applyInvoiceInfo.fpName }}</span>
            </div>
            <div class="flex-root d-flex flex-column">
              <span class="text-muted">增值税发票税号</span>
              <span class="fs-5">{{ applyInvoiceInfo.fpNo }}</span>
            </div>
            <div class="flex-root d-flex flex-column">
              <span class="text-muted">增值税注册地址</span>
              <span class="fs-5">{{ applyInvoiceInfo.fpAdd }}</span>
            </div>
            <div class="flex-root d-flex flex-column">
              <span class="text-muted">增值税注册电话</span>
              <span class="fs-5">{{ applyInvoiceInfo.fpTel }}</span>
            </div>
          </div>
          <!--end::Order details-->
          <!--begin::Billing & shipping-->
          <div class="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold">
            <div class="flex-root d-flex flex-column">
              <span class="text-muted">增值税注册银行</span>
              <span class="fs-5">{{ applyInvoiceInfo.fpYh }}</span>
            </div>
            <div class="flex-root d-flex flex-column">
              <span class="text-muted">增值税银行账号</span>
              <span class="fs-5">{{ applyInvoiceInfo.fpYhzh }}</span>
            </div>
            <div class="flex-root d-flex flex-column">
              <span class="text-muted">增值税收票人邮箱</span>
              <span class="fs-5">{{ applyInvoiceInfo.sjEmail }}</span>
            </div>
            <div class="flex-root d-flex flex-column">
              <span class="text-muted">开票状态</span>
              <span v-if="applyInvoiceInfo.isStauts == 1" class="fw-bold">
                        <span class="badge badge-danger fs-7 fw-bold">未开票</span>
                      </span>
              <span v-if="applyInvoiceInfo.isStauts == 2" class="fw-bold">
                        <span class="badge badge-success fs-7 fw-bold">已开票</span><a href="javascript:void(0)" style="color: #50cd89;margin-left: 10px;" @click="openInfo">查看发票</a>
                      </span>
            </div>
          </div>
          <!--end::Billing & shipping-->
          <!--begin:Order summary-->
          <div class="d-flex justify-content-between flex-column">
            <!--begin::Table-->
            <div class="table-responsive border-bottom mb-9">
              <el-table v-loading="loading"
                        :data="tableData"
                        element-loading-background="rgba(0, 0, 0, 0.8)"
                        element-loading-spinner="el-icon-loading"
                        element-loading-text="拼命加载中"
                        style="width: 100%">
                <el-table-column label="发票抬头">
                  <template slot-scope="scope">
                    <el-popover v-if="scope.row.money != 0" placement="top-start" trigger="hover">
                      <p class="fw-bold">发票信息</p>
                      <p>发票抬头: {{ scope.row.companyName }}</p>
                      <p>发票税号: {{ scope.row.taxID }}</p>
                      <p>开户行及账号: {{ scope.row.openingBankAndAccountNumber }}</p>
                      <p>开户行地址: {{ scope.row.bankAddress }}</p>
                      <p>收票地址: {{ scope.row.ticketReceivingAddress }}</p>
                      <p>收票邮箱: {{ scope.row.ticketReceivingEmail }}</p>
                      <div slot="reference" class="name-wrapper">
                        {{ scope.row.companyName }} <i class="el-icon-question"></i>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column label="订单数">
                  <template v-if="scope.row.money !=0" slot-scope="scope">
                    {{ scope.row.ordersNos.length}}
                  </template>
                </el-table-column>
                <el-table-column label="金额">
                  <template v-if="scope.row.money !=0" slot-scope="scope">
                    {{ scope.row.money | format }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="display: flex;justify-content: right;margin-right: 50px">
              <span class="fs-3 text-dark fw-bold text-end">开票总金额</span>
              <span class="text-dark fs-3 fw-bolder text-end"
                    style="margin-left: 150px">{{ applyInvoiceInfo.money | format }}</span>
            </div>
          </div>
          <div style="display: flex;justify-content: center;align-items: center;">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {getApplyInvoiceInfo, getApplyInvoiceInfoByCompany} from "@/api/ApplyInvoiceApi.jsApi";
import {formatMoney} from "@/utils/formatMoney";

export default {
  name: "RechargeRecordDetails",
  data() {
    return {
      loading: false,
      applyInvoiceInfo: {},
      tableData: []
    }
  },
  methods: {
    openInfo(){
      this.$swal.fire({
        icon: "warning",
        title: "即将开放！",
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    async getApplyInvoiceInfo(id) {
      let {data} = await getApplyInvoiceInfo({
        id
      });
      this.applyInvoiceInfo = data;
      this.getApplyInvoiceInfoByCompany(id)

    },
    async getApplyInvoiceInfoByCompany(id) {
      this.loading = true
      let {data} = await getApplyInvoiceInfoByCompany({
        id
      });
      data=data.filter(item => item.money != 0)
      this.tableData = data;
      this.loading = false
    },
    cleanData() {
      this.applyInvoiceInfo = {}
      this.tableData = []
    }
  },
  filters: {
    format(num) {
      return formatMoney(num)
    }
  },
  created() {

  }
}
</script>

<style scoped>

</style>