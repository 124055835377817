import service from "@/utils/request"
// 获取全国商品池列表
export function getChooseGoodsListApi(data) {
    return service({
        url: '/chooseGoods/getNewChooseGoodsList',
        method: 'get',
        params:data
    })
}

export function getChooseGoodsListCountApi(data) {
    return service({
        url: '/chooseGoods/getNewChooseGoodsListCount',
        method: 'get',
        params:data
    })
}

//批量挑选
export function selectionGoodsApi(data) {
    return service({
        url: '/chooseGoods/selectionGoods',
        method: 'post',
        data
    })
}

// 获取京东地址分页列表
export function getAddressPageApi(data) {
    return service({
        url: '/jdShopAddress/getAddressPage',
        method: 'get',
        params:data
    })
}

//修改地址数据
export function updateAddressApi(data) {
    return service({
        url: '/jdShopAddress/updateAddress',
        method: 'put',
        data
    })
}
// 删除地址
export function deleteAddressApi(data) {
    return service({
        url: '/jdShopAddress',
        method: 'delete',
        params:data
    })
}
//获取省市区
export function getJdRegionListApi(data) {
    return service({
        url: '/jdRegion/getJdRegionList',
        method: 'get',
        params:data
    })
}
//回显 
// /jdShopAddress/getAddressInfo
export function getAddressInfoApi(data) {
    return service({
        url: '/jdShopAddress/getAddressInfo',
        method: 'get',
        params:data
    })
}

//创建地址
export function addAddressApi(data) {
    return service({
        url: '/jdShopAddress/addAddress',
        method: 'post',
        data
    })
}
//购买
// /chooseGoods/buyPoolGoods
export function buyPoolGoodsApi(data) {
    return service({
        url: '/chooseGoods/buyPoolGoods',
        method: 'post',
        data
    })
}
//设置默认地址
// /jdShopAddress/defaultAddress
export function defaultAddressApi(data) {
    return service({
        url: '/jdShopAddress/defaultAddress',
        method: 'get',
        params:data
    })
}

// /sjGoods/updateGoodsInfo
//更细商品价格
export function updateGoodsInfoApi(data) {
    return service({
        url: '/sjGoods/updateGoodsInfo',
        method: 'get',
        params:data
    })
}