<!-- 组件模板内容 -->
<template>
  <div>
    <div
      v-loading="loading"
      class="card mb-5 mb-xl-8"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      element-loading-spinner="el-icon-loading"
      element-loading-text="拼命加载中"
      style="margin-left: 3px; width: 100%"
    >
      <div class="card-body py-3">
        <div class="table-responsive">
          <el-button
            type="primary"
            size="small"
            :disabled="idArr.length==0"
            @click="addApplyInvoiceBySqkp(1)"
            >批量开票</el-button
          >
          <table
            id="kt_ecommerce_products_table"
            class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
          >
            <thead>
              <tr
                class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
              >
                <th
                  class="w-10px pe-2 sorting_disabled"
                  rowspan="1"
                  colspan="1"
                  aria-label=""
                  style="width: 29.8906px"
                >
                  <div
                    class="form-check form-check-sm form-check-custom form-check-solid me-3"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      data-kt-check="true"
                      data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                      value="TRUE"
                      v-model="checkboxInput"
                      @change="checkboxInputEvent"
                    />
                  </div>
                </th>

                <th class="min-w-70px">采购账号</th>
                <th class="min-w-70px">订单批次</th>
                <th class="min-w-70px">订单编号</th>
                <th class="min-w-70px">订单来源</th>
                <th class="min-w-70px">商品来源</th>
                <th class="min-w-100px">商品名称</th>
                <th class="min-w-70px">市场价</th>
                <th class="min-w-70px">分销价</th>
                <th class="min-w-70px">销售价</th>
                <th class="min-w-70px">数量</th>
                <th class="min-w-70px">运费</th>
                <th
                  class="min-w-70px"
                  style="display: flex; flex-direction: column"
                >
                  <div>客户支付</div>
                  <div>(含运费)</div>
                </th>
                <th class="min-w-70px" style="color: red">开票金额</th>
                <th class="min-w-70px">开票状态</th>
                <th class="min-w-70px">下单时间</th>
                <th class="min-w-100px">操作</th>
              </tr>
            </thead>
            <tbody class="fw-semibold text-gray-600">
              <tr v-for="(item, index) in rows" :key="index">
                <td>
                  <div
                    class="form-check form-check-sm form-check-custom form-check-solid"
                  >
                    <input
                      v-if="
                        item.isSqFpStr == '未开票' &&
                        item.totalMoneySupplyStr &&
                        item.totalMoneySupplyStr != '-'
                      "
                      class="form-check-input"
                      type="checkbox"
                      value="1"
                      @click="addIdArr(item.orderNo)"
                      :checked="idArr.includes(item.orderNo)"
                    />
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center">{{ item.user }} </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="">{{ item.cartNo }} </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="">{{ item.orderNo }} </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center">{{ item.orderFrom }} </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center">{{ item.goodsTypeName }} </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center">{{ item.title }} </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center">{{ item.price | format }} </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center"
                      >{{ item.supplyPrice | format }}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center">{{ item.disPriceStr }} </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center">{{ item.num }} </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center"
                      >{{ item.yfPrice | format }}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center"
                      >{{ item.totalMoneyDisPriceStr | format }}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center"
                      >{{ item.totalMoneySupplyStr | format }}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center">{{ item.isSqFpStr }} </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex pe-0">
                    <span class="text-center">{{ item.crTime }} </span>
                  </div>
                </td>

                <td class="">
                  <el-button
                    type="primary"
                    size="small"
                    v-if="
                      item.isSqFpStr == '未开票' &&
                      item.totalMoneySupplyStr &&
                      item.totalMoneySupplyStr != '-'
                    "
                    @click="
                      addApplyInvoiceBySqkp(
                        item.orderNo,
                        item.title,
                        item.totalMoneyDisPriceStr
                      )
                    "
                    >申请开票</el-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            :total-items="totalCount"
            @page-change="pageChange"
          ></Pagination>
        </div>
      </div>
    </div>
    <ModalDialog ref="InvoicingModalDialog" title="申请开票" width="50%">
      <el-descriptions title="开票信息" :column="1" border>
        <el-descriptions-item
          label="商品名称"
          label-class-name="my-label"
          content-class-name="my-content"
          v-if="status == 1"
        >
          {{ goodsTitle }}
        </el-descriptions-item>
        <el-descriptions-item label="开票金额" v-if="status == 1">
          {{ goodsPrice | format }}
        </el-descriptions-item>
        <el-descriptions-item label="开票金额" v-if="status == 2">
          {{ sumMoney | format }}
        </el-descriptions-item>
        <el-descriptions-item label="发票信息">
          <el-select v-model="fpId" placeholder="请选择" @change="getFpInfo">
            <el-option
              v-for="item in invoiceList"
              :key="item.id"
              :label="item.fpName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item v-if="fpId != ''" label="发票抬头"
          >{{ fpInfo.fpName }}
        </el-descriptions-item>
        <el-descriptions-item v-if="fpId != ''" label="发票税号">
          {{ fpInfo.fpNo }}</el-descriptions-item
        >
        <el-descriptions-item v-if="fpId != ''" label="注册地址">
          {{ fpInfo.fpAdd }}</el-descriptions-item
        >
        <el-descriptions-item v-if="fpId != ''" label="注册电话">
          {{ fpInfo.fpTel }}</el-descriptions-item
        >
        <el-descriptions-item v-if="fpId != ''" label="开户银行">
          {{ fpInfo.fpYh }}</el-descriptions-item
        >
        <el-descriptions-item v-if="fpId != ''" label="银行账号"
          >{{ fpInfo.fpYhzh }}
        </el-descriptions-item>
        <el-descriptions-item v-if="fpId != ''" label="收票人邮箱"
          >{{ fpInfo.sjEmail }}
        </el-descriptions-item>
        <el-descriptions-item v-if="fpId != ''" label="是否专票">{{
          fpInfo.special ? "是" : "否"
        }}</el-descriptions-item>
      </el-descriptions>
      <div style="display: flex; justify-content: right; margin-right: 20px">
        <el-button
          type="primary"
          style="float: right; margin-right: 20px"
          @click="addApplyInvoiceBySqkps"
          >提交&nbsp;&nbsp;<i class="el-icon-arrow-right el-icon--right"></i
        ></el-button>
      </div>
    </ModalDialog>
  </div>
</template>

<script>
import {
  addApplyInvoiceByPlSqkpApi,
  addApplyInvoiceBySqkpApi,
  getApplyForInvoicingOrderListApi,
} from "@/api/ApplyInvoiceApi.jsApi";
import { getInvoiceListApi } from "@/api/invoiceApi";
export default {
  name: "",
  // 组件的props定义,用于子组件接收父组件传值
  props: {},
  // 组件的data属性
  data() {
    return {
      rows: [],
      idArr: [],
      totalCount: 999,
      loading: false,
      fpId: "",
      params: {
        pageIndex: 1,
        pageSize: 10,
        isSqFp: "",
        orderNo: "",
      },
      checkboxInput: false,
      orderNo: "",
      invoiceList: [],
      goodsTitle: "",
      goodsPrice: "",
      fpInfo: {
        crTime: "",
        fpAdd: "",
        fpName: "",
        fpNo: "",
        fpTel: "",
        fpYh: "",
        fpYhzh: "",
        id: "",
        sjEmail: "",
        special: false,
        staffId: "",
      },
      tempArr: [],
      status: "1",
    };
  },
  // 组件的方法
  methods: {
    async addApplyInvoiceBySqkps() {
      if (this.status == 1) {
        let { data } = await addApplyInvoiceBySqkpApi({
          orderNo: this.orderNo,
          sqkpFpId: this.fpId,
        });
      } else {
        let { data } = await addApplyInvoiceByPlSqkpApi({
          orderNos: this.idArr,
          sqkpFpId: this.fpId,
        });
      }
      this.$swal.fire({
        icon: "success",
        title: "发票申请成功,请等待管理员审核！",
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
      this.$refs.InvoicingModalDialog.close();
      this.$emit("close");
      this.getList();
    },
    getFpInfo(id) {
      this.invoiceList.forEach((e) => {
        if (e.id == id) {
          this.fpInfo = e;
        }
      });
    },
    async addApplyInvoiceBySqkp(orderNo, title, price) {
      if (orderNo == 1) {
        this.status = 2;
      } else {
        this.status = 1;
        this.goodsTitle = title;
        this.goodsPrice = price;
        this.orderNo = orderNo;
      }

      this.fpId = "";
      let { data } = await getInvoiceListApi({
        iDisplayStart: 1,
        iDisplayLength: 200,
        sEcho: "",
        fpName: "",
        fpNo: "",
      });
      this.invoiceList = data.data;
      this.$refs.InvoicingModalDialog.open();
    },
    addIdArr(id) {
      if (this.idArr.includes(id)) {
        let index = this.idArr.indexOf(id);
        if (index !== -1) {
          this.idArr.splice(index, 1);
        }
      } else {
        this.idArr.push(id);
      }
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.id)) {
          a = false;
        }
      });
      this.checkboxInput = a;
    },
    checkboxInputEvent() {
      if (this.checkboxInput) {
        this.rows.forEach((e) => {
          if (!this.idArr.includes(e.orderNo)) {
            if (e.isSqFpStr == '未开票' &&
                      e.totalMoneySupplyStr &&
                      e.totalMoneySupplyStr != '-') {
                          this.idArr.push(e.orderNo);
            }
          }
        });
      } else {
        this.rows.forEach((e) => {
          let index = this.idArr.indexOf(e.orderNo);
          if (index !== -1) {
            this.idArr.splice(index, 1);
          }
        });
      }
    },
    pageChange(currentPage, pageSize) {
      this.params.pageIndex = currentPage;
      this.params.pageSize = pageSize;
      this.getApplyForInvoicingOrderList();
    },
    async getApplyForInvoicingOrderList() {
      this.loading = true;
      let { data } = await getApplyForInvoicingOrderListApi(this.params);
      this.rows = data.rows;
      this.totalCount = data.total;
      this.loading = false;
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.orderNo)) {
          a = false;
        }
      });
      this.tempArr.push(...this.rows);
      this.tempArr = this.tempArr.filter((item, index, self) => {
        return (
          index ===
          self.findIndex(
            (t) => t.orderNo === item.orderNo // 根据对象的id属性来判断是否重复
          )
        );
      });
      this.checkboxInput = a;
    },
    getList() {
      this.getApplyForInvoicingOrderList();
    },
  },
  computed: {
    sumMoney() {
      let sum = 0;
      this.tempArr.forEach((e) => {
        if (this.idArr.includes(e.orderNo)) {
          sum += e.totalMoneySupplyStr * 100;
        }
      });
      return sum / 100;
    },
  },
  // 在created钩子中执行的操作
  created() {
    // this.getApplyForInvoicingOrderList()
  },
};
</script>

<!-- 组件的样式 -->
<style scoped>
</style>