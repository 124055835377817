
const state = {
  routes: [],
  tempRoutes:[],
  routerList: [
    {
      title: "首页",
      path: ["/"],
      subTitle: "首页",
      icon: "ki-graph-3",
      children: []
    },
    {
      title: "商品",
      path: ["/Goods"],
      subTitle: "商品",
      icon: "ki-purchase",
      children: [
        {
          title: "商品管理",
          subTitle: "商品管理",
          path: "/Goods",
          icon: "ki-element-11",
        }
      ]
    },
    {
      title: "商城",
      path: [""],
      icon: "ki-shop",
      subTitle: "商城",
      children: [
        {
          title: "商城",
          subTitle: "商城",
          path: "/pages/chooseGoods/chooseGoodsList.html",
          icon: "ki-element-11",
        }
      ]
    },
    {
      title: "全国商品池",
      path: ["/chooseGoods"],
      subTitle: "全国商品池",
      icon: "ki-category",
      children: [
        {
          title: "全国商品池",
          subTitle: "全国商品池",
          path: "/chooseGoods",
          icon: "ki-element-11",
        }
      ]
    },
    {
      title: "订单",
      path: [],
      icon: "ki-Delivery-3",
      subTitle: "订单",
      children: [
        {
          title: "订单",
          subTitle: "订单",
          path: "/pages/chooseGoods/chooseGoodsList.html",
          icon: "ki-element-11",
        }
      ]
    },
    {
      title: "财务",
      path: [],
      icon: "ki-tag",
      subTitle: "财务",
      children: [
        {
          title: "财务",
          subTitle: "财务",
          path: "/pages/chooseGoods/chooseGoodsList.html",
          icon: "ki-element-11",
        }
      ]
    },
    {
      title: "供应商",
      subTitle: "供应商",
      path: [],
      icon: "ki-badge",
      children: [
        {
          title: "全国商品池",
          subTitle: "全国商品池",
          path: "/pages/chooseGoods/chooseGoodsList.html",
          icon: "ki-element-11",
        }
      ]
    }
  ]
}

const mutations = {

}

const actions = {
  test({commit}){
    console.log(commit.routes);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
