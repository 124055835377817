import service from "@/utils/request"
// 获取卡包列表
export function getCardListApi(data) {
    return service({
        url: '/card/getCardList',
        method: 'get',
        params:data
    })
}

export function addCardApi(data) {
    return service({
        url: '/card/addCard',
        method: 'post',
        data
    })
}



export function getCardApi(data) {
    return service({
        url: '/card/getCard',
        method: 'get',
        params:data
    })
}

// /card/updateCard

export function updateCardApi(data) {
    return service({
        url: '/card/updateCard',
        method: 'put',
        data
    })
}
// 根据类型获取卡片信息

export function getMyCardInfoByTypeApi(data) {
    return service({
        url: '/card/getMyCardInfoByType',
        method: 'get',
        params:data
    })
}