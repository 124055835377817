import service from "@/utils/request"

//获取平台推荐
export function getSaasGoodApi() {
  return service({
    url: "/home/getSaasGood",
    method: "get",
  })
}
export function getSaasGoodsInfoApi(params) {
  return service({
    url: "/home/getSaasGoodsInfo",
    method: "get",
    params
  })
}