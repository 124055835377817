import {
    addComboGiftVoucherECard,
    getComboGiftVoucherECardList,
    getECardVoucherListApi,
    updateComboGiftVoucherECard
} from "@/api/ECardComboGiftVoucherApi";
import {uploadFileApi} from "@/api/uploadFileApi";
import Stepper2 from "@/views/Shop/component/createVoucherModal/stepperForm/stepper2.vue";
import {getVoucherTemplateList} from "@/api/shopVoucherApi";
import QRCode from "qrcode";
import sd from "silly-datetime";
import {getSjGoodsListApi, getSjGoodsListCountApi} from "@/api/goodsManageApi";
import {getChooseGoodsListApi, getChooseGoodsListCountApi} from "@/api/chooseGoodsApi";
import {getCategoryList} from "@/api/categoryApi";
import {
    addECardGoodsApi,
    addWxShopECardVoucherApi,
    deleteDelGoodsApi,
    deleteECardApi,
    deleteWxshopECardApi,
    getECardGoodsListApi,
    getECardShopListApi,
    updateWxShopECardVoucherApi
} from "@/api/eCardApi";
import {getComboGiftPackListApi} from "@/api/comboGiftPackApi";
import {mapState} from "vuex";

export const mixins = {
    //import引入的组件需要注入到对象中才能使用
    components: {Stepper2},
    data() {
        var itemDigit = (rule, value, callback) => {
            if (!this.comboGiftVoucherECardFrom.itemRule) {
                return callback(new Error('请选择卡密规则'));
            }
            callback();
        };
        //这里存放数据
        return {
            totalCount1:0,
            activeName1: 'first',
            goodList: [],
            loading: false,
            id: 0,
            endTime: "",
            status: 1,
            hidden: true,
            templateList: [],
            dialogTableVisible: false,
            pageIndex: 1,
            pageSize: 12,
            count: 0,
            totalCount: 0,
            categoryId1: [],
            categoryId2: [],
            selGoodsList: [],
            stepper2Form: this.comboGiftVoucherECardFrom,
            templatePicUrl: '',
            canChooseTemplate: true,
            filList: [],
            choiceIndex: 0,
            cardType: [
                {
                    name: '积分卡券',
                    buttonName: "创建积分卡券",
                    info: "创建积分消费卡券，客户可自由兑换商品",
                    index: 0
                },
                {
                    name: '现金卡券',
                    buttonName: "创建现金卡券",
                    info: "创建现金消费卡券，客户可自由兑换商品",
                    index: 1
                },
                {
                    name: '组合礼包',
                    buttonName: "创建组合礼包",
                    info: "创建礼包卡券，客户可从N个礼包中任选几件",
                    index: 2
                },
            ],
            activeName: '自由兑换',
            comboGiftVoucherECardListParams: {
                current: 1,
                size: 9,
                title: "",
                startCreateTime: "",
                endCreateTime: "",
                type: 1,
                time:[]
            },
            eCardListObj: {
                // 组合礼包卡券列表
                rows: [],
                total: 0,
                current: 1,
                size: 9,
                pages: 0
            },
            eCardDateRange: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            comboGiftVoucherECardFrom: {
                voucherTemplateid: -999,
                title: "",
                picUrl: "",
                score: "",
                selectNum: "1",
                itemDigit: "8",
                itemRule: "",
                reminder: false,
                showPrice: true,
                endTime: "",
                wxShopId:""
            },
            imgSrc: "",
            rules: {
                selectNum: [
                    {required: true, message: "请选择卡包类型", trigger: "change"},
                ],
                title: [
                    {required: true, message: "请输入卡券名称", trigger: "blur"},
                ],
                wxShopId:[
                    {required: true, message: "请选择商城", trigger: "blur"},
                ],
                endTime: [
                    {required: true, message: '请选择时间', trigger: 'change'}
                ],
                itemDigit: [
                    {validator: itemDigit, trigger: "change"},
                ],
            },
            dialogVisible: false,
            paramss: {
                isTwoShow: 1,
                telNum: "",
                isShow: 1,
                title: "",
                type: "1",
                categoryId1: "",
                categoryId2: "",
                size: 10,
                current: 1,
                isJd: 2,
                lowestBuy: 1
            },
            optionss: [
                {
                    value: '1',
                    label: '自有商品池'
                },
                {
                    value: '2',
                    label: '全国商品池'
                }
            ],
            optionss_vip: [
                {
                    value: '1',
                    label: '自有商品池'
                },
                {
                    value: '2',
                    label: '商会商品池'
                }
            ],
            // 商品来源
            jdType: [
                {
                    type: 1,
                    name: "京东商品"
                },
                {
                    type: 2,
                    name: "自有商品"
                }
            ],
            first: true,
            eCardId: "",
            tableData:[],
            pageParams:{
                size:10,
                current:1,
                isShow: '1',
            },
            shopList:[],
            userRole:JSON.parse(localStorage.getItem('role'))
        }
            ;
    },
    //监听属性 类似于data概念
    computed: {
        goodsIds() {
            return this.goodList.map(item => item.id);
        },
        idsArr() {
            return this.goodList.map(item => item.goodsId);
        },
        ...mapState("web", ["obj"]),
    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        getECardList(){

        },
        openMobileModalBox(item){
            if (this.choiceIndex!==2) {
                this.$refs.MobileModalBox.open("https://h5.3jzc.cn/?shopId="+item.wxShopId);
            }else{
                this.$refs.MobileModalBox.open("https://ecard.3jzc.cn/?comboVoucherId=" + item.id);
            }
        },
        handleClick(tab, event) {
            if (this.activeName1=="first"){
                this.findGoodsList();
            }
            if (this.activeName1=="second"){
                this.getComboGiftPackList();
            }
        },
        async findGoodsList() {
            if(this.userRole.includes('vipMember')){
                this.paramss.isVipGoods = true
            }else{
                this.paramss.isVipGoods = false
            }
            if (this.paramss.type == 1) {
                this.paramss.isJd = 2;
                this.loading = true;
                this.isTwoShow = 1;
                let {data} = await getSjGoodsListApi(this.paramss)
                this.selGoodsList = data.rows;
                this.loading = false;
                if (this.first) {
                    let {data} = await getSjGoodsListCountApi(this.paramss)
                    this.totalCount = data
                }
            } else {
                this.loading = true;
                let json = JSON.parse(JSON.stringify(this.paramss))
                json.sTitle = json.title;
                delete json.title;
                let {data} = await getChooseGoodsListApi(json)
                this.selGoodsList = data.rows;
                this.loading = false;
                if (this.first) {
                    let {data} = await getChooseGoodsListCountApi(json)
                    this.totalCount = data
                }
            }
            this.first = false
        },
        resetBtn() {
            this.first = true;
            this.paramss.isTwoShow = ""
            if (this.paramss.isJd == 2) {
                this.paramss.isTwoShow = 1
            }
            this.paramss = {
                telNum: "",
                title: "",
                isTwoShow: this.paramss.isTwoShow,
                type: this.paramss.type,
                categoryId1: "",
                categoryId2: "",
                size: 10,
                current: 1,
                isJd: this.paramss.isJd
            }
            this.categoryId2 = []
            this.$refs.Pagination.goToPage(1)
            this.findGoodsList()
        },
        async addGoods(item,type) {
            let obj = {
                goodsIds: [],
                jdSkuIds: [],
                comboGiftPackIds: []
            }

            obj.comboVoucherId = this.eCardId;

            if (type){
                obj.comboGiftPackIds=[item.id];
            }else{
                if (item.isJd == 1 || item.isJD == 1) {
                    obj.jdSkuIds = [item.skuId];
                }
                if (item.isJd == 2 || item.isJD == 2) {
                    obj.goodsIds = [item.id];
                }
            }
            await addECardGoodsApi(obj);
            this.getSelGoods();
        },
        async getSelGoods() {
            let {data} = await getECardGoodsListApi({
                size: 100,
                comboVoucherId: this.eCardId
            })
            this.goodList = data.rows
        },
        // 查询分类数据
        async getCategory(item) {
            this.first = true;
            if (item === 1) {
                this.paramss.categoryId2 = ""
                let parentId = this.paramss.categoryId1
                let {data} = await getCategoryList({
                    parentId
                })
                this.categoryId2 = data.list
            }
            this.findGoodsList()
        },
        async clearSelectedGoods() {
            let {data} = await deleteDelGoodsApi({ids: this.goodsIds.join(",")})
            this.getSelGoods()
        },
        async delGroupGoods(item) {
            let {data} = await deleteDelGoodsApi(
                {
                    ids: item.id
                }
            )
            this.getSelGoods()
        },
        shaixuan() {
            this.first = true;
            this.findGoodsList()
        },
        async initCategory() {
            let {data} = await getCategoryList({
                parentId: 0
            })
            this.categoryId1 = data.list
        },
        openGoodsList(item) {
            this.eCardId = item.id;
            this.$refs.goodsListDialog.open()
            this.initCategory();
            this.findGoodsList()
            this.getSelGoods()
        },
        pageChange(currentPage, pageSize) {
            this.paramss.current = currentPage;
            this.paramss.size = pageSize;
            this.findGoodsList();
        },
        pageChange1(currentPage, pageSize) {
            this.paramss.current = currentPage;
            this.paramss.size = pageSize;
            this.getComboGiftPackList();
        },

        /* 列表查询 */
        async getComboGiftPackList() {
            this.checkboxInput = false;
            this.loading = true;
            let res = await getComboGiftPackListApi(this.pageParams);
            let {data} = JSON.parse(res);
            this.loading = false;
            this.tableData = data.rows;
            this.totalCount1 = data.total;
        },
        openCardKey(item) {
            this.id = item.id;
            this.endTime = item.endTime;
            this.dialogTableVisible = true;
        },
        async creatQrCode(pageUrl) {
            //QRCode:二维码生成插件
            let res = await QRCode.toDataURL(pageUrl);
            return res;
        },
        deleteECard(item){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                if (this.choiceIndex==2){
                    await deleteECardApi({
                        id:item.id
                    })
                }else{
                    await deleteWxshopECardApi({
                        id:item.id
                    })
                }
                switch (this.choiceIndex) {
                    case 0:
                        this.getScoreCardList();
                        break;
                    case 1:
                        this.getMoneyCardList();
                        break;
                    case 2:
                        this.getComboGiftVoucherECardList();
                        break;
                    default:
                        this.getScoreCardList();
                        break;
                }
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        editECard(item) {
            this.comboGiftVoucherECardFrom = {
                voucherTemplateid: -1,
                title: "",
                picUrl: "",
                score: "",
                selectNum: "1",
                itemDigit: "8",
                itemRule: "",
                reminder: false,
                showPrice: true,
                endTime: "",
            }
            this.status = 2;
            this.$refs.comboGiftVoucherECardDialog.open();
            if (this.choiceIndex!==2){
                this.getShopList()
            }
            this.imgSrc = item.picUrl;
            item.endTime = new Date(item.endTime);
            this.comboGiftVoucherECardFrom = item
        },
        onChange(file, fileList) {
            this.comboGiftVoucherECardFrom.voucherTemplateid = -1;
            const blob = new Blob([file.raw], {type: 'application/octet-stream'});
            this.imgSrc = window.URL.createObjectURL(blob);
        },
        // 数字卡券组合礼包列表方法
        async getComboGiftVoucherECardList() {
            this.cleanList();
            if (this.eCardDateRange.length == 2) {
                this.comboGiftVoucherECardListParams.startCreateTime = this.eCardDateRange[0];
                this.comboGiftVoucherECardListParams.endCreateTime = this.eCardDateRange[1];
            }
            let {data} = await getComboGiftVoucherECardList(this.comboGiftVoucherECardListParams);
            for (const datum of data.rows) {
                let res = await this.creatQrCode("https://ecard.3jzc.cn/?comboVoucherId=" + datum.id);
                datum.qrCodeUrl = res;
            }
            this.eCardListObj = data;

        },
        // 获取积分卡券列表
        async getScoreCardList() {
            this.cleanList();
            this.comboGiftVoucherECardListParams.type = 1;
            let {data} = await getECardVoucherListApi(this.comboGiftVoucherECardListParams);
            for (const datum of data.rows) {
                let res = await this.creatQrCode("https://h5.3jzc.cn/?shopId=" + datum.wxShopId);
                datum.qrCodeUrl = res;
            }
            this.eCardListObj = data;

        },
        setShopTime(data){
            let arr=this.shopList.find(item=>{
                return item.id === data
            })
            this.comboGiftVoucherECardFrom.endTime=sd.format(new Date(arr.endTime))
        },
        // 获取现金卡券列表
        async getMoneyCardList() {
            this.cleanList();
            this.comboGiftVoucherECardListParams.type = 2;
            let {data} = await getECardVoucherListApi(this.comboGiftVoucherECardListParams);
            for (const datum of data.rows) {
                let res = await this.creatQrCode("https://h5.3jzc.cn/?shopId=" + datum.wxShopId);
                datum.qrCodeUrl = res;
            }
            this.eCardListObj = data;

        },
        // 左侧选择列表的时候触发
        modifyAssignment(index, e) {
            if (e.target.tagName === "INPUT") return;
            this.choiceIndex = index;
            switch (index) {
                case 0:
                    this.getScoreCardList();
                    break;
                case 1:
                    this.getMoneyCardList();
                    break;
                case 2:
                    this.getComboGiftVoucherECardList();
                    break;
                default:
                    this.getScoreCardList();
                    break;
            }
        },
        // 清空列表数据
        cleanList() {

            this.eCardListObj = {
                // 组合礼包卡券列表
                rows: [],
                total: 0,
                current: 1,
                size: 9,
                pages: 0
            }
        },
        resetParameters() {
            this.eCardDateRange = [];
            this.comboGiftVoucherECardListParams = {
                title: "",
                startCreateTime: "",
                endCreateTime: "",
            }
        },
        // 分页 每页条数改变的时候触发
        handleSizeChange(val) {
            this.comboGiftVoucherECardListParams.size = val;
            this.modifyAssignment(this.choiceIndex);
        },
        // 分页 当前页改变的时候触发
        handleCurrentChange(val) {
            this.comboGiftVoucherECardListParams.current = val;
            this.modifyAssignment(this.choiceIndex);
        },
        openDialogForm(type) {
            this.imgSrc = ""
            this.comboGiftVoucherECardFrom = {
                voucherTemplateid: -1,
                title: "",
                picUrl: "",
                score: "",
                selectNum: "1",
                itemDigit: "8",
                itemRule: "",
                reminder: false,
                showPrice: true,
                endTime: "",
            }
            this.status = 1;
            if (this.choiceIndex!==2){
                this.getShopList()
            }

            this.$refs.comboGiftVoucherECardDialog.open();
        },
        //获取商城列表
        async getShopList() {
            let {data} = await getECardShopListApi({
                shopType:this.choiceIndex+1
            });
            this.shopList = data;
        },
        // 提交文件方法
        async handleChange(param) {
            const data = new FormData();
            if (param) {
                data.append("file", param.file);
                data.append("moduleName", "chooseGoods/ECard");
                if (param.file.size >= this.fileSize * 1024 * 1024) {
                    this.$message({
                        message: "文件过大,最大" + this.fileSize + "MB",
                        type: "warning",
                    });
                    return;
                }
            }
            let {data: res} = await uploadFileApi(data);

            this.comboGiftVoucherECardFrom.picUrl = res.fileUrl
            // this.form.cover = res.filePath
            this.$message({
                message: "上传成功!",
                type: "success",
            });


            //  文件执行成功后后
            this.addComboGiftVoucherECard();
        },
        // 提交表单方法
        submitComboGiftVoucherECard() {
            // 如果用户自行上传图片 走图片方法
            if (this.comboGiftVoucherECardFrom.voucherTemplateid == -999) {
                this.$message.error('请先选择模板图片或上传图片');
                return;
            }
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    if (this.comboGiftVoucherECardFrom.voucherTemplateid == -1) {
                        if (this.status == 1){
                            this.$refs.upload.submit();
                        }else{
                            await this.addComboGiftVoucherECard();
                        }
                    } else {
                        // 使用了模板图片
                        await this.addComboGiftVoucherECard();
                    }
                    this.$refs.comboGiftVoucherECardDialog.close();
                }

            })


        },
        checkPay(){
            if (localStorage.getItem('paying')){
                this.$confirm('是否完成支付?', '支付', {
                    confirmButtonText: '已经完成',
                    cancelButtonText: '未完成',
                    type: 'warning'
                }).then(() => {
                    this.checkOrder();
                    localStorage.removeItem("paying")
                }).catch(() => {
                    this.checkOrder();
                    localStorage.removeItem("paying")
                });
            }
        },
        checkOrder(){
            console.log(1)
        },
        pay(){

        },
        // 提交组合礼包表单方法
        async addComboGiftVoucherECard() {
            let obj = JSON.parse(JSON.stringify(this.comboGiftVoucherECardFrom))
            console.log(obj.endTime)
            obj.endTime = sd.format(obj.endTime);
            obj.tplCode=this.obj.webUrl==='https://saas.3jzc.cn'?259853:255551;
            if (this.choiceIndex==2){
                if (this.status == 1) {
                    try {
                        let {data} = await addComboGiftVoucherECard(obj);
                        this.$message({
                            message: '新增成功!',
                            type: 'success',
                        });
                    }catch (e){
                        if (e.code==700720){
                            this.$confirm('剩余搭建组合礼包卡券次数不足，是否增购？（30元/个 ）', '提示', {
                                confirmButtonText: '增购',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                this.$refs.VipPayComponent.openPay({
                                    type: 3,
                                    rerunUrl: process.env.NODE_ENV === "development"?"http://localhost:8080/#/payCallback":"https://saas.zhelidao.com/#/payCallback"
                                })
                            }).catch(() => {

                            });
                        }

                    }
                } else {
                    let {data} = await updateComboGiftVoucherECard(obj);
                    this.$message({
                        message: '编辑成功!',
                        type: 'success',
                    });
                }
            }else{
                obj.voucherTemplateId=obj.voucherTemplateid
                delete obj.voucherTemplateid;
                if (this.status == 1) {
                    let {data}=await addWxShopECardVoucherApi(obj);
                    this.$message({
                        message: '新增成功!',
                        type: 'success',
                    });
                } else {
                    let {data} = await updateWxShopECardVoucherApi(obj);
                    this.$message({
                        message: '编辑成功!',
                        type: 'success',
                    });
                }

            }
            this.$refs.comboGiftVoucherECardDialog.close();
            this.comboGiftVoucherECardFrom = {
                voucherTemplateid: -1,
                title: "",
                picUrl: "",
                score: "",
                selectNum: "1",
                itemDigit: "8",
                itemRule: "",
                reminder: false,
                showPrice: true,
                endTime: "",
                wxShopId: ""
            }

            this.imgSrc = "";
            if (this.choiceIndex==2){
                this.getComboGiftVoucherECardList();
            }
            if (this.choiceIndex==0){
                this.getScoreCardList();
            }
            if (this.choiceIndex==1){
                this.getMoneyCardList();
            }
        },
        async getWxShopVoucherTemplateList() {
            let queryData = {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize

            }
            let {data} = await getVoucherTemplateList(queryData);
            this.templateList = data.rows;
            console.log(this.templateList)
            this.count = data.count
        },
        handleCurrentChangeVoucher(val) {
            this.pageIndex = val
            this.getWxShopVoucherTemplateList();
        },
        view(picUrl) {
            this.templatePicUrl = picUrl
            this.templatePicUrlVisible = true
        },
        chooseTemplate(id, frontPicUrl) {
            this.comboGiftVoucherECardFrom.voucherTemplateid = id;
            this.comboGiftVoucherECardFrom.picUrl = frontPicUrl;
            this.imgSrc = frontPicUrl
            this.dialogVisible = false;
        },
        openVisible() {

            this.dialogVisible = true;
            this.getWxShopVoucherTemplateList()
        }

    },

    beforeCreate() {
    }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.$nextTick(e => {
            this.$refs.cardType0[0].click()
        })

    },
    beforeMount() {
    }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() {
    }, //生命周期 - 更新之前
    updated() {
    }, //生命周期 - 更新之后
    beforeDestroy() {
    }, //生命周期 - 销毁之前
    destroyed() {
    }, //生命周期 - 销毁完成
    activated() {
    }, //如果页面有keep-alive缓存功能，这个函数会触发
}
