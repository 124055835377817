import service from "@/utils/request"
// 获取卡包列表
export function getInvoiceListApi(data) {
    return service({
        url: '/staffFp/getStaffFpList',
        method: 'get',
        params:data
    })
}
export function getInvoiceApi(data) {
    return service({
        url: '/staffFp/getInfo',
        method: 'get',
        params:data
    })
}
export function addInvoiceApi(data) {
    return service({
        url: '/staffFp/addStaffFp',
        method: 'post',
        data
    })
}
export function updateInvoiceApi(data) {
    return service({
        url: '/staffFp/updateStaffFp',
        method: 'post',
        data
    })
}
export function addStaffFpApi(data) {
    return service({
        url: '/staffFp/addStaffFp',
        method: 'post',
        data
    })
}
//根据订单号查询提现
export function getInvoiceInfoByOrdersApi(data) {
    return service({
        url: '/sjCashOut/getInvoiceInfoByOrders',
        method: 'post',
        data
    })
}