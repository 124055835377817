<!-- 组件模板内容 -->
<template>
    <div>
        <StepComponent
            :index="index"
            @submit-event="submitEvent"
            :arr="arr"
            @index-change="indexChange"
        >
            <stepper1 ref="stepper1" :addForm="addForm" @addForm-change="addFormChange" v-if="index==1" @indexAdd="test"></stepper1>
            <stepper2 :addForm="addForm" @addForm-change="addFormChange" @closePopup="closePopup" v-if="index==2" @indexSub="index--"></stepper2>
        </StepComponent>

    </div>
</template>
  
<script>
import StepComponent from '@/components/StepComponent'
import stepper1 from './stepperForm/stepper1'
import stepper2 from './stepperForm/stepper2'
import {getWxShopBaseInfo,getWxshopChooseTopic} from '@/api/shopApi'
export default {
    name: "Demo",
    components: {
        StepComponent,
        stepper1,
        stepper2
    },
    // 组件的props定义,用于子组件接收父组件传值
    props: {
      currentWxShopId: {
        default:null
      },
    },
    // 组件的data属性
    data() {
        return {
            index: 1,
            arr: [
                { title: "基本信息", subTitle: "填写商城基本信息" },
                { title: "界面模板", subTitle: "选择一个商城的外观样式" },
            ],
            wxShopId: this.currentWxShopId,
            addForm:{
                id:null,
                name: '',
                customerTel: '',
                wxCustomer:'',
                publication:2,//商城发布到哪 1 微信小程序  2 H5
                shopType:1, //商城类型  1 积分 2 现金
                fId: '',
                scoreRatio: '1',
                unit: '积分',
                isCash:1,//是否开启现金支付
                wxShopTopicIds:[],
                bannerUrl:'',
                logoUrl:'',
                templateId:null,
                endTime:'',
                provinceId:'',
                cityId:'',
                countyId:'',
                afterSalesTel:'',
                comparingPrices: true
            }
        };
    },
    // 组件的方法
    methods: {
        test(){
            this.index++
        },
        submitEvent() {
            console.log("提交");
        },
        indexChange(i) {
            this.index = i;
        },
        addFormChange(a){
            this.addForm = a
        },
        closePopup(){
          this.$emit('closePopup')
        },
        async getWxShopInfo(){
          console.log(this.addForm,"================================1")
          let queryParam = {
            wxShopId:this.wxShopId
          }
          let {data} = await getWxShopBaseInfo(queryParam)
          this.addForm=data
          this.addForm.wxShopTopicIds = []
          this.addForm.fId = ''
          console.log(this.addForm)
          console.log(data,"===============================")
          await this.$refs.stepper1.getShopInfo(this.addForm)
        },


    },
    // 在created钩子中执行的操作
    created() {
      console.log("被创建了",this.wxShopId)
      if(this.wxShopId){
        this.getWxShopInfo()
      }
    },
    updated() {
        // 当数据更新后，可以在此处手动初始化下拉菜单插件
        // this.rows.forEach((_, index) => {
        //     this.toggleDropdown(index);
        // });
    }
};
</script>
  
  <!-- 组件的样式 -->
<style scoped></style>