<!-- 组件模板内容 -->
<template>
  <div>
    <!-- {{ goodsInfo }} -->
    <div style="display: flex">
      <div class="imgBox">
        <el-carousel>
          <el-carousel-item
            v-for="(item, index) in goodsInfo.sjGoodsFile"
            :key="index"
          >
            <img :src="item.url" width="100%" height="100%" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="titleBox">
        <div class="sku-name">
          <span>自有商品</span>
          {{ goodsInfo.title }}
        </div>
        <div class="sku-price">
          <div>
            <span>市场价</span>
            <span>{{ goodsInfo.price | format }}</span>
          </div>
          <div>
            <span>分销价</span>
            <span>{{ goodsInfo.supplPrice | format }}</span>
          </div>
          <div>
            <span>销售价</span>
            <span>{{ goodsInfo.salePrice | format }}</span>
          </div>
        </div>
        <div class="sku-info">
          <div>
            <span>库存</span>
            <span>{{ goodsInfo.stock }}</span>
          </div>
          <div>
            <span>销售方式：</span>
            <span>
              <span v-if="goodsInfo.startNum == 1">一件代发</span>
              <span v-else>集采({{ goodsInfo.startNum }}个起售)</span>
            </span>
          </div>
          <div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>{{
                goodsInfo.categoryId1Name
              }}</el-breadcrumb-item>
              <el-breadcrumb-item>{{
                goodsInfo.categoryId2Name
              }}</el-breadcrumb-item>
              <el-breadcrumb-item v-if="goodsInfo.categoryId3Name">{{
                goodsInfo.categoryId3Name
              }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div v-if="goodsInfo.jdUrl">
            <span>比价链接：</span>
            <span>{{ goodsInfo.jdUrl }} </span>
          </div>
          <div v-if="goodsInfo.freightName">
            <span>运费模板：</span>
            <span>{{ goodsInfo.freightName }} </span>
          </div>
          <div>
            <span>送货上门：</span>
            <span>{{ goodsInfo.isInstall==1?"支持":"不支持" }} </span>
          </div>
          <div v-if="goodsInfo.sevenNRR">
            <span>七天无理由：</span>
            <span>{{ goodsInfo.sevenNRR==1?"支持":"不支持" }} </span>
          </div>
          <div v-if="goodsInfo.weight">
            <span>重量(kg)：</span>
            <span>{{ goodsInfo.weight }} </span>
          </div>
          <div v-if="goodsInfo.deliveryTime">
            <span>发货时效：</span>
            <span>{{ goodsInfo.deliveryTime }} </span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p v-html="goodsInfo.detail" id="detailId" style="max-width: 100%;"></p>
    </div>
  </div>
</template>

<script>
import { getGoodsDetailById } from "@/api/goodsManageApi";
import { formatMoney } from "@/utils/formatMoney";
export default {
  name: "SjGoods",
  // 组件的props定义,用于子组件接收父组件传值
  props: {},
  // 组件的data属性
  data() {
    return {
      goodsInfo: {
        id: 0,
        title: "",
        spuId: null,
        spuTitle: null,
        no: null,
        price: "",
        disPriceType: null,
        disPrice: "",
        detail:"",
        picUrl:"",
        isShow: "",
        crTime: "",
        staffId: "",
        stock: "",
        weight: "",
        freightId: "",
        isDelivery: "",
        deliveryId: "",
        isInstall: "",
        installId: "",
        startNum: 0,
        supplPrice: "",
        chooseId: "",
        type: "",
        sjGoodsId: null,
        costPrice: "",
        showTime: "",
        categoryId1: 0,
        categoryId1Name: "",
        categoryId2: 0,
        categoryId2Name: "",
        categoryId3: null,
        categoryId3Name: null,
        saleNum: 0,
        taobaoUrl: "",
        jdUrl: "",
        salePrice: "",
        isQuan: "",
        ratio: "",
        isJD: "",
        code: "",
        sevenNRR: 0,
        recommend: 1,
        deliveryTime: null,
        level: "",
        status: "",
        specialSpec: null,
        isTwoShow: 0,
        profit: 0,
        poolDisPrice: "",
        commentCount: 0,
        pcPrice: null,
        pcMark: 0,
        pcTime: "",
        skuId: null,
        skuName: null,
        userId: null,
        quanSupplyPrice: null,
        chuantype: null,
        rightRate: null,
        sjGoodsStaffId: null,
        categoryName: null,
        maxRightRate: null,
        buyType: null,
        resType: null,
        isShowTime: null,
        image: null,
        goodsFrom: null,
        staffFlag: null,
        saleAttrVo: [
          {
            attrId: null,
            attrName: null,
            attrValues: [{ attrValue: null, skuIds: "6937" }],
          },
        ],
        saleAttr: null,
        sjGoodsFile: [
          {
            id: 0,
            name: "",
            url: "",
            crTime: "",
            sid: "",
          },
        ],
        pstaffId: "",
      },
    };
  },
  filters: {
    format(num) {
      return formatMoney(num);
    },
  },
  // 组件的方法
  methods: {
    async getSjGoodsInfo(id, isJd) {
      this.goodsInfo = {
        title: "",
        sjGoodsFile: [{ url: "" }],
        detail: "",
      };
      let { data } = await getGoodsDetailById({
        id,
        isJd,
      });
      this.goodsInfo = data;
    },
  },
  // 在created钩子中执行的操作
  created() {},
};
</script>
<style>
#detailId>p>img{
  width: 100%;
}
</style>
<!-- 组件的样式 -->
<style scoped>

.imgBox {
  width: 400px;
  padding: 10px;
}
.titleBox {
  padding: 10px;
}
.sku-name {
  font: 700 16px Arial, "microsoft yahei";
  color: #666;
  padding-top: 10px;
  line-height: 28px;
  margin-bottom: 5px;
}
.sku-name span {
  display: inline-block;
  /* height: 14px; */
  padding: 5px;
  line-height: 14px;
  font-family: arial, simsun;
  color: #fff;
  background: #e4393c;
  margin-left: 6px;
  font-size: 14px;
  cursor: pointer;
}
.sku-price {
  font-size: 16px;
  font-weight: bold;
  background: #f3f3f3;
  padding: 10px;
}
.sku-price >div span:nth-child(1){
    font-size: 12px;
    font-weight: 100;
}
.sku-price >div span:nth-child(2){
    color: red;
    font-size: 18px;
    margin-left: 10px;
    font-weight:500;
    
}
.sku-info{
    margin-top: 10px;
}
.sku-info >div{
    margin-top: 5px;
}
.sku-info >div span:nth-child(1){

}
.sku-info >div span:nth-child(2){
    margin-left: 10px;
}
</style>